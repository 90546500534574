import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { WellStreamOrganisationClient } from '../../infrastructure/WellStreamClient/Organisations';
import { GetAssetDomain, GetAPIDomain } from '../../infrastructure/Helper';
import { permissionContext } from '../../infrastructure/Context/permissionContext';
import { OrganisationSelector } from '../../infrastructure/OrganisationSelector';

export class OrganisationsCreate extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, make: "", model: "", authenticated: true, next: false, name: "", description: "" };

    }

    renderForm() {
       
            return (

                <permissionContext.Consumer>
                    {({ refresh }) => {

                        return (<Formik
                            initialValues={{
                                name: "",
                                description: "",
                                autoApproveContent: "",
                            }}
                            onSubmit={async (fields, { setErrors, setFieldError }) => {


                                if (!fields.name) {
                                    setFieldError("name", "Please specify the organisation name");
                                    return;
                                }

                                if (!fields.description) {
                                    setFieldError("description", "Please give a description of the organisation");
                                    return;
                                }

                                var imageId = this.state.logoId;

                                if (fields.file) {

                                    let formData = new FormData();
                                    formData.append("file", fields.file);

                                    var imageResult = await fetch((await GetAPIDomain()) + "/uploads/images", {
                                        method: 'post',
                                        headers: {
                                            'Authorization': 'Bearer ' + localStorage.getItem("acess-token")
                                        },
                                        body: formData
                                    });

                                    if (imageResult.ok) {
                                        imageId = (await imageResult.json()).id;
                                    }
                                }

                                var response = await WellStreamOrganisationClient.Create(fields.name, fields.description, imageId, fields.autoApproveContent);

                                if (!response.successful) {

                                    if (response.status === 404) {
                                        this.setState({ next: true });
                                    } else {

                                        response.validationErrors.map(error => {
                                            setFieldError(error.key, error.message);
                                            return {
                                                [error.key]: error.message
                                            };
                                        });
                                    }

                                } else {
                                    await refresh();

                                    OrganisationSelector.SetSelectedOrg(response.data.id);
                                    this.setState({ reloadApplication: true, message: "Switching to new organisation." }, () => {
                                        setTimeout(() => { window.location.href = "/"; }, 1000);
                                    });

                                    this.setState({ next: true });
                                }

                            }}
                        >{({ errors, handleChange, handleBlur, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                            <Form>
                                <Row>
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="name">Organisation name*</label>
                                            <Field name="name" placeholder="E.g. Acme Inc" type="text" className={'bgWhite form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                                            <ErrorMessage name="name" component="div" className="invalid-feedback text-left" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="description">Description*</label>
                                                <Field name="description" rows="10" placeholder="E.g. Working to make a better tomorrow for all mankind." type="text" component="textarea" className={'bgWhite form-control' + (errors.description && touched.description ? ' is-invalid' : '')} />
                                            <ErrorMessage name="description" component="div" className="invalid-feedback text-left" />
                                        </div>
                                        <div className="form-group">
                                            <label>
                                                <input
                                                    name="autoApproveContent"
                                                    type="checkbox"
                                                    value="True"
                                                    checked={values.autoApproveContent}
                                                    onChange={e => {
                                                        setFieldValue("autoApproveContent", e.target.checked)
                                                    }}
                                                /> Automatically approve content
                                </label>
                                        </div>
                                        <hr />

                                        <div className="form-group mt-3">
                                            <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                                {!isSubmitting && "SAVE"}
                                                {isSubmitting && <Spinner animation="border" />}
                                            </button>
                                        </div>

                                    </Col>
                                    <Col md="3" className="text-center">
                                        <div className="form-group">
                                            <h6 className="text-left">Organisation logo</h6>

                                            {this.state.file && <div>
                                                <img alt="Uploaded supporting file" src={this.state.file} style={{ width: "250px", marginTop: "20px", objectFit: "cover" }} />
                                            </div>}

                                            <input style={{ "overflow": "hidden", marginTop: "25px" }} className="form-control-file" id="file" name="file" type="file" onChange={(event) => {

                                                this.setState({
                                                    file: URL.createObjectURL(event.target.files[0])
                                                })
                                                setFieldValue("file", event.currentTarget.files[0]);
                                            }} />

                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        )}</Formik>);

                    }}
                         </permissionContext.Consumer>);
    }

    render() {
        if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Create organisation
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Create new organisation</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/"} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.renderForm()}
                        </CardBody>
                    </Card>

                    <Modal isOpen={this.state.reloadApplication}>
                        <ModalHeader>Updating <Spinner style={{ float: "right" }} animation="border" /></ModalHeader>
                        <ModalBody>Switching to newly created organisation</ModalBody>
                    </Modal>

                </div>
            );
        }
    }

}
