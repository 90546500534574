import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Spinner, Card, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { OrganisationSelector } from '../../infrastructure/OrganisationSelector';
import { WellStreamOrganisationClient } from '../../infrastructure/WellStreamClient/Organisations';
import { GetAPIDomain } from '../../infrastructure/Helper';

export class OnboardingBusiness extends Component {

    constructor(props) {
        super(props);
        this.state = { codeStatus: "", loading: true };
    }

    componentDidMount() {
        OrganisationSelector.Init();
        this.populateData();
    }

    async populateData() {

        if (!this.state.init) {

            var selectedOrg = await OrganisationSelector.GetSelectedOrg();
            if (selectedOrg === null)
                this.setState({ next: "dashboard" });

            this.setState({ orgId: selectedOrg.id });

            var item = await WellStreamOrganisationClient.Get(selectedOrg.id);

            if (!item.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {

                this.setState({
                    data: item.data,
                    name: item.data.name,
                    loading: false,
                    init: true
                });

            }
        }
    }

    renderContent() {

        if (this.state.next === "next") {
            return (<Redirect to="/onboarding/step-02" />);
        } else if (this.state.next === "dashboard") {
            return (<Redirect to="/" />);
        } else {
            return (<Formik
                initialValues={{
                    name: this.state.name,
                    description: ''
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    if (!fields.name) {
                        setFieldError("name", "Please specify the business");
                        return;
                    }

                    var imageId = null;

                    if (fields.file) {

                        let formData = new FormData();
                        formData.append("file", fields.file);

                        var imageResult = await fetch((await GetAPIDomain()) + "/uploads/images", {
                            method: 'post',
                            headers: {
                                'Authorization': 'Bearer ' + localStorage.getItem("acess-token")
                            },
                            body: formData
                        });

                        if (imageResult.ok) {
                            imageId = (await imageResult.json()).id;
                        }
                    }

                    var response = await WellStreamOrganisationClient.Update(this.state.orgId, fields.name, fields.description, imageId);

                    if (!response.successful) {

                        response.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });

                    } else {
                        this.setState({ next: "next" });
                    }
                }}
                render={({ errors, status, touched, isSubmitting, setFieldValue }) => (
                    <Form>
                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <Field name="name" placeholder="Organisation name" type="text" className={'bgWhite form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                            <ErrorMessage name="name" component="div" className="invalid-feedback text-left" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="description">Description</label>
                            <Field name="description" type="text" component="textarea" className="form-control" />
                            <ErrorMessage name="description" component="div" className="invalid-feedback text-left" />
                        </div>
                        <hr />
                        <div className="form-group">
                            <h6 className="text-left">Logo</h6>

                            {this.state.file && <div>
                                <img alt="Uploaded supporting file" src={this.state.file} style={{ width: "250px", marginTop: "20px", objectFit: "cover" }} />
                            </div>}

                            <input style={{ "overflow": "hidden", marginTop: "25px" }} className="form-control-file" id="file" name="file" type="file" onChange={(event) => {

                                this.setState({
                                    file: URL.createObjectURL(event.target.files[0])
                                })
                                setFieldValue("file", event.currentTarget.files[0]);
                            }} />

                        </div>
                        <hr />
                        <div className="form-group">
                            <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting} style={{ width: "100%" }}>
                                {!isSubmitting && "Next"}
                                {isSubmitting && <Spinner animation="border" />}
                            </button>
                        </div>
                    </Form>
                )}
            />);
        }
    }

    render() {

        return (
            <Card style={{ maxWidth: "600px", width: "100%" }}>
                <CardBody>
                    <div>
                        <div>
                            <div className="card-body text-left">

                                <h3 className="mb-4">Organisation details</h3>
                                <p>Please provide a little information about your organisation</p>
                                <hr />
                                {!this.state.init && <Spinner />}
                                {this.state.init && this.renderContent()}

                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        );

    }
}