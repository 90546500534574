import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { WellStreamInitiativeClient } from '../../infrastructure/WellStreamClient/Initiatives';

export class InitiativesEdit extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, make: "", model: "", loading: true, authenticated: true, next: false, name: "", description: "" };

    }

    componentDidMount() {
        this.populateData();
    }

    renderForm() {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {

            return (<Formik
                initialValues={{
                    name: this.state.name,
                    description: this.state.description,
                    billedToOrg: this.state.billedToOrg,
                    quickJoinEnabled: this.state.quickJoinEnabled,
                    quickJoinCode: this.state.quickJoinCode
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {


                    if (!fields.name) {
                        setFieldError("name", "Please specify the initiative name");
                        return;
                    }

                    if (!fields.description) {
                        setFieldError("description", "Please give a description of the initiative");
                        return;
                    }

                    var response = await WellStreamInitiativeClient.Update(this.props.match.params.id, fields.name, fields.description, fields.billedToOrg, fields.quickJoinEnabled, fields.quickJoinCode);

                    if (!response.successful) {

                        if (response.status === 404) {
                            this.setState({ next: true });
                        } else {

                            response.validationErrors.map(error => {
                                setFieldError(error.key, error.message);
                                return {
                                    [error.key]: error.message
                                };
                            });
                        }

                    } else {
                        this.setState({ next: true });
                    }

                }}
            >{({ errors, handleChange, handleBlur, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                <Form>

                    <div className="form-group">
                        <label htmlFor="name">Initiative name*</label>
                        <Field name="name" placeholder="E.g. Employee Wellbeing" type="text" className={'bgWhite form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                        <ErrorMessage name="name" component="div" className="invalid-feedback text-left" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="description">Description*</label>
                        <Field name="description" rows="10" placeholder="E.g. Welcome to our company wellbeing improvement initiative. Our aim is to..." type="text" component="textarea" className={'bgWhite form-control' + (errors.description && touched.description ? ' is-invalid' : '')} />
                        <ErrorMessage name="description" component="div" className="invalid-feedback text-left" />
                        </div>
                        <div className="form-group">
                            <label>
                                <input
                                    name="billedToOrg"
                                    type="checkbox"
                                    value="True"
                                    checked={values.billedToOrg}
                                    onChange={e => {
                                        setFieldValue("billedToOrg", e.target.checked)
                                    }}
                                /> Subscriptions billed to organisation
                            </label>
                        </div>
                        <Row>
                            <Col md="3">
                                <label>
                                    <input
                                        name="quickJoinEnabled"
                                        type="checkbox"
                                        value="True"
                                        checked={values.quickJoinEnabled}
                                        onChange={e => {
                                            setFieldValue("quickJoinEnabled", e.target.checked)
                                        }}
                                    /> Quick Join Enabled
                                </label>
                            </Col>
                            {values.quickJoinEnabled && <Col md="9">
                                <label htmlFor="quickJoinCode">Quick Join Code</label>
                                <Field name="quickJoinCode" placeholder="E.g. WS100" type="text" className={'bgWhite form-control' + (errors.quickJoinCode && touched.quickJoinCode ? ' is-invalid' : '')} />
                                <ErrorMessage name="quickJoinCode" component="div" className="invalid-feedback text-left" />
                            </Col>}
                        </Row>
                    <hr />

                    <div className="form-group mt-3">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "SAVE"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/initiatives/details/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update initiative
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Update initiative information</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/initiatives/details/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {
            this.setState({ init: true });

            var response = await WellStreamInitiativeClient.Get(this.props.match.params.id);

            if (!response.authenticated) {
                this.setState({
                    loading: false,
                    authenticated: false
                });
            } else {

                this.setState({
                    loading: false,
                    name: response.data.name,
                    description: response.data.description,
                    quickJoinCode: response.data.quickJoinCode,
                    quickJoinEnabled: response.data.quickJoinEnabled,
                    billedToOrg: response.data.subscriptionBilledToOrganisation
                });
            }
        }
    }
}
