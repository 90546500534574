import { ListQueryParams, ApiResponse } from "../WellStreamAPI";
import { GetAPIHeadersPatch, GetAPIDomain, GetAPIHeaders } from "../Helper";

export class WellStreamTrustedSupporterClient {

    static async Accept(code: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/supporters/confirm/"+code, {
            method: 'post',
            headers: await GetAPIHeaders()
        }));
    }

    static async ValidateCode(code: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/supporters/validate", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "code": code
            })
        }));
    }

    static async Decline(id: string) {

        var query = (await GetAPIDomain()) + "/supporters/decline/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'post'
        }));
    }

}