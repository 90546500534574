import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Spinner, Card, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { OrganisationSelector } from '../../infrastructure/OrganisationSelector';
import { WellStreamInitiativeClient, InitiativeQueryParams } from '../../infrastructure/WellStreamClient/Initiatives';

export class OnboardingInitiative extends Component {

    constructor(props) {
        super(props);
        this.state = { codeStatus: "", loading: true };
    }


    componentDidMount() {
        OrganisationSelector.Init();
        this.populateData();
    }

    async populateData() {

        if (!this.state.init) {

            var selectedOrg = await OrganisationSelector.GetSelectedOrg();
            if (selectedOrg === null) {
                this.setState({ init: true, next: "next" });
            } else {

                this.setState({ orgId: selectedOrg.id });

                var initiatives = await WellStreamInitiativeClient.List(new InitiativeQueryParams()
                    .WithOrganisationId(selectedOrg.id)
                    .Paginate(0, 1));

                if (!initiatives.data || initiatives.data.data.length > 0) {
                    this.setState({ init: true, next: "next" });
                } else {

                    if (!initiatives.authenticated) {
                        this.setState({
                            authenticated: false
                        });
                    } else {

                        this.setState({
                            loading: false,
                            init: true
                        });

                    }
                }
            }
        }
    }

    renderContent() {

        if (this.state.next === "next") {
            return (<Redirect to="/" />);
        } else {
            return (<Formik
                initialValues={{
                    name: '',
                    description: ''
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    if (!fields.name) {
                        setFieldError("name", "Please specify the initiative name");
                        return;
                    }

                    if (!fields.description) {
                        setFieldError("description", "Please give a description of the initiative");
                        return;
                    }

                    var response = await WellStreamInitiativeClient.Create(this.state.orgId, fields.name, fields.description);

                    if (!response.successful) {

                        response.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });

                    } else {
                        this.setState({ next: "next" });
                    }
                }}
                render={({ errors, status, touched, isSubmitting, setFieldValue }) => (
                    <Form>
                        <div className="form-group">
                            <label htmlFor="name">Initiative name*</label>
                            <Field name="name" placeholder="E.g. Employee Wellbeing" type="text" className={'bgWhite form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                            <ErrorMessage name="name" component="div" className="invalid-feedback text-left" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="description">Description*</label>
                            <Field name="description" rows="10" placeholder="E.g. Welcome to our company wellbeing improvement initiative. Our aim is to..." type="text" component="textarea" className={'bgWhite form-control' + (errors.description && touched.description ? ' is-invalid' : '')} />
                            <ErrorMessage name="description" component="div" className="invalid-feedback text-left" />
                        </div>
                        
                        <hr />
                        <div className="form-group">
                            <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting} style={{ width: "100%" }}>
                                {!isSubmitting && "Next"}
                                {isSubmitting && <Spinner animation="border" />}
                            </button>
                        </div>
                    </Form>
                )}
            />);
        }
    }

    render() {

        return (
            <Card style={{ maxWidth: "600px", width: "100%" }}>
                <CardBody>
                    <div>
                        <div>
                            <div className="card-body text-left">

                                <h3 className="mb-4">Create your first wellbeing initiative</h3>
                                <p>A wellbeing initiative helps track the mental health of a cohort of users within your organisation.</p>
                                <p>To begin, please create your first initiative, this will provide the user with some information when deciding to join.</p>
                                <hr />

                                {!this.state.init && <Spinner />}
                                {this.state.init && this.renderContent()}

                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        );

    }
}