import { ListQueryParams, ApiResponse } from "../WellStreamAPI";
import { GetAPIDomain, GetAPIHeaders } from "../Helper";

export class ContentApprovalQueryParams extends ListQueryParams {

    WithContentItem(value: any) {
        this.WithParam("contentItemId", value);
        return this;
    }

    WithUser(value: any) {
        this.WithParam("userId", value);
        return this;
    }

    WithInitiative(value: any) {
        this.WithParam("initiativeId", value);
        return this;
    }

    WithOrganisation(value: any) {
        this.WithParam("organisationId", value);
        return this;
    }

}

export class WellStreamContentApprovalClient {

    static async List(params: ContentApprovalQueryParams) {

        var query = (await GetAPIDomain()) + "/content-approvals" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }
    
    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/content-approvals/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Create(contentItemId: string, allInitiatives: Boolean, initiativeId?: string, organisationId? :string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/content-approvals/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "contentItemId": contentItemId,
                "allInitiatives": allInitiatives,
                "initiativeId": initiativeId,
                "organisationId": organisationId
            })
        }));
    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/content-approvals/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }
}