import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import "react-datepicker/dist/react-datepicker.css";
import { WellStreamInitiativeClient, InitiativeQueryParams } from '../../infrastructure/WellStreamClient/Initiatives';
import { OrganisationSelector } from '../../infrastructure/OrganisationSelector';
import { WellStreamInviteClient } from '../../infrastructure/WellStreamClient/Invites';
import { SelectField } from '../../infrastructure/SelectField';

export class InvitesNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false,
            authenticated: true,
            page: "choose-type"
        };

    }

    componentDidMount() {
        OrganisationSelector.Init();
        this.populateData();

        const urlParams = new URLSearchParams(this.props.location.search);
        if (urlParams.get("dest") === "user")
            this.setState({ page: "invite-user" });

        if (urlParams.get("dest") === "staff")
            this.setState({ page: "invite-staff" });
    }

    renderForm() {
        return (<Formik
            initialValues={{
                name: '',
                description: ''
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.name) {
                    setFieldError("name", "Please specify the initiative name");
                    return;
                }

                if (!fields.description) {
                    setFieldError("description", "Please give a description of the initiative");
                    return;
                }

                var selectedOrg = await OrganisationSelector.GetSelectedOrg();
                var response = await WellStreamInitiativeClient.Create(selectedOrg.id, fields.name, fields.description);

                if (!response.successful) {

                    response.validationErrors.map(error => {
                        setFieldError(error.key, error.message);
                        return {
                            [error.key]: error.message
                        };
                    });

                } else {
                    this.setState({ next: true, id: response.data.id });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
            <Form>
                <div className="form-group">
                    <label htmlFor="name">Initiative name*</label>
                    <Field name="name" placeholder="E.g. Employee Wellbeing" type="text" className={'bgWhite form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                    <ErrorMessage name="name" component="div" className="invalid-feedback text-left" />
                </div>
                <div className="form-group">
                    <label htmlFor="description">Description*</label>
                    <Field name="description" rows="10" placeholder="E.g. Welcome to our company wellbeing improvement initiative. Our aim is to..." type="text" component="textarea" className={'bgWhite form-control' + (errors.description && touched.description ? ' is-invalid' : '')} />
                    <ErrorMessage name="description" component="div" className="invalid-feedback text-left" />
                </div>

                <hr />
                <div className="form-group">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "Create"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    renderInviteUserForm() {
        return (
            <div>

                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h5> Invite user to your initiative
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                </h5>
                                <span className="d-block m-t-5 text-muted">Send an invitation to a user to join an initiative</span>
                            </Col>
                            <Col>
                                <div style={{ paddingRight: "10px" }}>
                                    <Link style={{ float: "right" }} to="/invitations" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                </div>
                            </Col>
                        </Row>

                    </CardHeader>

                    <CardBody>

                        <Formik
                            initialValues={{
                                name: this.state.name,
                                surname: this.state.surname,
                                email: this.state.email,
                                initiativeId: this.state.initiativeOptions[0]
                            }}
                            onSubmit={async (fields, { setErrors, setFieldError }) => {

                                var response = await WellStreamInviteClient.InviteUser(fields.initiativeId.value, fields.name, fields.surname, fields.email);

                                if (!response.successful) {

                                    response.validationErrors.map(error => {
                                        setFieldError(error.key, error.message);
                                        return {
                                            [error.key]: error.message
                                        };
                                    });

                                } else {
                                    this.setState({ page: "next", id: response.data.id });
                                }

                            }}
                        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                            <Form>

                                <Row>
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="name">Name</label>
                                            <Field name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                                            <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="surname">Surname</label>
                                            <Field name="surname" type="text" className={'form-control' + (errors.surname && touched.surname ? ' is-invalid' : '')} />
                                            <ErrorMessage name="surname" component="div" className="invalid-feedback" />
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                        </div>
                                    </Col>
                                </Row>

                                <SelectField
                                    id="initiativeId"
                                    name="initiativeId"
                                    label="Initiatives"
                                    placeholder="Select initiative"
                                    options={this.state.initiativeOptions}
                                    value={values.initiativeId}
                                    isMulti={false}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    touched={touched.initiativeId}
                                    error={errors.initiativeId}
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                />

                                <div className="form-group mt-3">
                                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                        {!isSubmitting && "SEND INVITATION"}
                                        {isSubmitting && <Spinner animation="border" />}
                                    </button>
                                </div>
                            </Form>
                        )}</Formik>


                    </CardBody>

                </Card>

            </div>);
    }

    renderInviteStaffForm() {
        return (
            <div>

                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h5> Invite user to help manage your organisation
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                </h5>
                                <span className="d-block m-t-5 text-muted">Send an invitation to a user to manage your organisation</span>
                            </Col>
                            <Col>
                                <div style={{ paddingRight: "10px" }}>
                                    <Link style={{ float: "right" }} to="/invitations" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                </div>
                            </Col>
                        </Row>

                    </CardHeader>

                    <CardBody>

                        <Formik
                            initialValues={{
                                name: this.state.name,
                                surname: this.state.surname,
                                email: this.state.email
                            }}
                            onSubmit={async (fields, { setErrors, setFieldError }) => {

                                var selectedOrg = await OrganisationSelector.GetSelectedOrg();
                                var response = await WellStreamInviteClient.InviteOrganisation(selectedOrg.id, fields.name, fields.surname, fields.email);

                                if (!response.successful) {

                                    response.validationErrors.map(error => {
                                        setFieldError(error.key, error.message);
                                        return {
                                            [error.key]: error.message
                                        };
                                    });

                                } else {
                                    this.setState({ page: "next", id: response.data.id });
                                }

                            }}
                        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                            <Form>

                                <Row>
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="name">Name</label>
                                            <Field name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                                            <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="surname">Surname</label>
                                            <Field name="surname" type="text" className={'form-control' + (errors.surname && touched.surname ? ' is-invalid' : '')} />
                                            <ErrorMessage name="surname" component="div" className="invalid-feedback" />
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                        </div>
                                    </Col>
                                </Row>

                                <div className="form-group mt-3">
                                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                        {!isSubmitting && "SEND INVITATION"}
                                        {isSubmitting && <Spinner animation="border" />}
                                    </button>
                                </div>
                            </Form>
                        )}</Formik>


                    </CardBody>

                </Card>

            </div>);
    }

    render() {
        if (this.state.page === "choose-type") {
            return (<Card className="note-bar">
                <CardHeader><h5>Who are you inviting</h5></CardHeader>
                <CardBody>
                    <Link to="#" onClick={() => { this.setState({ page: "invite-user" }); }} className="media friendlist-box">
                        <div className="mr-4 photo-table">
                            <i className="feather icon-user"></i>
                        </div>
                        <div className="media-body">
                            <h6>Invite user to initiative</h6>
                            <p className="text-muted m-0">Send an invite for a user to join an initiative</p>
                        </div>
                    </Link>

                    <Link to="#" onClick={() => { this.setState({ page: "invite-staff" }); }} className="media friendlist-box">
                        <div className="mr-4 photo-table">
                            <i className="feather icon-tablet"></i>
                        </div>
                        <div className="media-body">
                            <h6>Invite staff to organisation</h6>
                            <p className="text-muted m-0">Send an invite for a user to manage your organisation</p>
                        </div>
                    </Link>
                </CardBody>
            </Card>);
        } else if (this.state.page === "next") {
            return (<Redirect to={"/invitations/details/" + this.state.id} />);
        } else if (this.state.page === "invite-staff") {
            return this.renderInviteStaffForm();
        } else if (this.state.page === "invite-user") {
            return this.renderInviteUserForm();
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> New initiative
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Start a new initiative</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/initiatives" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.data && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }


    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });


            var selectedOrg = await OrganisationSelector.GetSelectedOrg();
            var initiatives = (await WellStreamInitiativeClient.List(new InitiativeQueryParams().Paginate(0, 10000)
                .WithOrganisationId(selectedOrg.id))).data;

            this.setState({
                init: true,
                initiativeOptions: initiatives.data.map(function (item) { return { value: item.id, label: item.name }; }),
                loading: false
            });
        }
    }
}
