var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
export function GetAPIDomain() {
    return __awaiter(this, void 0, void 0, function () {
        var domain, response, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    domain = localStorage.getItem("api-domain");
                    if (!!domain) return [3 /*break*/, 3];
                    return [4 /*yield*/, fetch('app-settings/domains')];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    domain = data.domainApi;
                    localStorage.setItem("api-domain", domain);
                    _a.label = 3;
                case 3: return [2 /*return*/, domain];
            }
        });
    });
}
export function GetAPIHeaders() {
    return __awaiter(this, void 0, void 0, function () {
        var expiry, accessToken, headers;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    expiry = new Date(Date.parse(localStorage.getItem("expires")));
                    if (!(expiry < new Date())) return [3 /*break*/, 2];
                    console.log("Expired");
                    return [4 /*yield*/, WellStreamAPI.RefreshToken()];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    accessToken = localStorage.getItem("acess-token");
                    headers = new Headers({ 'content-type': 'application/json', 'Authorization': 'Bearer ' + accessToken });
                    return [2 /*return*/, headers];
            }
        });
    });
}
var ApiResponse = /** @class */ (function () {
    function ApiResponse() {
        this.successful = false;
        this.authenticated = false;
    }
    ApiResponse.Create = function (response) {
        return __awaiter(this, void 0, void 0, function () {
            var result, data, ex_1, _a, ex_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        result = new ApiResponse();
                        result.authenticated = true;
                        if (!!response.ok) return [3 /*break*/, 5];
                        result.successful = false;
                        if (!(response.status === 401)) return [3 /*break*/, 1];
                        WellStreamAPI.LogOut();
                        result.authenticated = false;
                        window.location.reload();
                        return [3 /*break*/, 4];
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _b.sent();
                        result.data = data;
                        result.validationErrors = data.validationErrors;
                        return [3 /*break*/, 4];
                    case 3:
                        ex_1 = _b.sent();
                        console.log("No validate errors for this request");
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 9];
                    case 5:
                        result.successful = true;
                        _b.label = 6;
                    case 6:
                        _b.trys.push([6, 8, , 9]);
                        _a = result;
                        return [4 /*yield*/, response.json()];
                    case 7:
                        _a.data = _b.sent();
                        return [3 /*break*/, 9];
                    case 8:
                        ex_2 = _b.sent();
                        console.log(ex_2);
                        console.log("No content for this request");
                        return [3 /*break*/, 9];
                    case 9: return [2 /*return*/, result];
                }
            });
        });
    };
    return ApiResponse;
}());
export { ApiResponse };
var ListQueryParams = /** @class */ (function () {
    function ListQueryParams() {
        this.skip = 0;
        this.take = 50;
        this.direction = "ascending";
        this.params = Array();
    }
    ListQueryParams.prototype.Sort = function (sort, direction) {
        this.sort = sort;
        if (direction && direction === "desc")
            return this.Descending();
        if (direction && direction !== "desc")
            return this.Ascending();
        return this;
    };
    ListQueryParams.prototype.Descending = function () {
        this.direction = "descending";
        return this;
    };
    ListQueryParams.prototype.Ascending = function () {
        this.direction = "ascending";
        return this;
    };
    ListQueryParams.prototype.Paginate = function (skip, take) {
        this.skip = skip;
        this.take = take;
        return this;
    };
    ListQueryParams.prototype.Search = function (value) {
        this.WithParam("q", value);
        return this;
    };
    ListQueryParams.prototype.WithStart = function (value) {
        this.WithParam("start", value);
        return this;
    };
    ListQueryParams.prototype.WithEnd = function (value) {
        this.WithParam("end", value);
        return this;
    };
    ListQueryParams.prototype.WithParam = function (param, value) {
        this.params.push({
            key: param,
            value: value
        });
        return this;
    };
    ListQueryParams.prototype.GenerateQueryString = function () {
        var query = "?skip=" + this.skip + "&take=" + this.take;
        if (this.sort)
            query = query + "&sort=" + this.sort + "&direction=" + this.direction;
        if (this.params) {
            this.params.map(function (param) {
                if (param.value && param.key) {
                    if (param.value instanceof Date) {
                        query = query + "&" + param.key + "=" + param.value.toISOString();
                    }
                    else {
                        query = query + "&" + param.key + "=" + param.value;
                    }
                }
            });
        }
        return query;
    };
    return ListQueryParams;
}());
export { ListQueryParams };
var QueryParameter = /** @class */ (function () {
    function QueryParameter() {
    }
    return QueryParameter;
}());
export { QueryParameter };
var SubmissionParams = /** @class */ (function (_super) {
    __extends(SubmissionParams, _super);
    function SubmissionParams() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return SubmissionParams;
}(ListQueryParams));
export { SubmissionParams };
var WellStreamAPI = /** @class */ (function () {
    function WellStreamAPI() {
    }
    //SIGN IN
    WellStreamAPI.SignIn = function (username, password, setError) {
        return __awaiter(this, void 0, void 0, function () {
            var formData, response, _a, data_1, data;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        formData = new URLSearchParams();
                        formData.append('username', username);
                        formData.append('password', password);
                        formData.append('client_id', '83ff0708-170d-44ad-9de9-4a533567e5f3');
                        formData.append('grant_type', 'password');
                        _a = fetch;
                        return [4 /*yield*/, GetAPIDomain()];
                    case 1: return [4 /*yield*/, _a.apply(void 0, [(_b.sent()) + "/connect/token", {
                                body: formData,
                                method: "post"
                            }])];
                    case 2:
                        response = _b.sent();
                        if (!!response.ok) return [3 /*break*/, 5];
                        localStorage.clear();
                        if (!setError) return [3 /*break*/, 4];
                        return [4 /*yield*/, response.json()];
                    case 3:
                        data_1 = _b.sent();
                        setError(data_1.error_description);
                        _b.label = 4;
                    case 4: return [2 /*return*/, false];
                    case 5: return [4 /*yield*/, response.json()];
                    case 6:
                        data = _b.sent();
                        localStorage.setItem("acess-token", data.access_token);
                        localStorage.setItem("refresh-token", data.refresh_token);
                        localStorage.setItem("expires", new Date((new Date()).getTime() + (50 * 60 * 1000)).toISOString());
                        return [2 /*return*/, true];
                }
            });
        });
    };
    WellStreamAPI.SignUp = function (business, name, surname, mobile, email, password) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, _c, _d;
            var _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        _b = (_a = ApiResponse).Create;
                        _c = fetch;
                        return [4 /*yield*/, GetAPIDomain()];
                    case 1:
                        _d = [(_f.sent()) + "/users"];
                        _e = {
                            method: 'post'
                        };
                        return [4 /*yield*/, GetAPIHeaders()];
                    case 2: return [4 /*yield*/, _c.apply(void 0, _d.concat([(_e.headers = _f.sent(),
                                _e.body = JSON.stringify({
                                    "organisation": business,
                                    "name": name,
                                    "surname": surname,
                                    "mobile": mobile,
                                    "email": email,
                                    "password": password
                                }),
                                _e)]))];
                    case 3: return [4 /*yield*/, _b.apply(_a, [_f.sent()])];
                    case 4: return [2 /*return*/, _f.sent()];
                }
            });
        });
    };
    WellStreamAPI.SignUpWithCode = function (name, surname, mobile, email, password, code) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, _c, _d;
            var _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        _b = (_a = ApiResponse).Create;
                        _c = fetch;
                        return [4 /*yield*/, GetAPIDomain()];
                    case 1:
                        _d = [(_f.sent()) + "/users"];
                        _e = {
                            method: 'post'
                        };
                        return [4 /*yield*/, GetAPIHeaders()];
                    case 2: return [4 /*yield*/, _c.apply(void 0, _d.concat([(_e.headers = _f.sent(),
                                _e.body = JSON.stringify({
                                    "name": name,
                                    "surname": surname,
                                    "mobile": mobile,
                                    "email": email,
                                    "password": password,
                                    "initiativeCode": code
                                }),
                                _e)]))];
                    case 3: return [4 /*yield*/, _b.apply(_a, [_f.sent()])];
                    case 4: return [2 /*return*/, _f.sent()];
                }
            });
        });
    };
    WellStreamAPI.CheckEmailAddress = function (email) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, _c, _d;
            var _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        _b = (_a = ApiResponse).Create;
                        _c = fetch;
                        return [4 /*yield*/, GetAPIDomain()];
                    case 1:
                        _d = [(_f.sent()) + "/users/check-email"];
                        _e = {
                            method: 'post'
                        };
                        return [4 /*yield*/, GetAPIHeaders()];
                    case 2: return [4 /*yield*/, _c.apply(void 0, _d.concat([(_e.headers = _f.sent(),
                                _e.body = JSON.stringify({
                                    "email": email
                                }),
                                _e)]))];
                    case 3: return [4 /*yield*/, _b.apply(_a, [_f.sent()])];
                    case 4: return [2 /*return*/, _f.sent()];
                }
            });
        });
    };
    WellStreamAPI.Export = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var query, response, _a, _b;
            var _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4 /*yield*/, GetAPIDomain()];
                    case 1:
                        query = (_d.sent()) + "/submissions/export.csv" + params.GenerateQueryString();
                        _a = fetch;
                        _b = [query];
                        _c = {};
                        return [4 /*yield*/, GetAPIHeaders()];
                    case 2: return [4 /*yield*/, _a.apply(void 0, _b.concat([(_c.headers = _d.sent(),
                                _c)]))];
                    case 3:
                        response = _d.sent();
                        return [4 /*yield*/, response.blob()];
                    case 4: return [2 /*return*/, _d.sent()];
                }
            });
        });
    };
    //REFRESH TOKEN
    WellStreamAPI.RefreshToken = function () {
        return __awaiter(this, void 0, void 0, function () {
            var token, formData, response, _a, data;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!!this.refresh_lock) return [3 /*break*/, 4];
                        this.refresh_lock = true;
                        token = localStorage.getItem("refresh-token");
                        if (!token) {
                            localStorage.clear();
                            return [2 /*return*/];
                        }
                        formData = new URLSearchParams();
                        formData.append('refresh_token', token);
                        formData.append('client_id', 'db4cf99b-631b-41d2-9122-840d7d57bb7f');
                        formData.append('grant_type', 'refresh_token');
                        _a = fetch;
                        return [4 /*yield*/, GetAPIDomain()];
                    case 1: return [4 /*yield*/, _a.apply(void 0, [(_b.sent()) + "/connect/token", {
                                body: formData,
                                method: "post"
                            }])];
                    case 2:
                        response = _b.sent();
                        if (!response.ok) {
                            localStorage.clear();
                            return [2 /*return*/, false];
                        }
                        return [4 /*yield*/, response.json()];
                    case 3:
                        data = _b.sent();
                        localStorage.setItem("acess-token", data.access_token);
                        localStorage.setItem("refresh-token", data.refresh_token);
                        localStorage.setItem("expires", new Date((new Date()).getTime() + (50 * 60 * 1000)).toISOString());
                        this.refresh_lock = false;
                        return [2 /*return*/, true];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    //SIGN OUT
    WellStreamAPI.LogOut = function () {
        localStorage.removeItem("acess-token");
        localStorage.removeItem("refresh-token");
        localStorage.clear();
    };
    //IS AUTHENTICATED
    WellStreamAPI.IsAuthenticated = function () {
        if (localStorage == null)
            return false;
        if (localStorage.getItem("acess-token") == null)
            return false;
        var token = localStorage.getItem("acess-token");
        if (token != null && token.length === 0)
            return false;
        return true;
    };
    //BEGIN PASSWORD RESET
    WellStreamAPI.BeginPasswordReset = function (email) {
        return __awaiter(this, void 0, void 0, function () {
            var response, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = fetch;
                        return [4 /*yield*/, GetAPIDomain()];
                    case 1: return [4 /*yield*/, _a.apply(void 0, [(_b.sent()) + "/reset-password/begin", {
                                method: 'post',
                                headers: new Headers({ 'content-type': 'application/json' }),
                                body: JSON.stringify({
                                    "email": email,
                                    "source": "admin"
                                })
                            }])];
                    case 2:
                        response = _b.sent();
                        return [4 /*yield*/, response.json()];
                    case 3: return [2 /*return*/, (_b.sent()).successful];
                }
            });
        });
    };
    //COMPLETE PASSWORD RESET
    WellStreamAPI.CompletePasswordReset = function (code, password) {
        return __awaiter(this, void 0, void 0, function () {
            var response, _a, data, details;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = fetch;
                        return [4 /*yield*/, GetAPIDomain()];
                    case 1: return [4 /*yield*/, _a.apply(void 0, [(_b.sent()) + "/reset-password/complete", {
                                method: 'post',
                                headers: new Headers({ 'content-type': 'application/json' }),
                                body: JSON.stringify({
                                    "code": code,
                                    "password": password
                                })
                            }])];
                    case 2:
                        response = _b.sent();
                        return [4 /*yield*/, response.json()];
                    case 3:
                        data = _b.sent();
                        details = new ApiResponse();
                        details.successful = response.ok;
                        if (!response.ok) {
                            details.validationErrors = data.validationErrors;
                        }
                        else {
                            details.data = data;
                        }
                        return [2 /*return*/, details];
                }
            });
        });
    };
    //BEGIN DELETE ACCOUNT REQUEST
    WellStreamAPI.GetAccountDeleteRequest = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var response, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = fetch;
                        return [4 /*yield*/, GetAPIDomain()];
                    case 1: return [4 /*yield*/, _a.apply(void 0, [(_b.sent()) + "/delete-account-requests/" + id, {
                                method: 'get'
                            }])];
                    case 2:
                        response = _b.sent();
                        return [4 /*yield*/, response.json()];
                    case 3: return [2 /*return*/, (_b.sent())];
                }
            });
        });
    };
    //CANCEL DELETE ACCOUNT REQUEST
    WellStreamAPI.CancelAccountDeleteRequest = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var response, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = fetch;
                        return [4 /*yield*/, GetAPIDomain()];
                    case 1: return [4 /*yield*/, _a.apply(void 0, [(_b.sent()) + "/delete-account-requests/" + id, {
                                method: 'delete'
                            }])];
                    case 2:
                        response = _b.sent();
                        return [2 /*return*/, true];
                }
            });
        });
    };
    //******************************
    // AUTHENTICATION
    //******************************
    WellStreamAPI.refresh_lock = false;
    return WellStreamAPI;
}());
export { WellStreamAPI };
