import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody, Input, InputGroup, ButtonGroup, Badge } from 'reactstrap';
import { WellStreamInviteClient, InviteQueryParams } from '../../infrastructure/WellStreamClient/Invites';
import { TableHeader } from '../../infrastructure/TableHeader';
import { OrganisationSelector } from '../../infrastructure/OrganisationSelector';
import ReactTooltip from "react-tooltip";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Moment from 'react-moment';

export class Invites extends Component {

    constructor(props) {
        super(props);
        this.state = { data: [], loading: false, total: 0, skip: 0, take: 50, authenticated: true, search: "", sort: "name", direction: "asc" };
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.notifySuccessCopy = this.notifySuccessCopy.bind(this);
    }

    handleStatusChange(evt) {
        this.setState({
            inviteStatus: evt.target.value
        }, () => this.update());
    }

    handleTypeChange(evt) {
        this.setState({
            inviteType: evt.target.value
        }, () => this.update());
    }

    handleSearchChange(evt) {
        this.setState({
            search: evt.target.value
        });
    }

    notifySuccessCopy() {
        toast("📋 Invitation link copied to clipboard");
    }

    async clear() {
        this.setState({
            search: "",
            inviteType: "",
            inviteStatus: "",
            skip: 0
        }, () => this.update());
    }

    componentDidMount() {
        OrganisationSelector.Init();
        this.populateData(this.state.skip, this.state.take);
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }

    renderTable(data) {
        return (<div className="table-responsive">
            <table className='table' aria-labelledby="tabelLabel">

                <TableHeader
                    sort={this.state.sort}
                    direction={this.state.direction}
                    columns={[
                        { name: "Name", sort: "name" },
                        { name: "User", sort: "user.name" },
                        { name: "Code", sort: "code" },
                        { name: "Expiry", sort: "expiry" },
                        { name: "Status", sort: "status" },
                        { name: "Type", sort: "type" },
                        { name: "Initiative", sort: "initiative.name" },
                        { name: "Created", sort: "created" },
                        { name: "", sort: "", static: true }]}
                    onUpdate={(sort, direction) => this.setState({
                        sort: sort,
                        direction: direction
                    }, () => this.update())}
                />
                <tbody>
                    {data.map(item =>
                        <tr key={item.id}>
                            <td>
                                {item.name} {item.surname}
                                <p className="muted">{item.email}</p>
                            </td>
                            <td>
                                {item.user && <Link key={item.user.id + "-view"} to={"/users/details/" + item.user.id}>{item.user.name} {item.user.surname}</Link>}
                                {!item.user && <i>Invite not used</i>}
                            </td>
                            <td>
                                {item.code}
                            </td>

                            <td>
                                {item.expiry && <Moment utc local format="ddd DD MMM, h:mm a">{item.expiry}</Moment>}
                                {!item.expiry && <i>Not specified</i>}
                            </td>

                            <td>
                                {item.status === "accepted" && <Badge color="success">Acepted</Badge>}
                                {item.status === "declined" && <Badge color="secondary">Declined</Badge>}
                                {item.status === "pending" && <Badge color="primary">Active</Badge>}
                                {item.status === "invalid" && <Badge color="secondary">Cancelled</Badge>}
                                {item.status === "expired" && <Badge color="secondary">Expired</Badge>}
                            </td>

                            <td>
                                {item.type === "joinInitiative" && <Badge color="success">Join Initiative</Badge>}
                                {item.type === "manageOrganisation" && <Badge color="secondary">Manage Organisation</Badge>}
                            </td>

                            <td>
                                {item.initiative && <Link key={item.initiative.id + "-view"} to={"/initiatives/details/" + item.initiative.id}>{item.initiative.name}</Link>}
                                {!item.initiative && <i>N/A</i>}
                            </td>

                            <td>
                                {item.created && <Moment utc local format="ddd DD MMM, h:mm a">{item.created}</Moment>}
                            </td>

                            <td>
                                <CopyToClipboard
                                    onCopy={this.notifySuccessCopy}
                                    text={"https://" + window.location.host + "/invitation/accept/" + item.code}>

                                    <div style={{ float: "right" }} className="mr-2 mt-2" >
                                        <button data-tip="React-tooltip" onClick={this.onClick} disabled={this.state.loading} className="btn btn-outline-dark" >
                                            <span className="pcoded-micon"><i className="feather icon-copy"></i></span>
                                        </button>
                                        <ReactTooltip place="top" type="dark" effect="solid"><span>Copy invite link</span></ReactTooltip>
                                    </div>
                                </CopyToClipboard>
                                <Link key={item.id + "-view"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/invitations/details/" + item.id}>Details</Link>
                            </td>
                        </tr>
                    )}
                    {this.state.data.length === 0 && <tr><td colSpan="9"><h4 className="text-muted text-center mt-3"><i>No invitations to display</i></h4></td></tr>}
                </tbody>
            </table>
        </div>
        );
    }

    render() {
        if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Invitations ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">List of invitations</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                        <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>
                                        <Link style={{ float: "right" }} to="/invitations/new" className="btn mr-2 btn-outline-dark mt-2 mb-2">New</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                    </Card>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>Filter</h5>
                                </Col>
                                <Col>
                                    <InputGroup>
                                        <Input type="select" name="select" value={this.state.inviteStatus} onChange={this.handleStatusChange} >
                                            <option value="">All statuses</option>
                                            <option value="pending">Active</option>
                                            <option value="accepted">Accepted</option>
                                            <option value="declined">Declined</option>
                                            <option value="invalid">Cancelled</option>
                                            <option value="expired">Expired</option>
                                        </Input>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <InputGroup>
                                        <Input type="select" name="select" value={this.state.inviteType} onChange={this.handleTypeChange} >
                                            <option value="">All types</option>
                                            <option value="joinInitiative">Join Initiative</option>
                                            <option value="manageOrganisation">Manage Organisation</option>
                                        </Input>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>

                                        <InputGroup>
                                            <Input type="text" placeholder="Search" value={this.state.search} onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()} />
                                            <ButtonGroup className="input-group-append">
                                                <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                    <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                                </button>
                                            </ButtonGroup>
                                            <ButtonGroup>
                                                {(this.state.search || this.state.inviteStatus || this.state.inviteType) &&
                                                    <button onClick={() => { this.clear(); }} className="btn btn-outline-dark ml-2" >
                                                        <span className="pcoded-micon">Clear</span>
                                                    </button>}
                                            </ButtonGroup>
                                        </InputGroup>
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>

                        <CardBody className="p-0">
                            {this.state.data && this.renderTable(this.state.data, this.state.nextPage, this.state.previousPage)}
                        </CardBody>
                    </Card>
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            );
        }
    }

    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async populateData(skip, take) {
        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var selectedOrg = await OrganisationSelector.GetSelectedOrg();
            var query = new InviteQueryParams();

            var response = await WellStreamInviteClient.List(query
                .Paginate(skip, take)
                .Search(this.state.search)
                .WithOrganisationId(selectedOrg.id)
                .WithStatus(this.state.inviteStatus)
                .WithType(this.state.inviteType)
                .Sort(this.state.sort, this.state.direction));

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data.data, loading: false, total: response.data.total });
            }
        }
    }
}
