import React, { Component } from 'react';
import { Container, Alert, Card, CardBody } from 'reactstrap';
import { WellStreamAPI } from '../../infrastructure/WellStreamAPI';
import { Link, Redirect } from 'react-router-dom';

export class Blocked extends Component {

    constructor(props) {
        super(props);
        this.state = { codeStatus: "", loading: true, success: false, next: false };
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/" />);
        } else {
            return (
                <Card>
                    <CardBody>
                        <div>
                            <Container>
                                <h3 className="mb-4">No access</h3>
                                <hr />
                                <div>
                                <Alert color="danger">
                                    <h5>Access denied</h5>
                                    You do not have access to the organisation portal, if you think you should have access please let your team lead know.
                                </Alert>
                                <Link className="btn mr-2 btn-outline-dark mt-2" to="/sign-in" onClick={() => { WellStreamAPI.LogOut(); this.setState({ next: true }); }}>Sign out</Link></div>
                            </Container>
                        </div>
                    </CardBody>
                </Card>
            );
        }
    }
}
