import React, { Component } from 'react';
import { Collapse, NavbarToggler, Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Route } from 'react-router';
import { OrganisationSelector } from '../../infrastructure/OrganisationSelector';
import { SelectField } from '../../infrastructure/SelectField';
import { WellStreamAPI, SubmissionParams } from '../../infrastructure/WellStreamAPI';
import { permissionContext } from '../../infrastructure/Context/permissionContext';
import FileSaver from 'file-saver';

export class SideBar extends Component {

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    async componentDidMount() {
        if (WellStreamAPI.IsAuthenticated()) {
            OrganisationSelector.Init();

            var selectedOrg = await OrganisationSelector.GetSelectedOrg();
            var orgs = (await OrganisationSelector.GetAllOrgs()).map(function (item) { return { value: item.id, label: item.name }; });

            this.setState({
                orgs: orgs,
                selectedOrg: orgs.find(item => item.value === selectedOrg.id)
            });

            var selectedMode = await OrganisationSelector.GetSelectedMode();
            var modes = (await OrganisationSelector.GetAllModes()).map(function (item) { return { value: item.id, label: item.name }; });

            this.setState({
                modes: modes,
                selectedMode: modes.find(item => item.value === selectedMode.id)
            });
        }
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {


        return (
            <permissionContext.Consumer>
                {({ permissions, mode }) => {

                    return (<div>

                        <NavbarToggler className="d-block d-md-none bg-dark sidebar" onClick={this.toggleNavbar}>
                            <span style={{ color: "white" }}>View menu</span>
                        </NavbarToggler>

                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar style={{ width: "100%", minWidth: "200px" }}>

                           <nav className="sidebar pcoded-navbar menu-light" style={{ paddingTop: "10px", zIndex: "0" }}>
                                <div className="sidebar-sticky" style={{ width: "100%", minWidth: "200px" }}>
                                    <ul className="nav flex-column pcoded-inner-navbar">

                                        {mode === "org" && <>
                                            {this.state.selectedOrg && this.state.orgs.length > 1 && <><li className="nav-item pcoded-menu-caption"><SelectField
                                                id="selectedOrgId"
                                                name="selectedOrgId"
                                                placeholder="Select organisation"
                                                options={this.state.orgs}
                                                value={this.state.selectedOrg}
                                                isMulti={false}
                                                onChange={(id, item) => {
                                                    OrganisationSelector.SetSelectedOrg(item.value);
                                                    this.setState({ reloadApplication: true, message: "Switching to new organisation." }, () => {
                                                        setTimeout(() => { window.location.reload(false); }, 1000);
                                                    });
                                                }}
                                                isClearable={true}
                                                backspaceRemovesValue={true}
                                            /></li>
                                            </>}

                                            {this.state.selectedOrg && this.state.orgs.length === 1 && <li className="nav-item pcoded-menu-caption"><h6>{this.state.selectedOrg.label}</h6></li>}

                                        </>}



                                        {mode === "org" && <>
                                            <li className="nav-item pcoded-menu-caption">
                                                <label>Organisation</label>
                                            </li>

                                            <Route path="/" exact>
                                                {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                    <Link className="nav-link" to="/">
                                                        <span className="pcoded-micon"><i className="feather icon-pie-chart"></i></span>
                                                        <span className="pcoded-mtext">Dashboard</span>
                                                    </Link>
                                                </li>}
                                            </Route>

                                            <Route path="/users" >
                                                {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                    <Link className="nav-link" to="/users">
                                                        <span className="pcoded-micon"><i className="feather icon-user"></i></span>
                                                        <span className="pcoded-mtext">Users</span>
                                                    </Link>
                                                </li>}
                                            </Route>

                                            <Route path="/initiatives" >
                                                {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                    <Link className="nav-link" to="/initiatives">
                                                        <span className="pcoded-micon"><i className="feather icon-radio"></i></span>
                                                        <span className="pcoded-mtext">Initiatives</span>
                                                    </Link>
                                                </li>}
                                            </Route>

                                            <Route path="/contacts" >
                                                {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                    <Link className="nav-link" to="/contacts">
                                                        <span className="pcoded-micon"><i className="feather icon-briefcase"></i></span>
                                                        <span className="pcoded-mtext">Contacts</span>
                                                    </Link>
                                                </li>}
                                            </Route>

                                            <Route path="/library" >
                                                {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                    <Link className="nav-link" to="/library">
                                                        <span className="pcoded-micon"><i className="feather icon-book"></i></span>
                                                        <span className="pcoded-mtext">Content library</span>
                                                    </Link>
                                                </li>}
                                            </Route>

                                            <li className="nav-item pcoded-menu-caption">
                                                <label>Manage</label>
                                            </li>

                                            <Route path="/invitations" >
                                                {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                    <Link className="nav-link" to="/invitations">
                                                        <span className="pcoded-micon"><i className="feather icon-mail"></i></span>
                                                        <span className="pcoded-mtext">Invitations</span>
                                                    </Link>
                                                </li>}
                                            </Route>
                                            <Route path="/categories" >
                                                {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                    <Link className="nav-link" to="/categories">
                                                        <span className="pcoded-micon"><i className="feather icon-layers"></i></span>
                                                        <span className="pcoded-mtext">Categories</span>
                                                    </Link>
                                                </li>}
                                            </Route>
                                            <Route path="/organisations/settings" >
                                                {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                    <Link className="nav-link" to="/organisations/settings">
                                                        <span className="pcoded-micon"><i className="feather icon-settings"></i></span>
                                                        <span className="pcoded-mtext">Settings</span>
                                                    </Link>
                                                </li>}
                                            </Route>

                                            {permissions.includes("organisations.billing.view") && <Route path="/subscription">
                                                {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                    <Link className="nav-link" to="/billing">
                                                        <span className="pcoded-micon"><i className="feather icon-credit-card"></i></span>
                                                        <span className="pcoded-mtext">Billing</span>
                                                    </Link>
                                                </li>}
                                            </Route>}
                                        </>}

                                        {mode === "admin" && <>

                                            {permissions.includes("users.view.all") && <Route path="/customers" >
                                                {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                    <Link className="nav-link" to="/customers">
                                                        <span className="pcoded-micon"><i className="feather icon-user"></i></span>
                                                        <span className="pcoded-mtext">Customers</span>
                                                    </Link>
                                                </li>}
                                            </Route>}


                                            {permissions.includes("publishers.view.all") && <>
                                                <li className="nav-item pcoded-menu-caption">
                                                    <label>Publishing</label>
                                                </li>
                                                <Route path="/content" >
                                                    {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                        <Link className="nav-link" to="/content">
                                                            <span className="pcoded-micon"><i className="feather icon-file-text"></i></span>
                                                            <span className="pcoded-mtext">Publish content</span>
                                                        </Link>
                                                    </li>}
                                                </Route>
                                                <Route path="/publishers" >
                                                    {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                        <Link className="nav-link" to="/publishers">
                                                            <span className="pcoded-micon"><i className="feather icon-edit"></i></span>
                                                            <span className="pcoded-mtext">Publishers</span>
                                                        </Link>
                                                    </li>}
                                                </Route>
                                            </>}

                                            {permissions.includes("categories.view.all") && <>
                                                <Route path="/global-categories" >
                                                    {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                        <Link className="nav-link" to="/global-categories">
                                                            <span className="pcoded-micon"><i className="feather icon-layers"></i></span>
                                                            <span className="pcoded-mtext">Categories</span>
                                                        </Link>
                                                    </li>}
                                                </Route>
                                            </>}

                                            {permissions.includes("roles.view.all") && <>
                                                <li className="nav-item pcoded-menu-caption">
                                                    <label>Administration</label>
                                                </li>
                                                <Route path="/roles" >
                                                    {({ match }) => <li className={(match ? 'active ' : undefined) + "nav-item pcoded-hasmenu"}>
                                                        <Link className="nav-link" to="/roles">
                                                            <span className="pcoded-micon"><i className="feather icon-file-text"></i></span>
                                                            <span className="pcoded-mtext">Roles</span>
                                                        </Link>
                                                    </li>}
                                                </Route>
                                            </>}
                                        </>}


                                        {this.state.selectedMode && this.state.modes.length > 1 && <>
                                            <li className="nav-item pcoded-menu-caption">
                                                <label>Mode</label>
                                            </li>
                                            <li className="nav-item pcoded-menu-caption" style={{ paddingTop: "5px" }}><SelectField
                                            id="selectedModeId"
                                            name="selectedModeId"
                                            placeholder="Select mode"
                                            options={this.state.modes}
                                            value={this.state.selectedMode}
                                            isMulti={false}
                                            onChange={(id, item) => {
                                                OrganisationSelector.SetSelectedMode(item.value);
                                                this.setState({ reloadApplication: true, message: "Switching to different portal." }, () => {
                                                    setTimeout(() => {
                                                        if (item.value == "admin") {
                                                            window.location.href = "/content";
                                                        } else {
                                                            window.location.href = "/";
                                                        }
                                                    }, 1000);
                                                });
                                            }}
                                            isClearable={true}
                                            backspaceRemovesValue={true}
                                            /></li>

                                            {mode === "admin" && permissions.includes("submissions.view.all") && <>
                                                <li className="nav-item pcoded-menu-caption">
                                                    <label>Export</label>
                                                </li>

                                                <li className="nav-item pcoded-menu-caption" style={{ paddingTop: "5px" }}>
                                                    <button style={{ width: "100%" }} onClick={() => { this.exportSubmissions(); }} disabled={this.state.exporting} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Export Submissions</button>
                                                </li>
                                            </>}

                                        </>}

                                    </ul>
                                </div>
                            </nav>

                        </Collapse>

                        <Modal isOpen={this.state.reloadApplication}>
                            <ModalHeader>Updating <Spinner style={{ float: "right" }} animation="border" /></ModalHeader>
                            <ModalBody>{this.state.message}</ModalBody>
                        </Modal>

                    </div>);
             }}
            </permissionContext.Consumer>
        );
    }

    async exportSubmissions() {

        this.setState({ exporting: true });

        var query = new SubmissionParams()
           .Sort("created", "desc").Paginate(0, 1);

        var blob = await WellStreamAPI.Export(query);

        FileSaver.saveAs(blob, "submissions.csv");

        this.setState({ exporting: false });
    }
}
