import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import "react-datepicker/dist/react-datepicker.css";
import { WellStreamPermissionsClient, PermissionQueryParams } from '../../infrastructure/WellStreamClient/Permissions';
import { WellStreamRoleClient } from '../../infrastructure/WellStreamClient/Roles';

export class RolesNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, authenticated: true, next: false
        };

    }

    componentDidMount() {
        this.populateData();
    }

    renderForm(permissionGroups) {
        return (<Formik
            initialValues={{
                name: '',
                description: '',
                permissions: []
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.name) {
                    setFieldError("name", "Please specify the role name");
                    return;
                }

                if (!fields.description) {
                    setFieldError("description", "Please give a description of the role");
                    return;
                }

                var response = await WellStreamRoleClient.Create(fields.name, fields.description, fields.permissions);

                if (!response.successful) {

                    response.validationErrors.map(error => {
                        setFieldError(error.key, error.message);
                        return {
                            [error.key]: error.message
                        };
                    });

                } else {
                    this.setState({ next: true, id: response.data.id });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
            <Form>
                <div className="form-group">
                    <label htmlFor="name">Role name*</label>
                    <Field name="name" placeholder="E.g. Content manager" type="text" className={'bgWhite form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                    <ErrorMessage name="name" component="div" className="invalid-feedback text-left" />
                </div>
                <div className="form-group">
                    <label htmlFor="description">Description*</label>
                    <Field name="description" rows="10" placeholder="E.g. Manages content for Wellstream" type="text" component="textarea" className={'bgWhite form-control' + (errors.description && touched.description ? ' is-invalid' : '')} />
                    <ErrorMessage name="description" component="div" className="invalid-feedback text-left" />
                </div>
                    <div className="form-group">
                        <h5>Permissions</h5>
                        <hr />
                        <FieldArray name="permissions"
                            render={arrayHelpers => (
                                <div className="row">
                                    {permissionGroups && permissionGroups.map(permissionGroup => {

                                        return (<Col md="4">
                                            <h6>{permissionGroup.name}</h6>
                                            <hr />
                                            <ul className="list-unstyled">
                                                {permissionGroup.permissions && permissionGroup.permissions.map(permission => {

                                                    return (
                                                        <li><label>
                                                            <input
                                                                name="permissions"
                                                                type="checkbox"
                                                                value={permission.id}
                                                                checked={values.permissions.includes(permission.id)}
                                                                onChange={e => {
                                                                    if (e.target.checked) arrayHelpers.push(permission.id);
                                                                    else {
                                                                        const idx = values.permissions.indexOf(permission.id);
                                                                        arrayHelpers.remove(idx);
                                                                    }
                                                                }}
                                                            />{" "}
                                                            {permission.name}
                                                        </label>
                                                            <p className="text-muted">{permission.description}</p>
                                                        </li>
                                                    )
                                                })}
                                            </ul></Col>)
                                    })}
                                </div>
                            )} />
                        <ErrorMessage name="permissions" component="div" className="invalid-feedback" />
                    </div>
                <hr />
                <div className="form-group">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "Create"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/roles/details/" + this.state.id} />);
        }  else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> New role
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Create a new role</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/roles" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.data && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }


    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await WellStreamPermissionsClient.ListGrouped(new PermissionQueryParams().Paginate(0, 50));

            if (!response.successful) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data.data, loading: false, total: response.data.total });
            }

            this.setState({
                init: true,
                loading: false
            });
        }
    }
}
