import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Spinner, Card, CardHeader, Row, Col, CardBody, Badge, Button } from 'reactstrap';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactMarkdown from 'react-markdown'
import { GetAssetDomain } from '../../infrastructure/Helper';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { WellStreamContentItemClient } from '../../infrastructure/WellStreamClient/ContentItems';
import { UsersTable } from '../users/UsersTable';
import { OrganisationSelector } from '../../infrastructure/OrganisationSelector';
import { ContentApprovalsTable } from '../contentapprovals/ContentApprovalsTable';

export class LibraryDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null, loading: false, authenticated: true, next: false,
            activeTab: '1'
        };

        this.toggle = this.toggle.bind(this);
        this.delete = this.delete.bind(this);
        this.download = this.download.bind(this);
    }

    async componentDidMount() {

        OrganisationSelector.Init();

        this.setState({
            assetUrl: await GetAssetDomain(),
            currentOrg: await OrganisationSelector.GetSelectedOrg()
        });
        this.populateData(this.props.match.params.id);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    async download() {

        var data = await WellStreamContentItemClient.GetDownloadLink(this.props.match.params.id);

        var link = document.createElement('a');
        link.href = data.data.link;
        //link.download = name;
        link.dispatchEvent(new MouseEvent('click'));

    }

    async delete() {
        var response = await WellStreamContentItemClient.Delete(this.props.match.params.id);
        if (response.successful) {
            this.setState({ next: true });
        } else {
            this.setState({ deleteFail: true });
        }
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/library" />);
        } else {
            return (

                <div>
                    {this.state.data && <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/library" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>Content items</span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                </Col>
                                <Col>
                                    {this.state.data && <div style={{ paddingRight: "10px" }}>
                                        {this.state.data.organisation && <Link style={{ float: "right" }} to={"/library/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                    </div>}
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>}


                    <Card>
                        <CardHeader>
                            <Nav pills style={{ boxShadow: "none" }}>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggle('1'); }}>
                                        Details</NavLink>
                                </NavItem>
                                {this.state.currentOrg && !this.state.currentOrg.autoApproveContent &&
                                    <NavItem>
                                        <NavLink
                                            href="#"
                                            className={classnames({ active: this.state.activeTab === '4' })}
                                            onClick={() => { this.toggle('4'); }}>
                                            Approvals</NavLink>
                                    </NavItem>}
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.toggle('2'); }}>
                                        Viewed</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '3' })}
                                        onClick={() => { this.toggle('3'); }}>
                                        Saved</NavLink>
                                </NavItem>
                            </Nav>
                        </CardHeader>
                    </Card>


                    <TabContent activeTab={this.state.activeTab} style={{ padding: "0", backgroundColor: "transparent" }}>
                        <TabPane tabId="1">
                            {this.state.activeTab === '1' && this.state.data && <><Card>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <h6>Details</h6>
                                            <hr />

                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td className="pr-2">Name:</td>
                                                        <td>
                                                            {this.state.data.name && this.state.data.name}
                                                            {!this.state.data.name && <i>Not specified</i>}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pr-2">Description:</td>
                                                        <td>
                                                            {this.state.data.description && this.state.data.description}
                                                            {!this.state.data.description && <i>Not specified</i>}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pr-2">Publisher:</td>
                                                        <td>
                                                            {this.state.data.publisher && this.state.data.publisher.name}
                                                            {!this.state.data.publisher && this.state.data.organisation && this.state.data.organisation.name}
                                                            {!this.state.data.publisher && !this.state.data.organisation && <span>Wellstream</span>}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pr-2">Category:</td>
                                                        <td>
                                                            {this.state.data.category && this.state.data.category.name}
                                                            {!this.state.data.category && <i>No cateogry</i>}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="pr-2">Visibility:</td>
                                                        <td>
                                                            {this.state.data.visibility === "private" && <Badge color="secondary">Draft</Badge>}
                                                            {this.state.data.visibility === "public" && <Badge color="success">Published</Badge>}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="pr-2">Type:</td>
                                                        <td>
                                                            {this.state.data.type === "markdown" && <Badge color="primary">Text</Badge>}
                                                            {this.state.data.type === "webArticle" && <Badge color="primary">Web article</Badge>}
                                                            {this.state.data.type === "video" && <Badge color="primary">Video</Badge>}
                                                            {this.state.data.type === "document" && <Badge color="primary">Document</Badge>}
                                                            {this.state.data.type === "audio" && <Badge color="primary">Audio/ Podcast</Badge>}
                                                        </td>
                                                    </tr>

                                                    {(this.state.data.type === "video" || this.state.data.type === "document" || this.state.data.type === "audio") && <tr>
                                                        <td colSpan="2">
                                                            <hr />
                                                            {!this.state.data.hasFileUploaded && <i>File not uploaded</i>}
                                                            {this.state.data.hasFileUploaded &&
                                                                <Button style={{ float: "left" }} to="/roles" onClick={this.download} className="btn mr-2 mt-2 mb-2 btn-primary">Download</Button>}
                                                        </td>
                                                    </tr>}
                                                </tbody>
                                            </table>
                                        </Col>
                                        <Col md="3">
                                            <h6 className="text-left">Thumbnail</h6>
                                            <hr />
                                            {!this.state.data.thumbnail && <h4 className="text-muted mt-3"><i>No image</i></h4>}
                                            {this.state.data.thumbnail && <img alt="Uploaded by submitted" src={this.state.assetUrl + "/image/" + this.state.data.thumbnail.id + ".jpg"} style={{ width: "150px", marginTop: "20px", objectFit: "cover" }} />}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                                {(this.state.data.type == "markdown" || this.state.data.type == "webArticle") && <Card>
                                    <CardBody>
                                        <h6>Content</h6>
                                        <hr />
                                        {this.state.data.type == "markdown" && <ReactMarkdown>{this.state.data.content}</ReactMarkdown>}
                                        {this.state.data.type == "webArticle" && <a href={this.state.data.content}>{this.state.data.content}</a>}
                                    </CardBody>
                                </Card>}

                                {this.state.data && this.state.data.organisation && <div className="alert alert-danger">
                                    {!this.state.deleteFail &&
                                        <Row>
                                            <Col>
                                                <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Danger: </strong> You can delete this piece of content, doing so will permanently remove it, please proceed with caution</p></Col>
                                            <Col>
                                                <Button style={{ float: "right" }} to="/roles" onClick={this.delete} className="btn mr-2 mt-2 mb-2 btn-danger">Delete content</Button>
                                            </Col>
                                        </Row>}

                                    {this.state.deleteFail &&
                                        <div>
                                            <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Failed: </strong> This content is currently in use, please make nothing is using it</p>
                                        </div>}
                                </div>}
                            </>}
                        </TabPane>
                        <TabPane tabId="2">
                            {this.state.activeTab === '2' && this.state.data && <UsersTable viewedContentItemId={this.state.data.id} />}
                        </TabPane>
                        <TabPane tabId="3">
                            {this.state.activeTab === '3' && this.state.data && <UsersTable savedContentItemId={this.state.data.id} />}
                        </TabPane>
                        {this.state.currentOrg && !this.state.currentOrg.autoApproveContent && <TabPane tabId="4">
                            {this.state.activeTab === '4' && this.state.data && <ContentApprovalsTable contentItemId={this.state.data.id} />}
                        </TabPane>}

                    </TabContent>

                </div>
            );
        }
    }


    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await WellStreamContentItemClient.Get(id);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false,
                    loading: false
                });
            } else {
                this.setState({ data: response.data, loading: false });
            }
        }
    }

}
