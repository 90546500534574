import { ApiResponse } from "../WellStreamAPI";
import { GetAPIDomain, GetAPIHeaders } from "../Helper";

export class WellStreamStatisticsClient {

    static async Dashboard(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/organisations/" + id + "/stats/dashboard", {
            headers: await GetAPIHeaders()
        }));

    }

    static async Graph(id: string, start: Date, end: Date) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/organisations/" + id + "/stats/dashboard/graph?interval=day&start=" + start.toISOString() + "&end=" + end.toISOString(), {
            headers: await GetAPIHeaders()
        }));

    }

    static async WeeklyGraph(id: string, start: Date, end: Date) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/organisations/" + id + "/stats/dashboard/graph?interval=week&start=" + start.toISOString() + "&end=" + end.toISOString(), {
            headers: await GetAPIHeaders()
        }));

    }

    static async MonthlyGraph(id: string, start: Date, end: Date) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/organisations/" + id + "/stats/dashboard/graph?interval=month&start=" + start.toISOString() + "&end=" + end.toISOString(), {
            headers: await GetAPIHeaders()
        }));

    }

    static async WeeklyGraphDetail(id: string, start: Date, end: Date) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/organisations/" + id + "/stats/dashboard/graph/details?missing=zero&interval=week&start=" + start.toISOString() + "&end=" + end.toISOString(), {
            headers: await GetAPIHeaders()
        }));

    }

    static async MonthlyRunningGraphDetail(id: string, start: Date, end: Date) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/organisations/" + id + "/stats/dashboard/graph/dow?missing=zero&interval=month&start=" + start.toISOString() + "&end=" + end.toISOString(), {
            headers: await GetAPIHeaders()
        }));

    }

}