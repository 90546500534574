import React, { Component } from 'react';
import { Container, Spinner, Alert, Card, CardBody, Row, Col } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { WellStreamAPI } from '../../infrastructure/WellStreamAPI';
import { Link, Redirect } from 'react-router-dom';
import { WellStreamInviteClient } from '../../infrastructure/WellStreamClient/Invites';
import { WellStreamTrustedSupporterClient } from '../../infrastructure/WellStreamClient/TrustedSupporters';

export class TrustedSupporterCheckDeclineInvitationCode extends Component {

    constructor(props) {
        super(props);
        this.state = { codeStatus: "", loading: true, success: false, screen: "" };
    }

    async componentDidMount() {

        if (WellStreamAPI.IsAuthenticated()) {
            WellStreamAPI.LogOut();
        }

        var result = await WellStreamTrustedSupporterClient.ValidateCode(this.props.match.params.id);

        this.setState({
            loading: false
        });

        if (result.data && result.data.status === "active") {
            this.setState({
                screen: "choice",
            });
            return;
        } else if (result.data && result.data.status === "declined") {
            this.setState({
                screen: "confirmed",
            });
            return;
        }

        //No valid state found
        this.setState({
            screen: "invalid-code"
        });
    }

    renderChoiceScreen() {

        return (<div className="text-center">

            <img width="150" src="/assets/images/ws-logo-portrait.jpg" />

            <h5 className="text-left mt-5">You are currently a trusted supporter</h5>
            <p className="text-left">If circumstances have changed and you can no longer be available for your friend, just use the button below to remove yourself from their list of trusted contacts.</p>

            <hr />

            <Row>
                <Col>
                    <Formik
                        initialValues={{
                            name: '',
                        }}
                        onSubmit={async (fields, { setErrors, setFieldError }) => {

                            var result = await WellStreamTrustedSupporterClient.Decline(this.props.match.params.id);
                            if (result.successful) {
                                this.setState({
                                    screen: "confirmed",
                                });
                            } else {
                                this.setState({
                                    screen: "error",
                                });
                            }

                        }}
                        render={({ errors, status, touched, isSubmitting }) => (
                            <Form autocomplete="off">
                                <div className="form-group">
                                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                        {!isSubmitting && "I NO LONGER WANT TO BE A TRUSTED SUPPORTER"}
                                        {isSubmitting && <Spinner animation="border" />}
                                    </button>
                                </div>
                            </Form>
                        )}
                    />
                </Col>
            </Row>
        </div>);
    }


    render() {

        return (
            <Card className="mt-5" style={{ maxWidth: "600px", width: "100%" }}>
                <CardBody>

                    {this.state.loading && <h4><Spinner animation="border" /> Checking code</h4>}

                    {this.state.screen === "choice" && this.renderChoiceScreen()}

                    {this.state.screen === "confirmed" && <div className="text-center">

                        <img width="150" src="/assets/images/ws-logo-portrait.jpg" />

                        <h5 className="text-left mt-5">You are not a trusted supporter</h5>
                        <p className="text-left">You are no longer on your friends list of trusted supporters, if circumstances ever change in the future be sure to reach out and let them know you are available again.</p>

                    </div>}

                    {this.state.screen === "error" && <div>
                        <Container>
                            <Alert color="danger" className="m-0">
                                <h5>Woops something went wrong</h5>
                                <p>Please try again later.</p>
                            </Alert>
                        </Container>
                    </div>}
                    {this.state.screen === "invalid-code" && <div>
                        <Container>
                            <Alert color="danger" className="m-0">
                                <h5>Code invalid</h5>
                                <p>This trusted supporter invitation link is no longer valid.</p>
                            </Alert>
                        </Container>
                    </div>}

                </CardBody>
            </Card>
        );

    }
}
