import React, { Component } from 'react';
import { Navbar, NavbarBrand, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { WellStreamAPI } from '../../infrastructure/WellStreamAPI';
import { permissionContext } from '../../infrastructure/Context/permissionContext';

export class NavMenu extends Component {

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <header>
                <Navbar className="navbar navbar-dark bg-dark flex-md-nowrap shadow" dark style={{ paddingTop: "10px", paddingBottom: "10px" }}>

                    <NavbarBrand style={{ textAlign: "left" }} className="navbar-brand col-sm-3 col-md-2 bg-dark" tag={Link} to="/">Wellstream</NavbarBrand>

                    <ul className="navbar-nav px-3 flex-row">

                        <permissionContext.Consumer>
                            {({ permissions, mode }) => {

                                return (permissions.includes("organisations.create") && <NavItem><NavLink tag={Link} className="pr-3" to="/organisations/create"><strong>Add Organisation</strong></NavLink></NavItem>);
                            }}
                        </permissionContext.Consumer>

                        {WellStreamAPI.IsAuthenticated() &&
                            <NavItem>
                                <NavLink tag={Link} className="text-dark" to="/" onClick={() => { WellStreamAPI.LogOut(); }}>Sign out</NavLink>
                            </NavItem>}

                        {!WellStreamAPI.IsAuthenticated() &&
                            <NavItem>
                                <NavLink tag={Link} className="text-dark" to="/sign-in">Sign in</NavLink>
                            </NavItem>
                        }
                    </ul>

                </Navbar>
            </header>
        );
    }
}
