import { ListQueryParams, ApiResponse } from "../WellStreamAPI";
import { GetAPIHeadersPatch, GetAPIDomain, GetAPIHeaders } from "../Helper";

export class InitiativeQueryParams extends ListQueryParams {

    WithOrganisationId(value: any) {
        this.WithParam("organisationId", value);
        return this;
    }

}


export class WellStreamInitiativeClient {

    static async List(params: InitiativeQueryParams) {

        var query = (await GetAPIDomain()) + "/initiatives" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/initiatives/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Create(orgId: string, name: string, description: string, billedToOrg: boolean, quickJoinEnabled: boolean, quickJoinCode: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/initiatives/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "name": name,
                "description": description,
                "organisationId": orgId,
                "subscriptionBilledToOrganisation": billedToOrg,
                "quickJoinEnabled": quickJoinEnabled,
                "quickJoinCode": quickJoinCode
            })
        }));
    }

    static async Update(id: string, name: string, description: string, billedToOrg: boolean, quickJoinEnabled: boolean, quickJoinCode: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/initiatives/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "name",
                "value": name
            },
                {
                    "op": "replace",
                    "path": "description",
                    "value": description
                },
                {
                    "op": "replace",
                    "path": "subscriptionBilledToOrganisation",
                    "value": billedToOrg
                },
                {
                    "op": "replace",
                    "path": "quickJoinEnabled",
                    "value": quickJoinEnabled
                },
                {
                    "op": "replace",
                    "path": "quickJoinCode",
                    "value": quickJoinCode
                }])
        }));
    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/initiatives/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }
}