import React, { Component } from 'react';
import { Container, Spinner, Alert, Card, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { WellStreamAPI } from '../../infrastructure/WellStreamAPI';
import { Link, Redirect } from 'react-router-dom';
import { WellStreamInviteClient } from '../../infrastructure/WellStreamClient/Invites';

export class CheckInvitationCode extends Component {

    constructor(props) {
        super(props);
        this.state = { codeStatus: "", loading: true, success: false, screen: "" };
    }

    async componentDidMount() {
        if (WellStreamAPI.IsAuthenticated()) {
            WellStreamAPI.LogOut();
        }

        //TODO: VALIDATE RESET CODE

        var result = await WellStreamInviteClient.ValidateCode(this.props.match.params.id);
        if (result.data && result.data.status === "active") {
            this.setState({
                screen: "check-email",
                inviteType: result.data.type === "joinInitiative" ? "initiative" : "org"
            });
        } else {
            this.setState({
                screen: "invalid-code"
            });
        }
    }


    renderCheckEmailForm() {

        return (<Formik
            initialValues={{
                email: ''
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {
                var response = await WellStreamAPI.CheckEmailAddress(fields.email);

                this.setState({
                    email: fields.email
                });

                if (response.data.status === "notValid") {
                    setFieldError("email", "Please enter a valid email address");
                } else if (response.data.status === "inUse") {
                    if (this.state.inviteType === "initiative") {
                        setFieldError("email", "This email address is already in use");
                    } else {
                        this.setState({ screen: "sign-up-existing-account" });
                    }
                } else {
                    this.setState({ screen: "sign-up" });
                }

            }}
            render={({ errors, status, touched, isSubmitting }) => (
                <Form>
                    <h3 className="mb-4">Enter your email address</h3>

                    {this.state.inviteType === "org" && <p>
                        Welcome to Wellstream, you've been invited to manage your organisation's account. Please enter the email you'd like to use to sign in to the service with.
                    </p>}

                    {this.state.inviteType !== "org" && <p>
                        Welcome to Wellstream, you've been invited to to participate in your organisation's wellbeing initiative. Please enter the email you'd like to use to sign in to the service with.
                    </p>}

                    <hr />
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <Field name="email" type="email" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "NEXT STEP"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}
        />);
    }

    renderSignUpAcceptInvite() {

        return (<Formik
            initialValues={{
                name: '',
                surname: '',
                mobile: '',
                password: ''
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.name) {
                    setFieldError("name", "Please specify your name");
                    return;
                }


                var response = await WellStreamAPI.SignUpWithCode(fields.name, fields.surname, fields.mobile, this.state.email, fields.password, this.props.match.params.id);

                if (!response.successful) {

                    response.validationErrors.map(error => {
                        setFieldError(error.key, error.message);
                        return {
                            [error.key]: error.message
                        };
                    });

                } else {
                    if (this.state.inviteType === "org") {
                        this.setState({ screen: "next-sign-in" });
                    } else {
                        this.setState({ screen: "next-download-app" });
                    }
                }

            }}
            render={({ errors, status, touched, isSubmitting }) => (
                <Form autocomplete="off">
                    <h5>Complete account</h5>
                    <p>Please let us know a little about yourself.</p>
                    <hr />
                    <div className="form-group">
                        <Field name="name" placeholder="Your name" type="text" className={'bgWhite form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                        <ErrorMessage name="name" component="div" className="invalid-feedback text-left" />
                    </div>
                    <div className="form-group">
                        <Field name="surname" placeholder="Your surname" type="text" className={'bgWhite form-control' + (errors.surname && touched.surname ? ' is-invalid' : '')} />
                        <ErrorMessage name="surname" component="div" className="invalid-feedback text-left" />
                    </div>
                    <div className="form-group">
                        <Field name="mobile" autoComplete="off" placeholder="Mobile" type="text" className={'bgWhite form-control' + (errors.mobile && touched.mobile ? ' is-invalid' : '')} />
                        <ErrorMessage name="mobile" component="div" className="invalid-feedback text-left" />
                    </div>
                    <hr />
                    <h5>Choose password</h5>
                    <p>This is the password you will use to log into the Wellstream mobile app on your phone.</p>
                    <hr />
                    <div className="form-group">
                        <input style={{ opacity: "0", height: "0", float: "left", margin: "0", padding: "0" }} type="text" name="fakeusernameremembered" />
                        <Field name="password" placeholder="Password" type="password" className={'bgWhite form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                        <ErrorMessage name="password" component="div" className="invalid-feedback text-left" />
                    </div>
                    <hr />
                    <div className="form-group">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "ACCEPT INVITATION"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}
        />);
    }

    renderSignUpExistingAccount() {

        return (<Formik
            initialValues={{
                password: ''
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (await WellStreamAPI.SignIn(this.state.email, fields.password, error => {

                    if (error === "too_many_attempts") {
                        setFieldError("password", "Too many attempts, please wait 5 minutes before trying again");
                    } else if (error === "account_locked") {
                        setFieldError("password", "Your account is locked, please contact concierge to unlock");
                    } else {
                        setFieldError("password", "Invalid email or password");
                    }

                })) {

                    await WellStreamInviteClient.AcceptForCurrentUser(this.props.match.params.id);
                    this.setState({ screen: "next-home" });
                }

            }}
            render={({ errors, status, touched, isSubmitting }) => (
                <Form>
                    <h5>Enter your password</h5>
                    <hr />
                    <div className="form-group">
                        <Field name="password" placeholder="Password" type="password" className={'bgWhite form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                        <ErrorMessage name="password" component="div" className="invalid-feedback text-left" />
                    </div>
                    <hr />
                    <div className="form-group">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "ACCEPT INVITATION"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}
        />);
    }


    render() {

        return (
            <Card style={{ maxWidth: "600px", width: "100%" }}>
                <CardBody>

                    {this.state.screen === "sign-up" && this.renderSignUpAcceptInvite()}
                    {this.state.screen === "sign-up-existing-account" && this.renderSignUpExistingAccount()}
                    {this.state.screen === "next-sign-in" && <Redirect to="/sign-in" />}
                    {this.state.screen === "next-home" && <Redirect to="/" />}
                    {this.state.screen === "check-email" && this.renderCheckEmailForm()}
                    {this.state.screen === "next-download-app" && <div>
                        <Container>
                            <h3 className="mb-4">Welcome to Wellstream</h3>
                            <hr />
                            <div>
                                <h5>Download the app</h5>
                                <p>Now you can install the WellStream app on your smartphone.</p>
                                <p>Download the WellStream app for Apple or Google Android devices. Search 'WellStream' on the app store on your phone, or use the links below.</p>
                                <p>Then log-in with the email and password combination that you just created.</p>
                                
                            </div>
                        </Container>
                    </div>}
                    {this.state.screen === "invalid-code" && <div>
                        <Container>
                            <Alert color="danger">
                                <h5>Code invalid</h5>
                                <p>The invitation link is no longer valid.</p>
                            </Alert>
                            <Link className="btn mr-2 btn-outline-dark mt-2" to="/sign-in">Go to sign in page</Link>
                        </Container>
                    </div>}

                </CardBody>
            </Card>
        );

    }
}
