import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import "react-datepicker/dist/react-datepicker.css";
import { WellStreamInitiativeClient } from '../../infrastructure/WellStreamClient/Initiatives';
import { OrganisationSelector } from '../../infrastructure/OrganisationSelector';

export class InitiativesNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, authenticated: true, next: false
        };

    }

    componentDidMount() {
        OrganisationSelector.Init();
        this.populateData();
    }

    renderForm() {
        return (<Formik
            initialValues={{
                name: '',
                description: '',
                quickJoinEnabled: '',
                billedToOrg: '',
                quickJoinCode: '',
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.name) {
                    setFieldError("name", "Please specify the initiative name");
                    return;
                }

                if (!fields.description) {
                    setFieldError("description", "Please give a description of the initiative");
                    return;
                }

                var selectedOrg = await OrganisationSelector.GetSelectedOrg();
                var response = await WellStreamInitiativeClient.Create(selectedOrg.id, fields.name, fields.description, fields.billedToOrg, fields.quickJoinEnabled, fields.quickJoinCode);

                if (!response.successful) {

                    response.validationErrors.map(error => {
                        setFieldError(error.key, error.message);
                        return {
                            [error.key]: error.message
                        };
                    });

                } else {
                    this.setState({ next: true, id: response.data.id });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
            <Form>
                <div className="form-group">
                    <label htmlFor="name">Initiative name*</label>
                    <Field name="name" placeholder="E.g. Employee Wellbeing" type="text" className={'bgWhite form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                    <ErrorMessage name="name" component="div" className="invalid-feedback text-left" />
                </div>
                <div className="form-group">
                    <label htmlFor="description">Description*</label>
                    <Field name="description" rows="10" placeholder="E.g. Welcome to our company wellbeing improvement initiative. Our aim is to..." type="text" component="textarea" className={'bgWhite form-control' + (errors.description && touched.description ? ' is-invalid' : '')} />
                    <ErrorMessage name="description" component="div" className="invalid-feedback text-left" />
                    </div>
                    <div className="form-group">
                        <label>
                            <input
                                name="billedToOrg"
                                type="checkbox"
                                value="True"
                                checked={values.billedToOrg}
                                onChange={e => {
                                    setFieldValue("billedToOrg", e.target.checked)
                                }}
                            /> Subscriptions billed to organisation
                        </label>
                    </div>
                    <Row>
                        <Col md="3">
                            <label>
                            <input
                                    name="quickJoinEnabled"
                                type="checkbox"
                                value="True"
                                    checked={values.quickJoinEnabled}
                                onChange={e => {
                                    setFieldValue("quickJoinEnabled", e.target.checked)
                                }}
                            /> Quick Join Enabled
                        </label>
                        </Col>
                        {values.quickJoinEnabled && <Col md="9">
                            <label htmlFor="quickJoinCode">Quick Join Code</label>
                            <Field name="quickJoinCode" placeholder="E.g. WS100" type="text" className={'bgWhite form-control' + (errors.quickJoinCode && touched.quickJoinCode ? ' is-invalid' : '')} />
                            <ErrorMessage name="quickJoinCode" component="div" className="invalid-feedback text-left" />
                        </Col>}
                    </Row>

                <hr />
                <div className="form-group">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "Create"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/initiatives/details/" + this.state.id} />);
        }  else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> New initiative
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Start a new initiative</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/initiatives" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.data && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }


    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            this.setState({
                init: true,
                loading: false
            });
        }
    }
}
