import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Spinner, Card, CardHeader, Row, Col, CardBody, Button } from 'reactstrap';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GetAssetDomain } from '../../infrastructure/Helper';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { permissionContext } from '../../infrastructure/Context/permissionContext';
import { WellStreamPublisherClient } from '../../infrastructure/WellStreamClient/Publishers';

export class PublishersDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null, loading: false, authenticated: true, next: false,
            activeTab: '1'
        };

        this.toggle = this.toggle.bind(this);
        this.delete = this.delete.bind(this);
    }

    async componentDidMount() {
        this.setState({
            assetUrl: await GetAssetDomain()
        });
        this.populateData(this.props.match.params.id);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    async delete() {
        var response = await WellStreamPublisherClient.Delete(this.props.match.params.id);
        if (response.successful) {
            this.setState({ next: true });
        } else {
            this.setState({ deleteFail: true });
        }
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/publishers" />);
        } else {
            return (

                <div>
                    {this.state.data && <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/publishers" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>Publishers</span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                </Col>
                                <Col>
                                    <permissionContext.Consumer>
                                        {({ permissions }) => {

                                            return (permissions.includes("publishers.modify") && <div style={{ paddingRight: "10px" }}>
                                                {this.state.data && <Link style={{ float: "right" }} to={"/publishers/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                            </div>);
                                        }}
                                    </permissionContext.Consumer>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>}


                    <Card>
                        <CardHeader>
                            <Nav pills style={{ boxShadow: "none" }}>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggle('1'); }}>
                                        Details</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.toggle('2'); }}>
                                        Content</NavLink>
                                </NavItem>
                            </Nav>
                        </CardHeader>
                    </Card>


                    <TabContent activeTab={this.state.activeTab} style={{ padding: "0", backgroundColor: "transparent" }}>
                        <TabPane tabId="1">
                            {this.state.activeTab === '1' && this.state.data && <><Card>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <h6>Details</h6>
                                            <hr />

                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td className="pr-2">Name:</td>
                                                        <td>
                                                            {this.state.data.name && this.state.data.name}
                                                            {!this.state.data.name && <i>Not specified</i>}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pr-2">Website:</td>
                                                        <td>
                                                            {this.state.data.website && this.state.data.website}
                                                            {!this.state.data.website && <i>Not specified</i>}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="pr-2">Description:</td>
                                                        <td>
                                                            {this.state.data.description && this.state.data.description}
                                                            {!this.state.data.description && <i>Not specified</i>}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Col>
                                        <Col>
                                            <h6 className="text-left">Profile image</h6>
                                            <hr />
                                            {!this.state.data.logo && <h4 className="text-muted mt-3"><i>No image</i></h4>}
                                            {this.state.data.logo && <img alt="Uploaded by submitted" src={this.state.assetUrl + "/image/" + this.state.data.logo.id + ".jpg"} style={{ width: "250px", marginTop: "20px", objectFit: "cover" }} />}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                                <permissionContext.Consumer>
                                    {({ permissions }) => {

                                        return (permissions.includes("publishers.modify") && this.state.data && <div className="alert alert-danger">
                                            {!this.state.deleteFail &&
                                                <Row>
                                                    <Col>
                                                        <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Danger: </strong> You can delete this publisher, doing so will permanently remove it, please proceed with caution</p></Col>
                                                    <Col>
                                                        <Button style={{ float: "right" }} to="/roles" onClick={this.delete} className="btn mr-2 mt-2 mb-2 btn-danger">Delete publisher</Button>
                                                    </Col>
                                                </Row>}

                                            {this.state.deleteFail &&
                                                <div>
                                                <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Failed: </strong> This publisher is currently in use, please make no content items are assigned to this publisher</p>
                                                </div>}
                                        </div>);

                                    }}
                                </permissionContext.Consumer>
                                </>}
                        </TabPane>
                        <TabPane tabId="2">
                            {this.state.activeTab === '2' && <h3>TODO: ATTRIBUTED CONTENT</h3>}
                        </TabPane>

                    </TabContent>

                </div>
            );
        }
    }


    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await WellStreamPublisherClient.Get(id);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false,
                    loading: false
                });
            } else {
                this.setState({ data: response.data, loading: false });
            }
        }
    }

}
