import React, { Component } from 'react';
import { Route } from 'react-router';
import { PrivateRoute } from './infrastructure/PrivateRoute';
import { permissionContext } from './infrastructure/Context/permissionContext'

import { WellStreamUserClient } from './infrastructure/WellStreamClient/Users';
import { OrganisationSelector } from './infrastructure/OrganisationSelector';

import { Layout } from './components/layout/Layout';
import { SignIn } from './components/SignIn';
import { Register } from './components/Register';
import { Home } from './components/Home';

import { OnboardingBusiness } from './components/onboarding/OnboardingBusiness';
import { OnboardingInitiative } from './components/onboarding/OnboardingInitiative';

import { PasswordResetBegin } from './components/pages/PasswordResetBegin';
import { PasswordResetComplete } from './components/pages/PasswordResetComplete';
import { Blocked } from './components/pages/Blocked';
import { CheckInvitationCode } from './components/pages/CheckInvitationCode';
import { TrustedSupporterCheckAcceptInvitationCode } from './components/pages/TrustedSupporterCheckAcceptInvitationCode';
import { TrustedSupporterCheckDeclineInvitationCode } from './components/pages/TrustedSupporterCheckDeclineInvitationCode';

import { Initiatives } from './components/initiatives/Initiatives';
import { InitiativesNew } from './components/initiatives/InitiativesNew';
import { InitiativesEdit } from './components/initiatives/InitiativesEdit';
import { InitiativesDetails } from './components/initiatives/InitiativesDetails';

import { Roles } from './components/roles/Roles';
import { RolesNew } from './components/roles/RolesNew';
import { RolesEdit } from './components/roles/RolesEdit';
import { RolesDetails } from './components/roles/RolesDetails';

import { Customers } from './components/customers/Customers';
import { CustomersDetails } from './components/customers/CustomersDetails';
import { CustomersEdit } from './components/customers/CustomersEdit';

import { Users } from './components/users/Users';
import { UsersEdit } from './components/users/UsersEdit';
import { UsersDetails } from './components/users/UsersDetails';

import { Invites } from './components/invites/Invites';
import { InvitesNew } from './components/invites/InvitesNew';
import { InvitesDetails } from './components/invites/InvitesDetails';
import { InvitesEdit } from './components/invites/InvitesEdit';

import { Publishers } from './components/publishers/Publishers';
import { PublishersNew } from './components/publishers/PublishersNew';
import { PublishersDetails } from './components/publishers/PublishersDetails';
import { PublishersEdit } from './components/publishers/PublishersEdit';

import { GlobalCategories } from './components/categories/admin/GlobalCategories';
import { GlobalCategoryNew } from './components/categories/admin/GlobalCategoryNew';
import { GlobalCategoryDetails } from './components/categories/admin/GlobalCategoryDetails';
import { GlobalCategoryEdit } from './components/categories/admin/GlobalCategoryEdit';
import { Categories } from './components/categories/org/Categories';
import { CategoryNew } from './components/categories/org/CategoryNew';
import { CategoryDetails } from './components/categories/org/CategoryDetails';
import { CategoryEdit } from './components/categories/org/CategoryEdit';

import { ContentItems } from './components/contentitems/ContentItems';
import { ContentItemsNew } from './components/contentitems/ContentItemsNew';
import { ContentItemsDetails } from './components/contentitems/ContentItemsDetails';
import { ContentItemsEdit } from './components/contentitems/ContentItemsEdit';

import { Library } from './components/library/Library';
import { LibraryNew } from './components/library/LibraryNew';
import { LibraryDetails } from './components/library/LibraryDetails';
import { LibraryEdit } from './components/library/LibraryEdit';

import { Settings } from './components/settings/Settings';
import { SettingsEdit } from './components/settings/SettingsEdit';
import { OrganisationsCreate } from './components/organisations/OrganisationsCreate';
import { CancelAccountDeleteBegin } from './components/pages/CancelAccountDeleteBegin';

import { Contacts } from './components/contacts/Contacts';
import { ContactsNew } from './components/contacts/ContactsNew';
import { ContactsDetails } from './components/contacts/ContactsDetails';
import { ContactsEdit } from './components/contacts/ContactsEdit';

import './custom.css'
import { WellStreamPermissionsClient } from './infrastructure/WellStreamClient/Permissions';
import { WellStreamAPI } from './infrastructure/WellStreamAPI';

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        this.state = {
            initComplete: false,
            permissions: [],
            hasAdminAccess: false,
            hasOrgAccess: false,
            mode: "org",
            selectedOrgId: null
        };
        this.refreshApplicationState = this.refreshApplicationState.bind(this);
    }

    async componentDidMount() {
        await this.refreshApplicationState();
    }

    async refreshApplicationState() {

        if (WellStreamAPI.IsAuthenticated()) {
            console.log("Refreshing access data");

            await OrganisationSelector.Refresh();

            var actions = await WellStreamUserClient.GetActions("current");
            if (actions.successful) {
                this.setState({
                    hasAdminAccess: actions.data.hasAdminAccess,
                    hasOrgAccess: actions.data.hasOrgAccess
                });
            }

            if (!actions.data.hasOrgAccess && !actions.data.hasAdminAccess) {
                this.setState({
                    next: "blocked",

                    initComplete: true
                });
                return;
            }

            var selectedOrg = await OrganisationSelector.GetSelectedOrg();
            if (selectedOrg != null) {
                this.setState({ selectedOrgId: selectedOrg.id });
            }

            var selectedMode = await OrganisationSelector.GetSelectedMode();

            if (selectedMode != null) {
                this.setState({ mode: selectedMode.id });
            }

            var permissions = await WellStreamPermissionsClient.ForCurrentUser();

            if (permissions.successful) {
                this.setState({
                    permissions: permissions.data.data.map(p => p.id),
                    initComplete: true
                });
            }

            this.setState({
                initComplete: true
            });

        }
    }

    render() {

        const value = {
            permissions: this.state.permissions,
            hasAdminAccess: this.state.hasAdminAccess,
            hasOrgAccess: this.state.hasOrgAccess,
            mode: this.state.mode,
            selectedOrgId: this.state.selectedOrgId,
            initComplete: this.state.initComplete,
            refresh: this.refreshApplicationState
        }

        return (
            <permissionContext.Provider value={value}>
                <Layout>

                    <Route exact path='/sign-in' component={SignIn} />
                    <Route exact path='/register' component={Register} />
                    <Route exact path='/password-reset/begin' component={PasswordResetBegin} />
                    <Route exact path='/password-reset/complete/:code' component={PasswordResetComplete} />
                    <Route exact path='/invite/accept/:id' component={CheckInvitationCode} />
                    <Route exact path='/account-delete-request/cancel/:id' component={CancelAccountDeleteBegin} />

                    <Route exact path='/trusted-supporter/invite/accept/:id' component={TrustedSupporterCheckAcceptInvitationCode} />
                    <Route exact path='/trusted-supporter/invite/decline/:id' component={TrustedSupporterCheckDeclineInvitationCode} />

                    <PrivateRoute exact path='/onboarding/step-01' component={OnboardingBusiness} />
                    <PrivateRoute exact path='/onboarding/step-02' component={OnboardingInitiative} />
                    <PrivateRoute exact path='/onboarding/no-access' component={Blocked} />

                    <PrivateRoute exact path='/initiatives' component={Initiatives} />
                    <PrivateRoute exact path='/initiatives/new' component={InitiativesNew} />
                    <PrivateRoute exact path='/initiatives/edit/:id' component={InitiativesEdit} />
                    <PrivateRoute exact path='/initiatives/details/:id' component={InitiativesDetails} />

                    <PrivateRoute exact path='/roles' component={Roles} />
                    <PrivateRoute exact path='/roles/new' component={RolesNew} />
                    <PrivateRoute exact path='/roles/edit/:id' component={RolesEdit} />
                    <PrivateRoute exact path='/roles/details/:id' component={RolesDetails} />

                    <PrivateRoute exact path='/invitations' component={Invites} />
                    <PrivateRoute exact path='/invitations/new' component={InvitesNew} />
                    <PrivateRoute exact path='/invitations/details/:id' component={InvitesDetails} />
                    <PrivateRoute exact path='/invitations/edit/:id' component={InvitesEdit} />

                    <PrivateRoute exact path='/publishers' component={Publishers} />
                    <PrivateRoute exact path='/publishers/new' component={PublishersNew} />
                    <PrivateRoute exact path='/publishers/details/:id' component={PublishersDetails} />
                    <PrivateRoute exact path='/publishers/edit/:id' component={PublishersEdit} />

                    <PrivateRoute exact path='/global-categories' component={GlobalCategories} />
                    <PrivateRoute exact path='/global-categories/new' component={GlobalCategoryNew} />
                    <PrivateRoute exact path='/global-categories/details/:id' component={GlobalCategoryDetails} />
                    <PrivateRoute exact path='/global-categories/edit/:id' component={GlobalCategoryEdit} />

                    <PrivateRoute exact path='/categories' component={Categories} />
                    <PrivateRoute exact path='/categories/new' component={CategoryNew} />
                    <PrivateRoute exact path='/categories/details/:id' component={CategoryDetails} />
                    <PrivateRoute exact path='/categories/edit/:id' component={CategoryEdit} />

                    <PrivateRoute exact path='/content' component={ContentItems} />
                    <PrivateRoute exact path='/content/new' component={ContentItemsNew} />
                    <PrivateRoute exact path='/content/details/:id' component={ContentItemsDetails} />
                    <PrivateRoute exact path='/content/edit/:id' component={ContentItemsEdit} />

                    <PrivateRoute exact path='/library' component={Library} />
                    <PrivateRoute exact path='/library/new' component={LibraryNew} />
                    <PrivateRoute exact path='/library/details/:id' component={LibraryDetails} />
                    <PrivateRoute exact path='/library/edit/:id' component={LibraryEdit} />

                    <PrivateRoute exact path='/users' component={Users} />
                    <PrivateRoute exact path='/users/details/:id' component={UsersDetails} />
                    <PrivateRoute exact path='/users/edit/:id' component={UsersEdit} />

                    <PrivateRoute exact path='/customers' component={Customers} />
                    <PrivateRoute exact path='/customers/details/:id' component={CustomersDetails} />
                    <PrivateRoute exact path='/customers/edit/:id' component={CustomersEdit} />

                    <PrivateRoute exact path='/organisations/settings' component={Settings} />
                    <PrivateRoute exact path='/organisations/edit/:id' component={SettingsEdit} />
                    <PrivateRoute exact path='/organisations/create' component={OrganisationsCreate} />

                    <PrivateRoute exact path='/contacts' component={Contacts} />
                    <PrivateRoute exact path='/contacts/new' component={ContactsNew} />
                    <PrivateRoute exact path='/contacts/details/:id' component={ContactsDetails} />
                    <PrivateRoute exact path='/contacts/edit/:id' component={ContactsEdit} />

                    <PrivateRoute exact path='/' component={Home} />

                </Layout>
            </permissionContext.Provider>
        );
    }
}
