import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { GetAssetDomain, GetAPIDomain } from '../../infrastructure/Helper';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { CategorySelector } from '../../infrastructure/Selectors/CategorySelector';
import { WellStreamContentItemClient } from '../../infrastructure/WellStreamClient/ContentItems';
import ReactMarkdown from 'react-markdown'
import { PublisherSelector } from '../../infrastructure/Selectors/PublisherSelector';
import { WellStreamFileClient } from '../../infrastructure/WellStreamClient/Files';

export class LibraryEdit extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, make: "", model: "", loading: true, authenticated: true, next: false, name: "", description: "" };

    }

    componentDidMount() {
        this.populateData();
    }

    renderForm() {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {

            return (<Formik
                initialValues={{
                    name: this.state.name,
                    description: this.state.description,
                    website: this.state.website,
                    visibility: this.state.visibility,
                    documentText: this.state.content,
                    categoryId: this.state.categoryId,
                    website: this.state.content
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {


                    if (!fields.name) {
                        setFieldError("name", "Please specify the publisher name");
                        return;
                    }

                    if (!fields.description) {
                        setFieldError("description", "Please give a description of the publisher");
                        return;
                    }

                    var imageId = this.state.imageId;

                    if (fields.file) {

                        let formData = new FormData();
                        formData.append("file", fields.file);

                        var imageResult = await fetch((await GetAPIDomain()) + "/uploads/images", {
                            method: 'post',
                            headers: {
                                'Authorization': 'Bearer ' + localStorage.getItem("acess-token")
                            },
                            body: formData
                        });

                        if (imageResult.ok) {
                            imageId = (await imageResult.json()).id;
                        }
                    }

                    var content = this.state.content;

                    if (this.state.type == "markdown")
                        content = fields.documentText;

                    if (this.state.type == "webArticle")
                        content = fields.website;

                    console.log(fields.categoryId);

                    var response = await WellStreamContentItemClient.Update(this.props.match.params.id, fields.name, fields.description, content, this.state.type, fields.visibility, null, imageId, fields.categoryId ? fields.categoryId.value : null);

                    if (this.state.type == "document" && fields.document) {
                        await WellStreamFileClient.UploadContentFile(this.props.match.params.id, fields.document);
                    } else if (this.state.type == "video" && fields.video) {
                        await WellStreamFileClient.UploadContentFile(this.props.match.params.id, fields.video);
                    } else if (this.state.type == "audio" && fields.audio) {
                        await WellStreamFileClient.UploadContentFile(this.props.match.params.id, fields.audio);
                    }

                    if (!response.successful) {

                        if (response.status === 404) {
                            this.setState({ next: true });
                        } else {

                            response.validationErrors.map(error => {
                                setFieldError(error.key, error.message);
                                return {
                                    [error.key]: error.message
                                };
                            });
                        }

                    } else {
                        this.setState({ next: true });
                    }

                }}
            >{({ errors, handleChange, handleBlur, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                <Form>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="name">Title*</label>
                                <Field name="name" placeholder="Title of the content" type="text" className={'bgWhite form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                                <ErrorMessage name="name" component="div" className="invalid-feedback text-left" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="description">Description*</label>
                                <Field name="description" rows="5" placeholder="Description of what the content is" type="text" component="textarea" className={'bgWhite form-control' + (errors.description && touched.description ? ' is-invalid' : '')} />
                                <ErrorMessage name="description" component="div" className="invalid-feedback text-left" />
                            </div>
                            <Row>
                                <Col>
                                    <div className="form-group">

                                        <label htmlFor="visibility">Visibility</label>
                                        <select type="select" name="visibility"
                                            value={values.visibility}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={'form-control' + (errors.visibility && touched.visibility ? ' is-invalid' : '')}>
                                            <option value="private">Draft</option>
                                            <option value="public">Published</option>
                                        </select>

                                        <ErrorMessage name="visibility" component="div" className="invalid-feedback" />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <PublisherSelector
                                            initialId={this.state.publisherId}
                                            placeholder="Select publisher"
                                            value={values.publisherId}
                                            onChange={setFieldValue} />
                                    </div>
                                    </Col>
                                    <Col>
                                        <div className="form-group">
                                            <CategorySelector
                                                initialId={this.state.categoryId}
                                                placeholder="Select category"
                                                value={values.categoryId}
                                                onChange={setFieldValue} />
                                        </div>
                                    </Col>
                            </Row>

                            {this.state.type == "webArticle" &&
                                <div className="form-group">
                                    <label htmlFor="website">Website</label>
                                    <Field name="website" placeholder="E.g. https://google.com" type="text" className={'bgWhite form-control' + (errors.website && touched.website ? ' is-invalid' : '')} />
                                    <ErrorMessage name="website" component="div" className="invalid-feedback text-left" />
                                </div>}

                                {this.state.type == "video" &&
                                <div className="form-group">
                                    <h6 className="text-left">Video</h6>

                                    {this.state.video && <div>
                                        <video width="320" height="240" controls >
                                            <source src={this.state.video} type="video/mp4" />
                                        </video>
                                    </div>}

                                    <input style={{ "overflow": "hidden", marginTop: "25px" }} className="form-control-file" id="video" name="video" type="file" onChange={(event) => {

                                        this.setState({
                                            video: URL.createObjectURL(event.target.files[0])
                                        })
                                        setFieldValue("video", event.currentTarget.files[0]);
                                    }} />

                                </div>}

                            {this.state.type == "audio" &&
                                <div className="form-group">
                                    <h6 className="text-left">Audio/ podcast</h6>

                                    {this.state.audio && <div>
                                        <audio width="320" height="240" controls >
                                            <source src={this.state.audio} />
                                        </audio>
                                    </div>}

                                    <input style={{ "overflow": "hidden", marginTop: "25px" }} className="form-control-file" id="audio" name="audio" type="file" onChange={(event) => {

                                        this.setState({
                                            video: URL.createObjectURL(event.target.files[0])
                                        })
                                        setFieldValue("audio", event.currentTarget.files[0]);
                                    }} />

                                </div>}

                            {this.state.type == "document" &&
                                <div className="form-group">
                                    <h6 className="text-left">Replace document</h6>

                                    <input style={{ "overflow": "hidden", marginTop: "25px" }} className="form-control-file" id="document" name="document" type="file" onChange={(event) => {

                                        this.setState({
                                            document: URL.createObjectURL(event.target.files[0])
                                        })
                                        setFieldValue("document", event.currentTarget.files[0]);
                                    }} />

                                </div>}
                        </Col>
                        <Col md="3" className="text-center">
                            <div className="form-group">
                                <h6 className="text-left">Thumbnail</h6>

                                {this.state.file && <div>
                                    <img alt="Uploaded supporting file" src={this.state.file} style={{ width: "250px", marginTop: "20px", objectFit: "cover" }} />
                                </div>}

                                <input style={{ "overflow": "hidden", marginTop: "25px" }} className="form-control-file" id="file" name="file" type="file" onChange={(event) => {

                                    this.setState({
                                        file: URL.createObjectURL(event.target.files[0])
                                    })
                                    setFieldValue("file", event.currentTarget.files[0]);
                                }} />

                            </div>
                        </Col>
                    </Row>

                    {this.state.type == "markdown" && <Row>
                        <Col><div className="form-group">
                            <label htmlFor="documentText">Content*</label>
                            <hr />
                            <Field name="documentText" rows="20" placeholder="Markdown is supported" type="text" component="textarea" className={'bgWhite form-control' + (errors.documentText && touched.documentText ? ' is-invalid' : '')} />
                            <ErrorMessage name="documentText" component="div" className="invalid-feedback text-left" />
                        </div></Col>
                        <Col md="3">
                            <label htmlFor="documentText">Preview</label>
                            <hr />
                            <p>
                                <ReactMarkdown>{values.documentText}</ReactMarkdown>
                            </p>
                        </Col>
                    </Row>}

                    <hr />

                    <div className="form-group mt-3">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "SAVE"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/library/details/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update content
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Update content information</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/library/details/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {
            this.setState({ init: true });

            var response = await WellStreamContentItemClient.Get(this.props.match.params.id);

            if (!response.authenticated) {
                this.setState({
                    loading: false,
                    authenticated: false
                });
            } else {

                if (response.data.thumbnail) {
                    this.setState({
                        file: (await GetAssetDomain()) + "/image/" + response.data.thumbnail.id + ".jpg"
                    });
                }

                this.setState({
                    loading: false,
                    name: response.data.name,
                    content: response.data.content,
                    description: response.data.description,
                    visibility: response.data.visibility,
                    imageId: response.data.thumbnail ? response.data.thumbnail.id : null,
                    publisherId: response.data.publisher ? response.data.publisher.id : null,
                    categoryId: response.data.category ? response.data.category.id : null,
                    type: response.data.type
                });
            }
        }
    }
}
