import React, { Component } from 'react';
import { Row } from 'reactstrap';
import { Route } from 'react-router';
import { Redirect, Link } from 'react-router-dom';
import { SideBar } from './SideBar';
import { NavMenu } from './NavMenu';
import { WellStreamAPI } from '../../infrastructure/WellStreamAPI';
import { permissionContext } from '../../infrastructure/Context/permissionContext';

export class Layout extends Component {
    static displayName = Layout.name;

    componentDidMount() {
        
    }

    render() {

        if (WellStreamAPI.IsAuthenticated()) {
            return (
                <>
                <Route path="/sign-in">
                    {({ match }) => {
                            if (match)
                                return (<Redirect to="/" />);
                            else
                                return (<></>);
                    }}
                </Route>
                <Route path="/onboarding">
                    {({ match }) => {
                            if (match) {
                                return (<div className="auth-wrapper aut-bg-img" style={{ backgroundImage: "url('assets/images/bg-images/bg3.jpg')" }}>
                                    {this.props.children}
                                </div>)
                            } else {

                                return (<permissionContext.Consumer>
                                    {({ initComplete, mode, hasAdminAccess, hasOrgAccess }) => {


                                        console.log(initComplete + ":" + hasAdminAccess + ":" + hasOrgAccess);
                                        if (initComplete && !hasAdminAccess && !hasOrgAccess) {


                                            return (<Redirect to="/onboarding/no-access" />);
                                        }

                                        return(<div className="bg-light">
                                            <NavMenu />
                                            <Row style={{ marginRight: 0 }}>
                                                <div className="col-md-2">
                                                    <SideBar />
                                                </div>
                                                <div className="col-md-10">
                                                    <div className="container-fluid" style={{ paddingTop: "20px" }}>
                                                        {this.props.children}
                                                    </div>
                                                </div>
                                            </Row>

                                        </div>)

                                    }}
                                </permissionContext.Consumer>);
                            }
                    }}
                </Route>
                </>);
        } else {
            return (
                <div className="auth-wrapper aut-bg-img" style={{ backgroundImage: "url('assets/images/bg-images/bg3.jpg')" }}>
                    {this.props.children}
                </div>
            );
        }
    }

}
