import React, { Component } from 'react';
import { Container, Spinner, Alert, Card, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Moment from 'react-moment';
import { WellStreamAPI } from '../../infrastructure/WellStreamAPI';
import { Link } from 'react-router-dom';

export class CancelAccountDeleteBegin extends Component {

    constructor(props) {
        super(props);
        this.state = { codeStatus: "", loading: true, success: false, scheduledDelete: null };
    }

    componentDidMount() {
        if (WellStreamAPI.IsAuthenticated()) {
            WellStreamAPI.LogOut();
        }
        this.populateData(this.props.match.params.id);
    }


    async populateData(id) {
        var response = await WellStreamAPI.GetAccountDeleteRequest(id);
        console.log(response);
        this.setState({
            loading: false,
            scheduledDelete: response.scheduled
        });
    }

    renderContent() {

        if (this.state.loading) {
            return (<div><Spinner animation="border" /></div>);
        }else  if (this.state.scheduledDelete == null) {
            return (<div><Alert color="info" style={{ color: "#00529f" }}>
                <h5 style={{ color: "#00529f" }}>Request not found</h5>
                This delete account request no longer exists, or has been cancelled.
            </Alert></div>);
        } else {
            return (<Formik
                initialValues={{
                    name: '',
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {
                    if (!await WellStreamAPI.CancelAccountDeleteRequest(this.props.match.params.id)) {
                        this.setState({ success: false });
                    } else {
                        this.setState({ success: true });
                    }
                }}
                render={({ errors, status, touched, isSubmitting }) => (
                    <Form>
                        <div className="form-group">
                            <p>Your account is scheduled to be deleted on <b><Moment utc local format="ddd DD MMM, h:mm a">{this.state.scheduledDelete}</Moment></b></p>
                        </div>
                        <hr />
                        <div className="form-group">
                            <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting} style={{ width: "100%" }}>
                                {!isSubmitting && "CANCEL DELETE REQUEST"}
                                {isSubmitting && <Spinner animation="border" />}
                            </button>
                        </div>
                    </Form>
                )}
            />);
        }
    }

    renderSuccessScreen() {

        return (<div><Alert color="success">
            <h5 style={{ color: "#299b85" }}>Success</h5>
            Your account will no longer be deleted.
                    </Alert></div>);
    }

    render() {

        return (
            <Card style={{ maxWidth: "600px", width: "100%" }}>
                <CardBody>
                    <div>
                        <Container>
                            <h3 className="mb-4">Delete account request</h3>
                            <hr />
                            {!this.state.success && this.renderContent()}
                            {this.state.success && this.renderSuccessScreen()}
                        </Container>
                    </div>

                </CardBody>
            </Card>
        );

    }
}