import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { GetAssetDomain } from '../../infrastructure/Helper';
import { WellStreamOrganisationClient } from '../../infrastructure/WellStreamClient/Organisations';
import { OrganisationSelector } from '../../infrastructure/OrganisationSelector';
import { UsersTable } from '../users/UsersTable';

export class Settings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null, loading: false, authenticated: true, next: false,
            activeTab: '1'
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    async componentDidMount() {
        OrganisationSelector.Init();
        this.setState({
            assetUrl: await GetAssetDomain()
        });
        this.populateData();
    }

    async populateData() {
        this.setState({
            loading: true
        });
        var selectedOrg = await OrganisationSelector.GetSelectedOrg();
        var item = await WellStreamOrganisationClient.Get(selectedOrg.id);

        if (!item.authenticated) {
            this.setState({
                authenticated: false
            });
        } else {

            this.setState({
                data: item.data,
                name: item.data.name,
                loading: false,
                init: true
            });

        }
    }

    render() {
        return (<div>

            <Card>
                <CardHeader>
                    <Row>
                        <Col>
                            <h5>
                                {this.state.data && <span>Manage your organisation</span>}
                                {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                            </h5>
                        </Col>
                        <Col>
                            <div style={{ paddingRight: "10px" }}>
                                {this.state.data && <Link style={{ float: "right" }} to={"/organisations/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                            </div>
                        </Col>
                    </Row>

                </CardHeader>
            </Card>


            <Card>
                <CardHeader>
                    <Nav pills style={{ boxShadow: "none" }}>
                        <NavItem>
                            <NavLink
                                href="#"
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggle('1'); }}>
                                Details</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                href="#"
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggle('2'); }}>
                                Staff</NavLink>
                        </NavItem>
                    </Nav>
                </CardHeader>
            </Card>

            <TabContent activeTab={this.state.activeTab} style={{ padding: "0", backgroundColor: "transparent" }}>
                <TabPane tabId="1">
                    {this.state.activeTab === '1' && this.state.data && <Card>
                        {this.state.data && <CardBody>
                            <Row>
                                <Col>
                                    <table style={{ marginBottom: "25px" }}>
                                        <tbody>
                                            <tr>
                                                <td className="pr-2">Name:</td>
                                                <td>
                                                    {this.state.data.name && this.state.data.name}
                                                    {!this.state.data.name && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Description:</td>
                                                <td>
                                                    {this.state.data.description && this.state.data.description}
                                                    {!this.state.data.description && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Content approval:</td>
                                                <td>
                                                    {this.state.data.autoApproveContent && "All Wellstream content is available"}
                                                    {!this.state.data.autoApproveContent && "Wellstream must be manually approved"}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    {this.state.data.subscription && <div className="alert alert-info">
                                        <h6>Subscription</h6>
                                        <hr />
                                        <p><b>{this.state.data.subscription.name}</b><br /> {this.state.data.subscription.status === "trial" && <i>Trial period ends 20 June</i>}</p>
                                        <hr />
                                        <p>
                                            Billing is by invoice, contact your account manager for details
                                        </p>
                                    </div>}
                                </Col>
                                <Col md="3" className="text-center">
                                    <h6 className="text-left">Logo</h6>
                                    <hr />
                                    {!this.state.data.logo && <h4 className="text-muted mt-3"><i>No image</i></h4>}
                                    {this.state.data.logo && <img alt="Uploaded by submitted" src={this.state.assetUrl + "/image/" + this.state.data.logo.id + ".jpg"} style={{ width: "250px", marginTop: "20px", objectFit: "cover" }} />}
                                </Col>
                            </Row>
                        </CardBody>}

                    </Card>}
                </TabPane>
                <TabPane tabId="2">
                    {this.state.activeTab === '2' && <UsersTable userType="manager" hideFilter={true} description="List of users in this initiative" />}
                </TabPane>

            </TabContent>

        </div>);
    }
}
