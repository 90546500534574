import React, { Component } from 'react';
import { Container, Spinner, Alert, Card, CardBody, Row, Col } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { WellStreamAPI } from '../../infrastructure/WellStreamAPI';
import { Link, Redirect } from 'react-router-dom';
import { WellStreamInviteClient } from '../../infrastructure/WellStreamClient/Invites';
import { WellStreamTrustedSupporterClient } from '../../infrastructure/WellStreamClient/TrustedSupporters';

export class TrustedSupporterCheckAcceptInvitationCode extends Component {

    constructor(props) {
        super(props);
        this.state = { codeStatus: "", loading: true, success: false, screen: "" };
    }

    async componentDidMount() {

        if (WellStreamAPI.IsAuthenticated()) {
            WellStreamAPI.LogOut();
        }

        var result = await WellStreamTrustedSupporterClient.ValidateCode(this.props.match.params.id);

        this.setState({
            loading: false
        });

        if (result.data && result.data.status === "pending") {
            this.setState({
                screen: "choice",
            });
            return;
        }

        //No valid state found
        this.setState({
            screen: "invalid-code"
        });
    }

    renderChoiceScreen() {

        return (<div className="text-center">

            <img width="150" src="/assets/images/ws-logo-portrait.jpg" />

            <h5 className="text-left mt-5">Becoming a trusted supporter</h5>
            <p className="text-left">Your friend is using WellStream to help improve their mental wellbeing. During this process they may need to reach out and talk to someone, especially when they are going through a rough patch.</p>
            <p className="text-left">You don't need to do anything other than be available to listen if they need help. By accepting this invite you will saved to their contact list as someone who they can reach out to.</p>

            <hr />

            <Row>
                <Col>
                    <Formik
                        initialValues={{
                            name: '',
                        }}
                        onSubmit={async (fields, { setErrors, setFieldError }) => {

                            var result = await WellStreamTrustedSupporterClient.Accept(this.props.match.params.id);
                            if (result.successful) {
                                this.setState({
                                    screen: "accepted",
                                });
                            } else {
                                this.setState({
                                    screen: "error",
                                });
                            }

                        }}
                        render={({ errors, status, touched, isSubmitting }) => (
                            <Form autocomplete="off">
                                <div className="form-group">
                                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                        {!isSubmitting && "ACCEPT INVITATION"}
                                        {isSubmitting && <Spinner animation="border" />}
                                    </button>
                                </div>
                            </Form>
                        )}
                    />
                </Col>
                <Col>
                    <Formik
                        initialValues={{
                            name: '',
                        }}
                        onSubmit={async (fields, { setErrors, setFieldError }) => {

                            var result = await WellStreamTrustedSupporterClient.Decline(this.props.match.params.id);
                            if (result.successful) {
                                this.setState({
                                    screen: "declined",
                                });
                            } else {
                                this.setState({
                                    screen: "error",
                                });
                            }

                        }}
                        render={({ errors, status, touched, isSubmitting }) => (
                            <Form autocomplete="off">
                                <div className="form-group">
                                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                        {!isSubmitting && "DECLINE INVITATION"}
                                        {isSubmitting && <Spinner animation="border" />}
                                    </button>
                                </div>
                            </Form>
                        )}
                    />
                </Col>
            </Row>
        </div>);
    }


    render() {

        return (
            <Card className="mt-5" style={{ maxWidth: "600px", width: "100%" }}>
                <CardBody>

                    {this.state.loading && <h4><Spinner animation="border" /> Checking code</h4>}

                    {this.state.screen === "choice" && this.renderChoiceScreen()}

                    {this.state.screen === "accepted" && <div className="text-center">

                        <img width="150" src="/assets/images/ws-logo-portrait.jpg" />

                        <h5 className="text-left mt-5">Support confirmed</h5>
                        <p className="text-left">Thank you for supporting your friend, you'll be added to their list of trusted supporters.</p>

                    </div>}

                    {this.state.screen === "declined" && <div className="text-center">

                        <img width="150" src="/assets/images/ws-logo-portrait.jpg" />

                        <h5 className="text-left mt-5">Invitation declined</h5>
                        <p className="text-left">Thank you letting us know you are unavilable, we will make sure you are not on your friend's list of trusted supporters.</p>

                    </div>}

                    {this.state.screen === "error" && <div>
                        <Container>
                            <Alert color="danger" className="m-0">
                                <h5>Woops something went wrong</h5>
                                <p>Please try again later.</p>
                            </Alert>
                        </Container>
                    </div>}
                    {this.state.screen === "invalid-code" && <div>
                        <Container>
                            <Alert color="danger" className="m-0">
                                <h5>Code invalid</h5>
                                <p>This trusted supporter invitation link is no longer valid.</p>
                            </Alert>
                        </Container>
                    </div>}

                </CardBody>
            </Card>
        );

    }
}
