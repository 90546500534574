import { ListQueryParams, ApiResponse } from "../WellStreamAPI";
import { GetAPIHeadersPatch, GetAPIDomain, GetAPIHeaders } from "../Helper";

export class PublisherQueryParams extends ListQueryParams {

}


export class WellStreamPublisherClient {

    static async List(params: PublisherQueryParams) {

        var query = (await GetAPIDomain()) + "/publishers" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/publishers/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Create(name: string, description: string, website?: string, imageId?: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/publishers/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "name": name,
                "description": description,
                "website": website,
                "imageId": imageId
            })
        }));
    }

    static async Update(id: string, name: string, description: string, website?: string, imageId?: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/publishers/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "name",
                "value": name
            },
            {
                "op": "replace",
                "path": "description",
                "value": description
            },
            {
                "op": "replace",
                "path": "website",
                "value": website
            },
            {
                "op": "replace",
                "path": "imageId",
                "value": imageId
            }])
        }));
    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/publishers/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }
}