import { ListQueryParams, ApiResponse } from "../WellStreamAPI";
import { GetAPIHeadersPatch, GetAPIDomain, GetAPIHeaders } from "../Helper";

export class ContentItemQueryParams extends ListQueryParams {

    WithOrganisationId(value: any) {
        this.WithParam("organisationId", value);
        return this;
    }

    WithPublisher(value: any) {
        this.WithParam("publisherId", value);
        return this;
    }

    WithInitiative(value: any) {
        this.WithParam("approvedForInitiativeId", value);
        return this;
    }

    WithOrganisationWide(value: any) {
        this.WithParam("approvedForOrganisationId", value);
        return this;
    }

    SavedByUser(value: any) {
        this.WithParam("savedByUserId", value);
        return this;
    }

    ReadByUser(value: any) {
        this.WithParam("readByUserId", value);
        return this;
    }

}

export class ContentItemOrganisationQueryParams extends ListQueryParams {


    WithPublisher(value: any) {
        this.WithParam("publisherId", value);
        return this;
    }

    WithInitiative(value: any) {
        this.WithParam("initiativeId", value);
        return this;
    }

    WithApproved(value: any) {
        this.WithParam("approved", value);
        return this;
    }
}


export class WellStreamContentItemClient {

    static async List(params: ContentItemQueryParams) {

        var query = (await GetAPIDomain()) + "/content-items" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }
    
    static async GetDownloadLink(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/content-items/" + id+"/download", {
            headers: await GetAPIHeaders()
        }));

    }


    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/content-items/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Create(name: string, description: string, content: string, type: string, visibility: string, organisationId?: string, publisherId?: string, imageId?: string, categoryId?: string,) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/content-items/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "name": name,
                "description": description,
                "content": content,
                "type": type,
                "visibility": visibility,
                "organisationId": organisationId,
                "publisherId": publisherId,
                "categoryId": categoryId,
                "imageId": imageId
            })
        }));
    }

    static async Update(id: string, name: string, description: string, content: string, type: string, visibility: string, publisherId?: string, imageId?: string, categoryId?: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/content-items/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "name",
                "value": name
            },
            {
                "op": "replace",
                "path": "description",
                "value": description
            },
            {
                "op": "replace",
                "path": "content",
                "value": content
            },
            {
                "op": "replace",
                "path": "type",
                "value": type
            },
            {
                "op": "replace",
                "path": "visibility",
                "value": visibility
                },
                {
                    "op": "replace",
                    "path": "publisherId",
                    "value": publisherId
                },
                {
                    "op": "replace",
                    "path": "categoryId",
                    "value": categoryId
                },
            {
                "op": "replace",
                "path": "imageId",
                "value": imageId
            }])
        }));
    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/content-items/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }
}