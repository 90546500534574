import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody, Input, InputGroup, ButtonGroup, Badge } from 'reactstrap';
import { WellStreamUserClient, UserQueryParams } from '../../infrastructure/WellStreamClient/Users';
import { TableHeader } from '../../infrastructure/TableHeader';
import { OrganisationSelector } from '../../infrastructure/OrganisationSelector';
import Moment from 'react-moment';
import { InitiativeSelector } from '../../infrastructure/Selectors/InitiativeSelector';

export class UsersTable extends Component {

    constructor(props) {
        super(props);
        this.state = { data: [], loading: false, total: 0, skip: 0, take: 50, userStatus: "", userType: "", authenticated: true, search: "", sort: "name", direction: "asc", showSuspended: false, initiativeId:"" };
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleShowSuspended = this.handleShowSuspended.bind(this);

        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleInitiativeIdChange = this.handleInitiativeIdChange.bind(this);
    }

    handleShowSuspended = (evt) => {
        this.setState({
            showSuspended: evt.target.checked
        }, () => this.update());
    }

    handleSearchChange(evt) {
        this.setState({
            search: evt.target.value
        });
    }

    handleStatusChange(evt) {
        this.setState({
            userStatus: evt.target.value
        }, () => this.update());
    }

    handleTypeChange(evt) {
        this.setState({
            userType: evt.target.value
        }, () => this.update());
    }

    handleInitiativeIdChange(name, value) {

        this.setState({
            initiativeId: value
        }, () => this.update());
    }

    async clear() {
        this.setState({
            search: "",
            userType: "",
            userStatus: "",
            initiativeId: "",
            skip: 0
        }, () => this.update());
    }

    componentDidMount() {
        OrganisationSelector.Init();
        this.populateData(this.state.skip, this.state.take);
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }

    renderTable(data) {

        const {
            userType,
            initiativeId,
            allUsers,
        } = this.props;

        var columns = [
            { name: "Name", sort: "name" },
            { name: "Access", sort: "status" },
            { name: "Email", sort: "email" },
            { name: "Mobile", sort: "mobile" },
            { name: "Joined", sort: "created" },
            { name: "", sort: "", static: true }];

        if (!initiativeId)
            columns.splice(4, 0, { name: "Initiative", sort: "initiative.name" });

        if (!userType)
            columns.splice(2, 0, { name: "Type", sort: "", static: true });

        return (<div className="table-responsive">
            <table className='table' aria-labelledby="tabelLabel">

                <TableHeader
                    sort={this.state.sort}
                    direction={this.state.direction}
                    columns={columns}
                    onUpdate={(sort, direction) => this.setState({
                        sort: sort,
                        direction: direction
                    }, () => this.update())}
                />
                <tbody>
                    {data.map(item =>
                        <tr key={item.id}>
                            <td>
                                {item.name && <span>{item.name} {item.surname && item.surname}</span>}
                                {!item.name && <i>Name not specified</i>}
                                {allUsers && <div>
                                    {item.initiative && item.initiative.organisation && <span>{item.initiative.organisation.name}</span>}
                                    {(item.initiative == null || item.initiative.organisation == null) && <i>Unaffiliated</i>}
                                </div>}
                            </td>
                            <td>
                                {item.status === "active" && <Badge color="success">Granted</Badge>}
                                {item.status === "suspended" && <Badge color="secondary">Suspended</Badge>}
                            </td>
                            {!userType && <td>
                                {item.type === "individual" && <Badge color="success">Individual</Badge>}
                                {item.type === "organisation" && <Badge color="success">Participant</Badge>}
                                {item.type === "manager" && <Badge color="primary">Manager</Badge>}
                                {item.type === "admin" && <Badge color="secondary">Admin</Badge>}
                            </td>}
                            <td>
                                {item.email && item.email}
                                {!item.email && <i>Not specified</i>}
                            </td>
                            <td>
                                {item.mobile && item.mobile}
                                {!item.mobile && <i>Not specified</i>}
                            </td>
                            {!initiativeId && allUsers && <td>
                                {item.initiative && item.initiative.name}
                                {!item.initiative && <i>None</i>}
                            </td>}
                            {!initiativeId && !allUsers && <td>
                                {item.initiative && item.initiative.organisation.id === this.state.orgId && <Link key={item.initiative.id + "-view"} to={"/initiatives/details/" + item.initiative.id}>{item.initiative.name}</Link>}
                                {item.initiative && item.initiative.organisation.id !== this.state.orgId && <i>Different organisation</i>}
                                {!item.initiative && <i>None</i>}
                            </td>}
                            <td>
                                {item.created && <Moment utc local format="ddd DD MMM, h:mm a">{item.created}</Moment>}
                            </td>
                            <td>
                                <Link key={item.id + "-view"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/" + (allUsers?"customers":"users")+"/details/" + item.id}>Details</Link>
                                <Link key={item.id + "-edit"} style={{ float: "right" }} className="btn mr-2 btn-outline-dark mt-2" to={"/" + (allUsers ? "customers" : "users") +"/edit/" + item.id}>Edit</Link>
                            </td>
                        </tr>
                    )}
                    {this.state.data.length === 0 && <tr><td colSpan="8"><h4 className="text-muted text-center mt-3"><i>No users to display</i></h4></td></tr>}
                </tbody>
            </table>
        </div>
        );
    }

    render() {


        const {
            hideFilter,
            description
        } = this.props;


        if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Users ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">{description} {!description && "List of users in your organisation"}</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                        <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                    </Card>
                    <Card>
                        {!hideFilter && <CardHeader>
                            <Row>
                                <Col>
                                    <h5>Filter</h5>
                                </Col>
                                {this.state.showSuspended && < Col >
                                    <InputGroup>
                                        <Input type="select" name="select" value={this.state.userStatus} onChange={this.handleStatusChange} >
                                            <option value="">All statuses</option>
                                            <option value="active">Active</option>
                                            <option value="suspended">Suspended</option>
                                        </Input>
                                    </InputGroup>
                                </Col>}
                                <Col>
                                    <InputGroup>
                                        <Input type="select" name="select" value={this.state.userType} onChange={this.handleTypeChange} >
                                            <option value="">All types</option>
                                            <option value="organisation">User</option>
                                            <option value="manager">Organisation manager</option>
                                        </Input>
                                    </InputGroup>
                                </Col>

                                <Col>
                                    <InitiativeSelector
                                        placeholder="Initiative"
                                        value={this.state.initiativeId}
                                        onChange={this.handleInitiativeIdChange}
                                        hideLabel={true} />
                                </Col>

                                <Col>
                                    <div style={{ paddingRight: "10px" }}>

                                        <InputGroup>
                                            <Input type="text" placeholder="Search" value={this.state.search} onChange={this.handleSearchChange} onKeyPress={e => e.key === 'Enter' && this.update()} />
                                            <ButtonGroup className="input-group-append">
                                                <button onClick={() => { this.update(); }} disabled={this.state.loading} className="btn btn-outline-dark" >
                                                    <span className="pcoded-micon"><i className="feather icon-search"></i></span>
                                                </button>
                                            </ButtonGroup>
                                            <ButtonGroup>
                                                {(this.state.search || this.state.userStatus || this.state.userType || this.state.initiativeId) &&
                                                    <button onClick={() => { this.clear(); }} className="btn btn-outline-dark ml-2" >
                                                        <span className="pcoded-micon">Clear</span>
                                                    </button>}
                                            </ButtonGroup>
                                        </InputGroup>
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>}


                        {!hideFilter && <CardHeader>
                            <Row>
                                <Col></Col>
                                <Col md="4">
                                    <div className="form-group">
                                        <ul className="list-unstyled list-inline" style={{ float: "right", margin: "0" }}>
                                            <li className="list-inline-item">
                                                <label style={{ margin: "0" }}>
                                                    <input name="showSuspended"
                                                        type="checkbox"
                                                        checked={this.state.showSuspended}
                                                        onChange={this.handleShowSuspended} />  Show suspended
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>}

                        <CardBody className="p-0">
                            {this.state.data && this.renderTable(this.state.data, this.state.nextPage, this.state.previousPage)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async populateData(skip, take) {

        const {
            roleId,
            userType,
            initiativeId,
            viewedContentItemId,
            savedContentItemId,
            allUsers
        } = this.props;

        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            if (!allUsers) {
                var selectedOrg = await OrganisationSelector.GetSelectedOrg();
                this.setState({
                    orgId: selectedOrg.id
                });
            }

            var query = new UserQueryParams().Paginate(skip, take)
                .Search(this.state.search)
                .WithStatus(this.state.showSuspended ? this.state.userStatus : null)
                .WithType(userType ?? this.state.userType)
                .WithRoleId(roleId)
                .WithShowSuspended(this.state.showSuspended ? true : false)
                .WithInitiativeId(initiativeId ?? (this.state.initiativeId ? this.state.initiativeId.value : null))
                .WithParam("savedContentItemId", savedContentItemId)
                .WithParam("viewedContentItemId", viewedContentItemId);

            if (!allUsers) {
                query = query.WithOrganisationId(selectedOrg.id);
            }

            var response = await WellStreamUserClient.List(query
                .Sort(this.state.sort, this.state.direction));

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data.data, loading: false, total: response.data.total });
            }
        }
    }
}
