import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import { WellStreamRoleClient } from '../../infrastructure/WellStreamClient/Roles';
import { WellStreamPermissionsClient, PermissionQueryParams } from '../../infrastructure/WellStreamClient/Permissions';
import { permissionContext } from '../../infrastructure/Context/permissionContext';

export class RolesEdit extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, make: "", model: "", loading: true, authenticated: true, next: false, name: "", description: "" };

    }

    componentDidMount() {
        this.populateData();
    }

    renderForm(permissionGroups) {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {

            return (<permissionContext.Consumer>
                {({ refresh }) => {

                    return (<Formik
                initialValues={{
                    name: this.state.name,
                    description: this.state.description,
                    permissions: this.state.permissions
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {


                    if (!fields.name) {
                        setFieldError("name", "Please specify the role name");
                        return;
                    }

                    if (!fields.description) {
                        setFieldError("description", "Please give a description of the role");
                        return;
                    }

                    var response = await WellStreamRoleClient.Update(this.props.match.params.id, fields.name, fields.description, fields.permissions);

                    if (!response.successful) {

                        if (response.status === 404) {
                            this.setState({ next: true });
                        } else {

                            response.validationErrors.map(error => {
                                setFieldError(error.key, error.message);
                                return {
                                    [error.key]: error.message
                                };
                            });
                        }

                    } else {
                        await refresh();
                        this.setState({ next: true });
                    }

                }}
            >{({ errors, handleChange, handleBlur, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                <Form>
                    <div className="form-group">
                        <label htmlFor="name">Role name*</label>
                        <Field name="name" placeholder="E.g. Content manager" type="text" className={'bgWhite form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                        <ErrorMessage name="name" component="div" className="invalid-feedback text-left" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="description">Description*</label>
                        <Field name="description" rows="10" placeholder="E.g. Manages content for Wellstream" type="text" component="textarea" className={'bgWhite form-control' + (errors.description && touched.description ? ' is-invalid' : '')} />
                        <ErrorMessage name="description" component="div" className="invalid-feedback text-left" />
                    </div>
                    <div className="form-group">
                        <h5>Permissions</h5>
                        <hr />
                        <FieldArray name="permissions"
                            render={arrayHelpers => (
                                <div className="row">
                                    {permissionGroups && permissionGroups.map(permissionGroup => {

                                        return (<Col md="4" key={permissionGroup.name}>
                                            <h6>{permissionGroup.name}</h6>
                                            <hr />
                                            <ul className="list-unstyled">
                                                {permissionGroup.permissions && permissionGroup.permissions.map(permission => {

                                                    return (
                                                        <li key={permission.id}><label>
                                                            <input
                                                                name="permissions"
                                                                type="checkbox"
                                                                value={permission.id}
                                                                checked={values.permissions.includes(permission.id)}
                                                                onChange={e => {
                                                                    if (e.target.checked) arrayHelpers.push(permission.id);
                                                                    else {
                                                                        const idx = values.permissions.indexOf(permission.id);
                                                                        arrayHelpers.remove(idx);
                                                                    }
                                                                }}
                                                            />{" "}
                                                            {permission.name}
                                                        </label>
                                                            <p className="text-muted">{permission.description}</p>
                                                        </li>
                                                    )
                                                })}
                                            </ul></Col>)
                                    })}
                                </div>
                            )} />
                        <ErrorMessage name="permissions" component="div" className="invalid-feedback" />
                    </div>
                    <hr />

                    <div className="form-group mt-3">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "SAVE"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
                        )}</Formik>);

                }}
            </permissionContext.Consumer>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/roles/details/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update role
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Update role information</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/roles/details/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {
            this.setState({ init: true });

            var response = await WellStreamPermissionsClient.ListGrouped(new PermissionQueryParams().Paginate(0, 50));

            if (!response.successful) {
                this.setState({
                    authenticated: false
                });
            } else {
                var role = await WellStreamRoleClient.Get(this.props.match.params.id);

                this.setState({
                    data: response.data.data,
                    loading: false,
                    total: response.data.total,
                    permissions: role.data.permissions
                        .filter(p => p.selected)
                        .map(p => p.id),
                    name: role.data.name,
                    description: role.data.description
                });
            }
        }
    }
}
