import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { SelectField } from '../SelectField';
import { InitiativeQueryParams, WellStreamInitiativeClient } from '../WellStreamClient/Initiatives';
import { OrganisationSelector } from '../OrganisationSelector';

export class InitiativeSelector extends Component {

    defaultId() {
        return "initiativeId";
    }

    constructor(props) {
        OrganisationSelector.Init();
        super(props);
        this.state = { init: false };
    }


    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {

        const {
            initialId,
            displayFormat,
            name
        } = this.props;

        var displayFormatter = (item) => {

            var display = displayFormat;

            if (!displayFormat)
                display = (item) => item.name;

            return display(item);

        }

        var query = new InitiativeQueryParams();

        var selectedOrg = await OrganisationSelector.GetSelectedOrg();
        var data = (await WellStreamInitiativeClient.List(query
            .WithOrganisationId(selectedOrg.id).Paginate(0, 10000))).data;

        if (data) {
            this.setState({
                options: data.data.map(function (item) {
                    return {
                        value: item.id,
                        label: displayFormatter(item)
                    };
                })
            }, () => {
                    if (initialId && !this.state.init) {
                        this.handleChange(name ? name : this.defaultId(), this.state.data.filter(i => i.value === initialId)[0]);
                }
                this.setState({
                    init: true
                });
            });
        }
    }

    handleChange = (name, value) => {
        const { onChange } = this.props;

        this.setState({ inputValue: value });

        onChange(name, value);
    };

    render() {
        const {
            id,
            name,
            label,
            placeholder,
            value,
            isMulti,
            isDisabled,
            touched,
            error,
            onBlur,
            hideLabel,
        } = this.props;

        return (this.state.init && <SelectField
            id={id ? id : this.defaultId()}
            name={name ? name : this.defaultId()}
            label={label ? label : hideLabel ? null : "Invitation"}
            placeholder={placeholder ? placeholder : "Select invitation"}
            options={this.state.options}
            value={value}
            onChange={this.handleChange}
            onBlur={onBlur}
            touched={touched}
            isMulti={isMulti}
            error={error}
            isDisabled={isDisabled}
            isClearable={true}
            backspaceRemovesValue={true}
        />);
    }
}
