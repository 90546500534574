import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import "react-datepicker/dist/react-datepicker.css";
import ReactMarkdown from 'react-markdown'
import { WellStreamContentItemClient } from '../../infrastructure/WellStreamClient/ContentItems';
import { WellStreamFileClient } from '../../infrastructure/WellStreamClient/Files';
import { PublisherSelector } from '../../infrastructure/Selectors/PublisherSelector';
import { CategorySelector } from '../../infrastructure/Selectors/CategorySelector';

export class ContentItemsNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, authenticated: true, next: false
        };

    }

    componentDidMount() {
        this.populateData();
    }

    renderForm() {
        return (<Formik
            initialValues={{
                name: '',
                description: '',
                publisherId: '',
                categoryId: '',
                type: "markdown",
                documentText: '',
                website: '',
                visibility: "private"
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.name) {
                    setFieldError("name", "Please specify the publisher name");
                    return;
                }

                if (!fields.description) {
                    setFieldError("description", "Please give a description of the publisher");
                    return;
                }

                var imageId = null;

                if (fields.file) {
                    imageId = await WellStreamFileClient.UploadImage(fields.file);
                }

                var content = null;

                if (fields.type == "markdown")
                    content = fields.documentText;

                if (fields.type == "webArticle")
                    content = fields.website;

                var response = await WellStreamContentItemClient.Create(fields.name, fields.description, content, fields.type, fields.visibility, null, fields.publisherId ? fields.publisherId.value : null, imageId, fields.categoryId ? fields.categoryId.value : null);

                if (response.successful) {
                    if (fields.type == "document") {
                        await WellStreamFileClient.UploadContentFile(response.data.id, fields.document);
                    } else if (fields.type == "video") {
                        await WellStreamFileClient.UploadContentFile(response.data.id, fields.video);
                    } else if (fields.type == "audio") {
                        await WellStreamFileClient.UploadContentFile(response.data.id, fields.audio);
                    }
                }

                if (!response.successful) {

                    response.validationErrors.map(error => {
                        setFieldError(error.key, error.message);
                        return {
                            [error.key]: error.message
                        };
                    });

                } else {
                    this.setState({ next: true, id: response.data.id });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
            <Form>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="name">Title*</label>
                            <Field name="name" placeholder="Title of the content" type="text" className={'bgWhite form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                            <ErrorMessage name="name" component="div" className="invalid-feedback text-left" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="description">Description*</label>
                            <Field name="description" rows="5" placeholder="Description of what the content is" type="text" component="textarea" className={'bgWhite form-control' + (errors.description && touched.description ? ' is-invalid' : '')} />
                            <ErrorMessage name="description" component="div" className="invalid-feedback text-left" />
                        </div>
                        <Row>
                            <Col>
                                <div className="form-group">

                                    <label htmlFor="type">Type</label>
                                    <select type="select" name="type"
                                        value={values.type}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={'form-control' + (errors.type && touched.type ? ' is-invalid' : '')}>
                                        <option value="audio">Audio/ podcast</option>
                                        <option value="webArticle">Web article</option>
                                        <option value="video">Video</option>
                                        <option value="document">Document</option>
                                        <option value="markdown">Text</option>
                                    </select>

                                    <ErrorMessage name="type" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">

                                    <label htmlFor="visibility">Visibility</label>
                                    <select type="select" name="visibility"
                                        value={values.visibility}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={'form-control' + (errors.visibility && touched.visibility ? ' is-invalid' : '')}>
                                        <option value="private">Draft</option>
                                        <option value="public">Published</option>
                                    </select>

                                    <ErrorMessage name="visibility" component="div" className="invalid-feedback" />
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <PublisherSelector
                                        placeholder="Select publisher"
                                        value={values.publisherId}
                                        onChange={setFieldValue} />
                                </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <CategorySelector
                                            globalOnly={true}
                                            placeholder="Select category"
                                            value={values.categoryId}
                                            onChange={setFieldValue} />
                                    </div>
                                </Col>
                        </Row>

                        {values.type == "webArticle" &&
                            <div className="form-group">
                                <label htmlFor="website">Website</label>
                                <Field name="website" placeholder="E.g. https://google.com" type="text" className={'bgWhite form-control' + (errors.website && touched.website ? ' is-invalid' : '')} />
                                <ErrorMessage name="website" component="div" className="invalid-feedback text-left" />
                            </div>}

                        {values.type == "video" &&
                            <div className="form-group">
                                <h6 className="text-left">Video</h6>

                                {this.state.video && <div>
                                    <video width="320" height="240" controls >
                                        <source src={this.state.video} type="video/mp4" />
                                    </video>
                                </div>}

                                <input style={{ "overflow": "hidden", marginTop: "25px" }} className="form-control-file" id="video" name="video" type="file" onChange={(event) => {

                                    this.setState({
                                        video: URL.createObjectURL(event.target.files[0])
                                    })
                                    setFieldValue("video", event.currentTarget.files[0]);
                                }} />

                            </div>}

                        {values.type == "audio" &&
                            <div className="form-group">
                                <h6 className="text-left">Audio/ podcast</h6>

                                {this.state.audio && <div>
                                    <audio width="320" height="240" controls >
                                        <source src={this.state.audio} type="video/mpeg" />
                                    </audio>
                                </div>}

                                <input style={{ "overflow": "hidden", marginTop: "25px" }} className="form-control-file" id="audio" name="audio" type="file" onChange={(event) => {

                                    this.setState({
                                        video: URL.createObjectURL(event.target.files[0])
                                    })
                                    setFieldValue("audio", event.currentTarget.files[0]);
                                }} />

                            </div>}

                        {values.type == "document" &&
                            <div className="form-group">
                                <h6 className="text-left">Document</h6>

                                <input style={{ "overflow": "hidden", marginTop: "25px" }} className="form-control-file" id="document" name="document" type="file" onChange={(event) => {

                                    this.setState({
                                        document: URL.createObjectURL(event.target.files[0])
                                    })
                                    setFieldValue("document", event.currentTarget.files[0]);
                                }} />

                            </div>}
                    </Col>
                    <Col md="3" className="text-center">
                        <div className="form-group">
                            <h6 className="text-left">Thumbnail</h6>

                            {this.state.file && <div>
                                <img alt="Uploaded supporting file" src={this.state.file} style={{ width: "250px", marginTop: "20px", objectFit: "cover" }} />
                            </div>}

                            <input style={{ "overflow": "hidden", marginTop: "25px" }} className="form-control-file" id="file" name="file" type="file" onChange={(event) => {

                                this.setState({
                                    file: URL.createObjectURL(event.target.files[0])
                                })
                                setFieldValue("file", event.currentTarget.files[0]);
                            }} />

                        </div>
                    </Col>
                </Row>


                {values.type == "markdown" && <Row>
                    <Col><div className="form-group">
                        <label htmlFor="documentText">Content*</label>
                        <hr />
                        <Field name="documentText" rows="20" placeholder="Markdown is supported" type="text" component="textarea" className={'bgWhite form-control' + (errors.documentText && touched.documentText ? ' is-invalid' : '')} />
                        <ErrorMessage name="documentText" component="div" className="invalid-feedback text-left" />
                    </div></Col>
                    <Col md="3">
                        <label htmlFor="documentText">Preview</label>
                        <hr />
                        <p>
                            <ReactMarkdown>{values.documentText}</ReactMarkdown>
                        </p>
                    </Col>
                </Row>}

                <hr />
                <div className="form-group">
                    <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                        {!isSubmitting && "Create"}
                        {isSubmitting && <Spinner animation="border" />}
                    </button>
                </div>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/content/details/" + this.state.id} />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Upload a new piece of content
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Upload new content that can be used by all organisations</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/content" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.data && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }


    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            this.setState({
                init: true,
                loading: false
            });
        }
    }
}
