﻿import { ListQueryParams, ApiResponse } from "../WellStreamAPI";
import { GetAPIHeadersPatch, GetAPIDomain, GetAPIHeaders } from "../Helper";

export class OrganisationQueryParams extends ListQueryParams {

    WithContentItem(value: any) {
        return this.WithParam("contentItemId", value);
    }

}


export class WellStreamOrganisationClient {

    static async List(params: OrganisationQueryParams) {

        var query = (await GetAPIDomain()) + "/organisations" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders("application/json", true)
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/organisations/" + id, {
            headers: await GetAPIHeaders("application/json", true)
        }));

    }

    static async Create(name: string, description: string, imageId?: string, autoApproveContent?: Boolean) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/organisations/", {
            method: 'post',
            headers: await GetAPIHeaders("application/json", true),
            body: JSON.stringify({
                "name": name,
                "description": description,
                "imageId": imageId,
                "autoApproveContent": autoApproveContent ? true : false
            })
        }));
    }

    static async Update(id: string, name: string, description: string, imageId?: string, autoApproveContent?: Boolean) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/organisations/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "name",
                "value": name
            },
            {
                "op": "replace",
                "path": "description",
                "value": description
                },
                {
                    "op": "replace",
                    "path": "imageId",
                    "value": imageId
                },
                {
                    "op": "replace",
                    "path": "autoApproveContent",
                    "value": autoApproveContent ?? false
                }])
        }));
    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/organisations/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }
}