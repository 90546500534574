import React, { Component } from 'react';
import { UsersTable } from '../users/UsersTable';

export class Customers extends Component {

    render() {
        return (
            <UsersTable allUsers={true} />
        );
    }

}
