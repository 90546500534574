import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Spinner, Card, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { WellStreamAPI } from '../infrastructure/WellStreamAPI';

export class Register extends Component {

    constructor(props) {
        super(props);
        this.state = { codeStatus: "", loading: true };
    }

    renderContent() {

        if (this.state.next === "next") {
            return (<Redirect to="/onboarding/step-01" />);
        } else if (this.state.next === "sign-in") {
            return (<Redirect to="/" />);
        } else {
            return (<Formik
                initialValues={{
                    name: '',
                    surname: '',
                    mobile: '',
                    email: '',
                    organisation: '',
                    password: ''
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {

                    if (!fields.organisation) {
                        setFieldError("organisation", "Please specify the business");
                        return;
                    }

                    if (!fields.name) {
                        setFieldError("name", "Please specify your name");
                        return;
                    }


                    var response = await WellStreamAPI.SignUp(fields.organisation, fields.name, fields.surname, fields.mobile, fields.email, fields.password);

                    if (!response.successful) {

                        response.validationErrors.map(error => {
                            setFieldError(error.key, error.message);
                            return {
                                [error.key]: error.message
                            };
                        });

                    } else {
                        if (await WellStreamAPI.SignIn(fields.email, fields.password)) {
                            this.setState({ next: "next" });
                        } else {
                            this.setState({ next: "sign-in" });
                        }
                    }
                }}
                render={({ errors, status, touched, isSubmitting }) => (
                    <Form>
                        <h5>Your organisation</h5>
                        <hr />
                        <div className="form-group">
                            <Field name="organisation" placeholder="Organisation name" type="text" className={'bgWhite form-control' + (errors.organisation && touched.organisation ? ' is-invalid' : '')} />
                            <ErrorMessage name="organisation" component="div" className="invalid-feedback text-left" />
                        </div>
                        <h5>Your details</h5>
                        <hr />
                        <div className="form-group">
                            <Field name="name" placeholder="Your name" type="text" className={'bgWhite form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                            <ErrorMessage name="name" component="div" className="invalid-feedback text-left" />
                        </div>
                        <div className="form-group">
                            <Field name="surname" placeholder="Your surname" type="text" className={'bgWhite form-control' + (errors.surname && touched.surname ? ' is-invalid' : '')} />
                            <ErrorMessage name="surname" component="div" className="invalid-feedback text-left" />
                        </div>
                        <div className="form-group">
                            <Field name="mobile" placeholder="Mobile" type="text" className={'bgWhite form-control' + (errors.mobile && touched.mobile ? ' is-invalid' : '')} />
                            <ErrorMessage name="mobile" component="div" className="invalid-feedback text-left" />
                        </div>
                        <hr />
                        <h5>Your account</h5>
                        <hr />
                        <div className="form-group">
                            <Field name="email" placeholder="Email" type="text" className={'bgWhite form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                            <ErrorMessage name="email" component="div" className="invalid-feedback text-left" />
                        </div>
                        <div className="form-group">
                            <Field name="password" placeholder="Password" type="password" className={'bgWhite form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                            <ErrorMessage name="password" component="div" className="invalid-feedback text-left" />
                        </div>
                        <hr />
                        <div className="form-group">
                            <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting} style={{ width: "100%" }}>
                                {!isSubmitting && "REGISTER BUSINESS"}
                                {isSubmitting && <Spinner animation="border" />}
                            </button>
                        </div>
                        <div className="form-group text-right">
                            <Link className="text-right" to="/sign-in">I already have an account</Link>
                        </div>
                    </Form>
                )}
            />);
        }
    }

    render() {

        return (
            <Card style={{ maxWidth: "600px", width: "100%" }}>
                <CardBody>
                    <div>
                        <div>
                            <div className="card-body text-left">

                                <h3 className="mb-4">Register your organisation</h3>
                                <hr />
                                {this.renderContent()}

                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        );

    }
}