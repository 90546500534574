import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Spinner, Card, CardHeader, Row, Col, CardBody, Button } from 'reactstrap';
import classnames from 'classnames';
import { permissionContext } from '../../infrastructure/Context/permissionContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { WellStreamRoleClient } from '../../infrastructure/WellStreamClient/Roles';
import { UsersTable } from '../users/UsersTable';

export class RolesDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null, loading: false, authenticated: true, next: false,
            activeTab: '1'
        };

        this.toggle = this.toggle.bind(this);
        this.delete = this.delete.bind(this);
    }

    async componentDidMount() {
        this.populateData(this.props.match.params.id);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/roles" />);
        } else {
            return (

                <div>
                    {this.state.data && <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/roles" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>Role</span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                </Col>
                                <Col>
                                    <permissionContext.Consumer>
                                        {({ permissions }) => {

                                            return (permissions.includes("role.modify") && <div style={{ paddingRight: "10px" }}>
                                                {this.state.data && <Link style={{ float: "right" }} to={"/roles/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                            </div>);
                                        }}
                                    </permissionContext.Consumer>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>}


                    <Card>
                        <CardHeader>
                            <Nav pills style={{ boxShadow: "none" }}>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggle('1'); }}>
                                        Details</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.toggle('2'); }}>
                                        Users</NavLink>
                                </NavItem>
                            </Nav>
                        </CardHeader>
                    </Card>


                    <TabContent activeTab={this.state.activeTab} style={{ padding: "0", backgroundColor: "transparent" }}>
                        <TabPane tabId="1">
                            {this.state.activeTab === '1' && this.state.data && <><Card>
                                <CardBody>
                                    <h6>Details</h6>
                                    <hr />

                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="pr-2">Name:</td>
                                                <td>
                                                    {this.state.data.name && this.state.data.name}
                                                    {!this.state.data.name && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Description:</td>
                                                <td>
                                                    {this.state.data.description && this.state.data.description}
                                                    {!this.state.data.description && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </CardBody>
                            </Card>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            {this.state.data && this.state.data.permissionGroups.map(group => {
                                                return (
                                                    <Col md="4" key={group.name}>
                                                        <h6>{group.name}</h6>
                                                        <hr />
                                                        <ul className="list-unstyled">
                                                            {group.permissions.map(permission => {
                                                                return (<li key={permission.id}>
                                                                    {permission.selected && <i className="feather icon-check-circle" style={{ color: "#1de9b6" }}></i>}
                                                                    {!permission.selected && <i className="feather icon-circle"></i>} {permission.name}
                                                                    <p className="text-muted">{permission.description}</p>
                                                                </li>);
                                                            })}
                                                        </ul>
                                                    </Col>
                                                );
                                            })}

                                        </Row>
                                    </CardBody>
                                </Card>

                                <permissionContext.Consumer>
                                    {({ permissions }) => {

                                        return (permissions.includes("role.modify") && this.state.data && <div className="alert alert-danger">
                                            {!this.state.deleteFail &&
                                                <Row>
                                                    <Col>
                                                        <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Danger: </strong> You can delete this role, doing so will permanently remove it, please proceed with caution</p></Col>
                                                    <Col>
                                                        <Button style={{ float: "right" }} to="/roles" onClick={this.delete} className="btn mr-2 mt-2 mb-2 btn-danger">Delete role</Button>
                                                    </Col>
                                                </Row>}

                                            {this.state.deleteFail &&
                                                <div>
                                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Failed: </strong> This role is currently in use, please make sure no one is using this role</p>
                                                </div>}
                                        </div>);

                                    }}
                                </permissionContext.Consumer>
                            </>}

                        </TabPane>
                        <TabPane tabId="2">
                            {this.state.activeTab === '2' && <UsersTable roleId={this.props.match.params.id} hideFilter={true} description="List of users in this role" />}
                        </TabPane>

                    </TabContent>

                </div>
            );
        }
    }


    async delete() {
        var response = await WellStreamRoleClient.Delete(this.props.match.params.id);
        if (response.successful) {
            this.setState({ next: true });
        } else {
            this.setState({ deleteFail: true });
        }
    }

    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await WellStreamRoleClient.Get(id);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false,
                    loading: false
                });
            } else {
                this.setState({ data: response.data, loading: false });
            }
        }
    }

}
