import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody, Input, InputGroup, ButtonGroup, Badge, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { WellStreamContentApprovalClient, ContentApprovalQueryParams } from '../../infrastructure/WellStreamClient/ContentApprovals';
import { TableHeader } from '../../infrastructure/TableHeader';
import { OrganisationSelector } from '../../infrastructure/OrganisationSelector';
import Moment from 'react-moment';
import { InitiativeSelector } from '../../infrastructure/Selectors/InitiativeSelector';
import { Formik, Field, Form, ErrorMessage } from 'formik';

export class ContentApprovalsTable extends Component {

    constructor(props) {
        super(props);
        this.state = { data: [], loading: false, total: 0, skip: 0, take: 50, userStatus: "", showAddForm: false, userType: "", authenticated: true, search: "", sort: "created", direction: "desc", initiativeId:"" };
        this.handleInitiativeIdChange = this.handleInitiativeIdChange.bind(this);
    }

    handleInitiativeIdChange(name, value) {

        this.setState({
            initiativeId: value
        }, () => this.update());
    }

    async clear() {
        this.setState({
            initiativeId: "",
            skip: 0
        }, () => this.update());
    }

    componentDidMount() {
        OrganisationSelector.Init();
        this.populateData(this.state.skip, this.state.take);
    }

    async update() {
        await this.populateData(this.state.skip, this.state.take);
    }

    renderTable(data) {

        const {
            showOrg,
            showContentItem
        } = this.props;

        var columns = [
            { name: "All Initiatives", sort: "allInitiatives" },
            { name: "Initiative", sort: "initiative.name" },
            { name: "Approved By", sort: "approvedBy.email" },
            { name: "Created", sort: "created" },
            { name: "", sort: "", static: true }];

        //if (showOrg)
        //    columns.splice(2, 0, { name: "Organisation", sort: "organisation.name" });

        //if (showContentItem)
        //    columns.splice(2, 0, { name: "Content", sort: "contentItem.name" });

        return (<div className="table-responsive">
            <table className='table' aria-labelledby="tabelLabel">

                <TableHeader
                    sort={this.state.sort}
                    direction={this.state.direction}
                    columns={columns}
                    onUpdate={(sort, direction) => this.setState({
                        sort: sort,
                        direction: direction
                    }, () => this.update())}
                />
                <tbody>
                    {data.map(item =>
                        <tr key={item.id}>
                            <td>
                                {item.allInitiatives && <Badge color="success">Yes</Badge>}
                                {!item.allInitiatives && <Badge color="secondary">No</Badge>}
                            </td>
                            <td>
                                {item.initiative && <Link key={item.initiative.id + "-view"} to={"/initiatives/details/" + item.initiative.id}>{item.initiative.name}</Link>}
                                {!item.initiative && <i>N/A</i>}
                            </td>
                            <td>
                                {item.approvedBy && <Link key={item.approvedBy.id + "-view"} to={"/users/details/" + item.approvedBy.id}>{item.approvedBy.name}</Link>}
                                {!item.approvedBy && <i>System</i>}
                            </td>
                            <td>
                                {item.created && <Moment utc local format="ddd DD MMM, h:mm a">{item.created}</Moment>}
                            </td>
                            <td>

                                <Formik
                                    initialValues={{
                                        allInitiatives: true,
                                        initiativeId: ''
                                    }}
                                    onSubmit={async (fields, { setErrors, setFieldError }) => {
                                        await WellStreamContentApprovalClient.Delete(item.id);
                                        await this.update();
                                    }}
                                >{({ isSubmitting }) => (
                                        <Form style={{ float: "right" }}>
                                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                            {!isSubmitting && "Revoke"}
                                            {isSubmitting && <Spinner animation="border" />}
                                        </button>
                                    </Form>
                                )}</Formik>
                            </td>
                        </tr>
                    )}
                    {this.state.data.length === 0 && <tr><td colSpan="8"><h4 className="text-muted text-center mt-3"><i>Not apprroved</i></h4></td></tr>}
                </tbody>
            </table>
        </div>
        );
    }

    render() {


        const {
            hideFilter,
            contentItemId,
            description
        } = this.props;


        if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Approvals ({this.state.total})
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">{description} {!description && "What this content has been approved for within your organisation"}</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <button style={{ float: "right" }} onClick={() => { this.next(); }} disabled={this.state.loading || (this.state.skip + this.state.take >= this.state.total)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Next</button>
                                        <button style={{ float: "right" }} onClick={() => { this.previous(); }} disabled={this.state.loading || (this.state.skip <= 0)} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Prev</button>
                                        <button style={{ float: "right" }} onClick={() => { this.setState({ showAddForm: true }); }} className="btn mr-2 btn-outline-dark mt-2 mb-2" >Approve</button>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                    </Card>
                    <Card>
                        {!hideFilter && <CardHeader>
                            <Row>
                                <Col>
                                    <h5>Filter</h5>
                                </Col>
                                
                                <Col md={4}>
                                    <div style={{ paddingRight: "10px" }}>
                                            <InitiativeSelector
                                                placeholder="Initiative"
                                                value={this.state.initiativeId}
                                                onChange={this.handleInitiativeIdChange}
                                                hideLabel={true} />
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>}

                        <CardBody className="p-0">
                            {this.state.data && this.renderTable(this.state.data, this.state.nextPage, this.state.previousPage)}
                        </CardBody>
                    </Card>

                    <Modal isOpen={this.state.showAddForm}>
                        <ModalHeader>Approve content</ModalHeader>
                        <ModalBody>

                            <p>Approve this content for</p>

                            <Formik
                                initialValues={{
                                    allInitiatives: true,
                                    initiativeId: ''
                                }}
                                onSubmit={async (fields, { setErrors, setFieldError }) => {

                                    if (!fields.allInitiatives && !fields.initiativeId) {
                                        setFieldError("initiativeId", "Please specify the initiative");
                                        return;
                                    }

                                    var response = await WellStreamContentApprovalClient.Create(contentItemId, fields.allInitiatives, !fields.allInitiatives && fields.initiativeId ? fields.initiativeId.value : null)

                                    if (!response.successful) {

                                        response.validationErrors.map(error => {
                                            setFieldError(error.key, error.message);
                                            return {
                                                [error.key]: error.message
                                            };
                                        });

                                    } else {
                                        await this.update();
                                        this.setState({ showAddForm: false });
                                    }

                                }}
                            >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                                <Form>
                                        <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <label>
                                                        <input
                                                            name="allInitiatives"
                                                            type="checkbox"
                                                            value="True"
                                                            checked={values.allInitiatives}
                                                            onChange={e => {
                                                                setFieldValue("allInitiatives", e.target.checked)
                                                            }}
                                                        /> All initiatives
                                                        </label>
                                                </div>
                                            </Col>
                                        </Row>

                                        {!values.allInitiatives && <Row>
                                            <Col>
                                                <div className="form-group">

                                                    <InitiativeSelector
                                                        placeholder="Initiative"
                                                        onChange={(name, value) => {
                                                            setFieldValue("initiativeId", value)
                                                        }} />

                                                </div>
                                            </Col>
                                        </Row>}
                                    <hr />
                                    <div className="form-group">
                                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                            {!isSubmitting && "Approve"}
                                            {isSubmitting && <Spinner animation="border" />}
                                            </button>
                                            <button onClick={() => { this.setState({ showAddForm: false }); }}  className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>Cancel</button>
                                    </div>
                                </Form>
                                )}</Formik>



                        </ModalBody>
                    </Modal>
                </div>
            );
        }
    }

    async previous() {
        await this.populateData(this.state.skip - this.state.take, this.state.take);
    }

    async next() {
        await this.populateData(this.state.skip + this.state.take, this.state.take);
    }

    async populateData(skip, take) {

        const {
            contentItemId,
            initiativeId,
        } = this.props;

        if (!this.state.loading) {
            this.setState({ loading: true, skip: skip, take: take });

            var query = new ContentApprovalQueryParams();

            var response = await WellStreamContentApprovalClient.List(query
                .Paginate(skip, take)
                .Search(this.state.search)
                .WithContentItem(contentItemId)
                .WithInitiative(initiativeId ?? (this.state.initiativeId ? this.state.initiativeId.value : null))
                .Sort(this.state.sort, this.state.direction));

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data.data, loading: false, total: response.data.total });
            }
        }
    }
}
