import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Spinner, Card, CardHeader, Row, Col, CardBody, Button, Badge } from 'reactstrap';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GetAssetDomain } from '../../../infrastructure/Helper';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { WellStreamCategoryClient } from '../../../infrastructure/WellStreamClient/Categories';

export class CategoryDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null, loading: false, authenticated: true, next: false,
            activeTab: '1'
        };

        this.toggle = this.toggle.bind(this);
        this.delete = this.delete.bind(this);
    }

    async componentDidMount() {
        this.setState({
            assetUrl: await GetAssetDomain()
        });
        this.populateData(this.props.match.params.id);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    async delete() {
        var response = await WellStreamCategoryClient.Delete(this.props.match.params.id);
        if (response.successful) {
            this.setState({ next: true });
        } else {
            this.setState({ deleteFail: true });
        }
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/categories" />);
        } else {
            return (

                <div>
                    {this.state.data && <><Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/categories" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>Category details</span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                </Col>
                                <Col>
                                    {this.state.data && this.state.data.organisation && <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/categories/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>
                                    </div>}
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <h6>Details</h6>
                                        <hr />

                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="pr-2">Name:</td>
                                                    <td>
                                                        {this.state.data.name && this.state.data.name}
                                                        {!this.state.data.name && <i>Not specified</i>}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="pr-2">Type:</td>
                                                    <td>
                                                        {this.state.data.organisation && <Badge color="success">Custom</Badge>}
                                                        {!this.state.data.organisation && <Badge color="secondary">System</Badge>}
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                        {this.state.data && this.state.data.organisation && <div className="alert alert-danger">
                            {!this.state.deleteFail &&
                                <div>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Danger: </strong> You can delete this catgegory, doing so will permanently remove it. Any linked content will no longer have a category assigned. Please proceed with caution.</p>

                                    <Button to="/roles" onClick={this.delete} className="btn mr-2 mt-2 mb-2 btn-danger">Delete category</Button>
                                </div>}

                            {this.state.deleteFail &&
                                <div>
                                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><strong>Failed: </strong> This category is currently in use, please make no content items are assigned to this category</p>
                                </div>}
                        </div>}
                    </>}
                </div>
            );
        }
    }


    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await WellStreamCategoryClient.Get(id);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false,
                    loading: false
                });
            } else {
                this.setState({ data: response.data, loading: false });
            }
        }
    }

}
