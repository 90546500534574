import { ListQueryParams, ApiResponse } from "../WellStreamAPI";
import { GetAPIHeadersPatch, GetAPIDomain, GetAPIHeaders } from "../Helper";

export class UserQueryParams extends ListQueryParams {

    WithOrganisationId(value: any) {
        this.WithParam("organisationId", value);
        return this;
    }

    WithShowSuspended(value: any) {
        console.log(value);
        if(!value)
            this.WithParam("status", "active");
        return this;
    }

    WithRoleId(value: any) {
        this.WithParam("roleId", value);
        return this;
    }

    WithInitiativeId(value: any) {
        this.WithParam("initiativeId", value);
        return this;
    }

    WithStatus(value: any) {
        this.WithParam("status", value);
        return this;
    }

    WithType(value: any) {
        this.WithParam("type", value);
        return this;
    }

}


export class WellStreamUserClient {

    static async List(params: UserQueryParams) {

        var query = (await GetAPIDomain()) + "/users" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/users/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async GetActions(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/users/" + id + "/actions", {
            headers: await GetAPIHeaders("application/json", true)
        }));

    }


    static async GetSubscription(id: string) {
        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/users/" + id + "/subscription", {
            headers: await GetAPIHeaders("application/json", true)
        }));
    }

    static async SetSubscription(id: string, plan: string, type: string) {
        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/users/" + id + "/set-subscription-type", {
            headers: await GetAPIHeaders("application/json", true),
            method: 'post',
            body: JSON.stringify({
                "plan": plan,
                "type": type
            })
        }));
    }

    static async SetInitiative(id: string, initiativeId: string) {

        var query = (await GetAPIDomain()) + "/users/" + id + "/set-initiative";

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'post',
            body: JSON.stringify({
                "initiativeId": initiativeId
            })
        }));
    }

    static async SetRole(id: string, roleId: any) {

        console.log(roleId);

        var query = (await GetAPIDomain()) + "/users/" + id + "/set-roles";

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'post',
            body: JSON.stringify({
                "roleIds": (roleId && roleId.value) ? [roleId.value] : []
            })
        }));
    }

    static async Suspend(id: string) {

        var query = (await GetAPIDomain()) + "/users/" + id + "/suspend";

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'post'
        }));
    }
    static async Restore(id: string) {

        var query = (await GetAPIDomain()) + "/users/" + id + "/restore";

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'post'
        }));
    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/users/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }


    static async Update(id: string, name: string, surname: string, email: string, mobile?: string, profileImageId?: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/users/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "name",
                "value": name
            },
                {
                    "op": "replace",
                    "path": "surname",
                    "value": surname
                },
                {
                    "op": "replace",
                    "path": "email",
                    "value": email
                },
                {
                    "op": "replace",
                    "path": "mobile",
                    "value": mobile
                },
            {
                "op": "replace",
                "path": "profileImageId",
                "value": profileImageId
            }])
        }));
    }
}