import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { InputGroup, ButtonGroup, Input, Spinner, Card, CardHeader, Button, Row, Col, CardBody, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { WellStreamContactClient } from '../../infrastructure/WellStreamClient/Contacts';
import { GetAssetDomain } from '../../infrastructure/Helper';

export class ContactsDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null, loading: false, authenticated: true, next: false,
            activeTab: '1'
        };

        this.delete = this.delete.bind(this);
    }

    async componentDidMount() {
        this.setState({
            assetUrl: await GetAssetDomain()
        });
        this.populateData(this.props.match.params.id);
    }

    render() {
        return (
            <div>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col>
                                <h5>
                                    <Link to="/contacts" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                    {this.state.data && <span>Invitation</span>}
                                    {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    <span className="d-block text-muted" style={{ marginLeft: "45px" }}>{this.state.data && <span>{this.state.data.name} {this.state.data.surname}</span>}</span>
                                </h5>
                            </Col>
                            <Col>
                                <div style={{ paddingRight: "10px" }}>
                                    {this.state.data && this.state.data.status === "pending" && <Link style={{ float: "right" }} to={"/contacts/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                </div>
                            </Col>
                        </Row>

                    </CardHeader>
                </Card>
                {this.state.data &&
                    <Card>
                        <CardBody>
                    <Row>
                        <Col>
                            <table style={{ marginBottom: "25px" }}>
                                <tbody>
                                    <tr>
                                        <td className="pr-2">Name:</td>
                                        <td>
                                            {this.state.data.name && this.state.data.name}
                                            {!this.state.data.name && <i>Not specified</i>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="pr-2">Link:</td>
                                        <td>
                                            {this.state.data.link && <a href="{this.state.data.link}">{this.state.data.link}</a>}
                                            {!this.state.data.link && <i>None</i>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="pr-2">Initiative:</td>
                                        <td>
                                            {this.state.data.initiative && <a href="/initiatives/{this.state.data.initiative.id}">{this.state.data.initiative.name}</a>}
                                                    {!this.state.data.initiative && <i>None</i>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="pr-2">Global:</td>
                                        <td>
                                            {this.state.data.organisation && <span>No</span>}
                                            {!this.state.data.organisation && <i>Yes</i>}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                        <Col md="3" className="text-center">
                            <h6 className="text-left">Thumbnail</h6>
                            <hr />
                            {!this.state.data.image && <h4 className="text-muted mt-3"><i>No image</i></h4>}
                            {this.state.data.image && <img alt="Uploaded by submitted" src={this.state.assetUrl + "/image/" + this.state.data.image.id + ".jpg"} style={{ width: "250px", marginTop: "20px", objectFit: "cover" }} />}
                        </Col>
                            </Row>
                        </CardBody>
                        </Card>
                }
            </div>
        );
    }

    async delete() {
        await WellStreamContactClient.Delete(this.props.match.params.id);
        await this.populateData(this.props.match.params.id);
    }

    async populateData(id) {
        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await await WellStreamContactClient.Get(id);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false
                });
            } else {
                this.setState({ data: response.data, loading: false });
            }
        }
    }

}
