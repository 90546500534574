import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { GetAssetDomain, GetAPIDomain } from '../../infrastructure/Helper';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { WellStreamPublisherClient } from '../../infrastructure/WellStreamClient/Publishers';

export class PublishersEdit extends Component {

    constructor(props) {
        super(props);
        this.state = { init: false, make: "", model: "", loading: true, authenticated: true, next: false, name: "", description: "" };

    }

    componentDidMount() {
        this.populateData();
    }

    renderForm() {
        if (this.state.loading) {
            return (
                <h4><i>Loading...</i></h4>);
        } else {

            return (<Formik
                initialValues={{
                    name: this.state.name,
                    description: this.state.description,
                    website: this.state.website
                }}
                onSubmit={async (fields, { setErrors, setFieldError }) => {


                    if (!fields.name) {
                        setFieldError("name", "Please specify the publisher name");
                        return;
                    }

                    if (!fields.description) {
                        setFieldError("description", "Please give a description of the publisher");
                        return;
                    }

                    var imageId = this.state.imageId;

                    if (fields.file) {

                        let formData = new FormData();
                        formData.append("file", fields.file);

                        var imageResult = await fetch((await GetAPIDomain()) + "/uploads/images", {
                            method: 'post',
                            headers: {
                                'Authorization': 'Bearer ' + localStorage.getItem("acess-token")
                            },
                            body: formData
                        });

                        if (imageResult.ok) {
                            imageId = (await imageResult.json()).id;
                        }
                    }

                    var response = await WellStreamPublisherClient.Update(this.props.match.params.id, fields.name, fields.description, fields.website, imageId);


                    if (!response.successful) {

                        if (response.status === 404) {
                            this.setState({ next: true });
                        } else {

                            response.validationErrors.map(error => {
                                setFieldError(error.key, error.message);
                                return {
                                    [error.key]: error.message
                                };
                            });
                        }

                    } else {
                        this.setState({ next: true });
                    }

                }}
            >{({ errors, handleChange, handleBlur, status, touched, isSubmitting, values, setFieldValue, setFieldTouched }) => (
                <Form>

                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="name">Name*</label>
                                    <Field name="name" placeholder="E.g. John Doe" type="text" className={'bgWhite form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                                    <ErrorMessage name="name" component="div" className="invalid-feedback text-left" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="website">Website</label>
                                    <Field name="website" placeholder="E.g. https://google.com" type="text" className={'bgWhite form-control' + (errors.website && touched.website ? ' is-invalid' : '')} />
                                    <ErrorMessage name="website" component="div" className="invalid-feedback text-left" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="description">Description*</label>
                                    <Field name="description" rows="10" placeholder="E.g. John is an expert in..." type="text" component="textarea" className={'bgWhite form-control' + (errors.description && touched.description ? ' is-invalid' : '')} />
                                    <ErrorMessage name="description" component="div" className="invalid-feedback text-left" />
                                </div>
                            </Col>
                            <Col md="3" className="text-center">
                                <div className="form-group">
                                    <h6 className="text-left">Publisher logo/ image</h6>

                                    {this.state.file && <div>
                                        <img alt="Uploaded supporting file" src={this.state.file} style={{ width: "250px", marginTop: "20px", objectFit: "cover" }} />
                                    </div>}

                                    <input style={{ "overflow": "hidden", marginTop: "25px" }} className="form-control-file" id="file" name="file" type="file" onChange={(event) => {

                                        this.setState({
                                            file: URL.createObjectURL(event.target.files[0])
                                        })
                                        setFieldValue("file", event.currentTarget.files[0]);
                                    }} />

                                </div>
                            </Col>
                        </Row>

                    <hr />

                    <div className="form-group mt-3">
                        <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                            {!isSubmitting && "SAVE"}
                            {isSubmitting && <Spinner animation="border" />}
                        </button>
                    </div>
                </Form>
            )}</Formik>);
        }
    }

    render() {
        if (this.state.next === true) {
            return (<Redirect to={"/publishers/details/" + this.props.match.params.id} />);
        } else if (!this.state.authenticated) {
            return (<Redirect to="/sign-in" />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> Update publisher
                                     {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Update publisher information</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to={"/publishers/details/" + this.props.match.params.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    async populateData() {
        if (!this.state.init) {
            this.setState({ init: true });

            var response = await WellStreamPublisherClient.Get(this.props.match.params.id);

            if (!response.authenticated) {
                this.setState({
                    loading: false,
                    authenticated: false
                });
            } else {

                if (response.data.logo) {
                    this.setState({
                        file: (await GetAssetDomain()) + "/image/" + response.data.logo.id + ".jpg"
                    });
                }

                this.setState({
                    loading: false,
                    name: response.data.name,
                    description: response.data.description,
                    website: response.data.website,
                    imageId: response.data.logo ? response.data.logo.id : null
                });
            }
        }
    }
}
