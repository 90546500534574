import { ListQueryParams, ApiResponse } from "../WellStreamAPI";
import { GetAPIHeadersPatch, GetAPIDomain, GetAPIHeaders } from "../Helper";

export class InviteQueryParams extends ListQueryParams {

    WithOrganisationId(value: any) {
        this.WithParam("organisationId", value);
        return this;
    }

    WithType(value: any) {
        this.WithParam("type", value);
        return this;
    }

    WithStatus(value: any) {
        this.WithParam("status", value);
        return this;
    }

}


export class WellStreamInviteClient {

    static async List(params: InviteQueryParams) {

        var query = (await GetAPIDomain()) + "/invites" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/invites/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async InviteUser(initiativeId: string, name: string, surname: string, email: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/invites/user", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "name": name,
                "surname": surname,
                "email": email,
                "initiativeId": initiativeId
            })
        }));
    }

    static async AcceptForCurrentUser(code: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/invites/confirm/"+code, {
            method: 'post',
            headers: await GetAPIHeaders()
        }));
    }

    static async ValidateCode(code: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/invites/validate", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "code": code
            })
        }));
    }

    static async InviteOrganisation(organisationId: string, name: string, surname: string, email: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/invites/organisation", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "name": name,
                "surname": surname,
                "email": email,
                "organisationId": organisationId
            })
        }));
    }

    static async Cancel(id: string) {

        var query = (await GetAPIDomain()) + "/invites/cancel/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'post'
        }));
    }

    static async Update(id: string, name: string, surname: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/invites/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "name",
                "value": name
            },
            {
                "op": "replace",
                "path": "surname",
                "value": surname
            }])
        }));
    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/invites/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }
}