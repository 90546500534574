﻿import { WellStreamOrganisationClient, OrganisationQueryParams } from "./WellStreamClient/Organisations";
import { WellStreamUserClient } from "./WellStreamClient/Users";

export class OrganisationSelector {

    static async Init() {

        var initComplete = localStorage.getItem("org-list-init");

        if (!initComplete) {
            await OrganisationSelector.Refresh();
            localStorage.setItem("org-list-init", "true");
        }

        return true;

    }

    static async Refresh() {

        var response = await WellStreamOrganisationClient.List(new OrganisationQueryParams()
            .WithParam("isManager", "True")
            .Sort("name", "asc")
            .Paginate(0, 1000));

        localStorage.setItem("org-list-orgs", JSON.stringify(response.data.data));

        var modes = [];

        var actions = await WellStreamUserClient.GetActions("current");
        if (actions.successful) {
            if (actions.data.hasAdminAccess)
                modes.push({ name: "Admin Portal", id: "admin" });
            if (actions.data.hasOrgAccess)
                modes.push({ name: "Organisation Portal", id: "org" });
        }

        localStorage.setItem("org-list-modes", JSON.stringify(modes));
        localStorage.setItem("org-list-init", "true");

        return true;
    }

    static async GetSelectedOrg() {
        var selectedOrgId = localStorage.getItem("org-list-selected");
        var allOrgs = await OrganisationSelector.GetAllOrgs();
        var selectedOrg = allOrgs.find((org?:any) => org.id === selectedOrgId);

        if (selectedOrg)
            return selectedOrg;

        if (allOrgs.length === 0)
            return;

        localStorage.setItem("org-list-selected", allOrgs[0].id);

        return allOrgs[0];
    }

    static async GetAllOrgs() {
        var initComplete = localStorage.getItem("org-list-init");

        if (!initComplete) {
            await OrganisationSelector.Init();
        }

        var data = localStorage.getItem("org-list-orgs");
        if (data) {
            return JSON.parse(<string>localStorage.getItem("org-list-orgs"))
        }
    }

    static async SetSelectedOrg(id:string) {
        localStorage.setItem("org-list-selected", id);
        return true;
    }

    static async GetSelectedMode() {
        var selectedModeId = localStorage.getItem("org-list-selected-mode");
        var allModes = await OrganisationSelector.GetAllModes();
        var selectedMode = allModes.find((org?: any) => org.id === selectedModeId);

        if (selectedMode)
            return selectedMode;

        if (allModes.length === 0)
            return;

        localStorage.setItem("org-list-selected-mode", allModes[0].id);

        return allModes[0];
    }

    static async GetAllModes() {
        var initComplete = localStorage.getItem("org-list-init");

        if (!initComplete) {
            await OrganisationSelector.Init();
        }

        var data = localStorage.getItem("org-list-modes");
        if (data) {
            return JSON.parse(<string>localStorage.getItem("org-list-modes"))
        }
    }

    static async SetSelectedMode(id: string) {
        localStorage.setItem("org-list-selected-mode", id);
        return true;
    }
}