import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner, Card, CardHeader, Row, Col, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import "react-datepicker/dist/react-datepicker.css";
import { OrganisationSelector } from '../../infrastructure/OrganisationSelector';
import { WellStreamContactClient } from '../../infrastructure/WellStreamClient/Contacts';
import { GetAPIDomain } from '../../infrastructure/Helper';
import { SelectField } from '../../infrastructure/SelectField';

export class ContactsNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false, authenticated: true, next: false
        };

    }

    componentDidMount() {
        OrganisationSelector.Init();
        this.populateData();
    }

    renderForm() {
        return (<Formik
            initialValues={{
                name: '',
                link: '',
                initiativeId: ''
            }}
            onSubmit={async (fields, { setErrors, setFieldError }) => {

                if (!fields.name) {
                    setFieldError("name", "Please specify the contact name");
                    return;
                }
                //fields.initiativeId.value
                var selectedOrg = await OrganisationSelector.GetSelectedOrg();
                var imageId = this.state.logoId;
                if (fields.file) {

                    let formData = new FormData();
                    formData.append("file", fields.file);

                    var imageResult = await fetch((await GetAPIDomain()) + "/uploads/images", {
                        method: 'post',
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem("acess-token")
                        },
                        body: formData
                    });

                    if (imageResult.ok) {
                        imageId = (await imageResult.json()).id;
                    }
                }

                var response = await WellStreamContactClient.Create(fields.name, fields.link, imageId, selectedOrg.id, fields.initiativeId != null ? fields.initiativeId.value : null);

                if (!response.successful) {

                    response.validationErrors.map(error => {
                        setFieldError(error.key, error.message);
                        return {
                            [error.key]: error.message
                        };
                    });

                } else {
                    this.setState({ next: true, id: response.data.id });
                }

            }}
        >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                <Form>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="name">Contact name*</label>
                                <Field name="name" placeholder="E.g. EAP Services" type="text" className={'bgWhite form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                                <ErrorMessage name="name" component="div" className="invalid-feedback text-left" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="link">Link</label>
                                <Field name="link" placeholder="https://thewellstream.com" type="text" className={'bgWhite form-control' + (errors.link && touched.link ? ' is-invalid' : '')} />
                                <ErrorMessage name="link" component="div" className="invalid-feedback text-left" />
                            </div>

                            <div className="form-group">

                                <SelectField
                                    id="initiativeId"
                                    name="initiativeId"
                                    label="Initiatives"
                                    placeholder="Select initiative"
                                    options={this.state.initiativeOptions}
                                    value={values.initiativeId}
                                    isMulti={false}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    touched={touched.initiativeId}
                                    error={errors.initiativeId}
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                />
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                    {!isSubmitting && "Create"}
                                    {isSubmitting && <Spinner animation="border" />}
                                </button>
                            </div>
                        </Col>
                        <Col md="3" className="text-center">
                            <div className="form-group">
                                <h6 className="text-left">Thumbnail</h6>

                                {this.state.file && <div>
                                    <img alt="Uploaded supporting file" src={this.state.file} style={{ width: "250px", marginTop: "20px", objectFit: "cover" }} />
                                </div>}

                                <input style={{ "overflow": "hidden", marginTop: "25px" }} className="form-control-file" id="file" name="file" type="file" onChange={(event) => {

                                    this.setState({
                                        file: URL.createObjectURL(event.target.files[0])
                                    })
                                    setFieldValue("file", event.currentTarget.files[0]);
                                }} />

                            </div>
                        </Col>
                    </Row>
            </Form>
        )}</Formik>);
    }

    render() {

        if (this.state.next === true) {
            return (<Redirect to={"/contacts/details/" + this.state.id} />);
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5> New contact
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <span className="d-block m-t-5 text-muted">Create a new contact</span>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Link style={{ float: "right" }} to="/contacts" className="btn mr-2 btn-outline-dark mt-2 mb-2">Cancel</Link>
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            {this.state.data && this.renderForm(this.state.data)}
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }


    async populateData() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            this.setState({
                init: true,
                loading: false
            });
        }
    }
}
