import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Moment from 'react-moment';
import { permissionContext } from '../../infrastructure/Context/permissionContext';
import { Badge, Spinner, Card, CardHeader, Button, Row, Col, CardBody, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { WellStreamUserClient } from '../../infrastructure/WellStreamClient/Users';
import { GetAssetDomain } from '../../infrastructure/Helper';
import { WellStreamInitiativeClient, InitiativeQueryParams } from '../../infrastructure/WellStreamClient/Initiatives';
import { SelectField } from '../../infrastructure/SelectField';
import { Formik, Form, ErrorMessage } from 'formik';
import { WellStreamRoleClient, RoleQueryParams } from '../../infrastructure/WellStreamClient/Roles';

export class CustomersDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null, loading: false, authenticated: true, next: false, restoreLoading: false,
            modal: false
        };

        this.suspend = this.suspend.bind(this);
        this.restore = this.restore.bind(this);
    }

    async toggleModal() {
        this.setState({ modal: !this.state.modal })
    }

    async componentDidMount() {
        this.setState({
            assetUrl: await GetAssetDomain()
        });
        await this.initDropdownData();
        this.populateData(this.props.match.params.id);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }


    async suspend() {
        this.setState({ restoreLoading: true });
        await WellStreamUserClient.Suspend(this.props.match.params.id);
        this.populateData(this.props.match.params.id);
        this.setState({ restoreLoading: false });
    }

    async restore() {
        this.setState({ restoreLoading: true });
        await WellStreamUserClient.Restore(this.props.match.params.id);
        this.populateData(this.props.match.params.id);
        this.setState({ restoreLoading: false });
    }

    render() {

        if (this.state.next) {
            return (<Redirect to="/initiatives" />);
        } else {
            return (

                <div>
                    {this.state.data && <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <h5>
                                        <Link to="/customers" className="btn mr-2 btn-outline-dark mt-2 mb-2"><FontAwesomeIcon icon={faChevronLeft} /></Link>
                                        {this.state.data && <span>User details </span>}
                                        {this.state.loading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}

                                    </h5>
                                </Col>
                                <Col>
                                    <div style={{ paddingRight: "10px" }}>
                                        {this.state.data && <Link style={{ float: "right" }} to={"/customers/edit/" + this.state.data.id} className="btn mr-2 btn-outline-dark mt-2 mb-2">Edit</Link>}
                                        {this.state.data && this.state.data.status === "active" &&
                                            <button style={{ float: "right" }} onClick={() => { this.toggleModal(); }} className="btn btn-outline-dark mr-2 mt-2 mb-2">
                                                {!this.state.restoreLoading && "Suspend"}
                                                {this.state.restoreLoading && <Spinner animation="border" />}
                                            </button>}
                                        {this.state.data && this.state.data.status === "suspended" &&
                                            <button style={{ float: "right" }} onClick={() => { this.restore(); }} className="btn btn-outline-dark mr-2 mt-2 mb-2">
                                                {!this.state.restoreLoading && "Restore"}
                                                {this.state.restoreLoading && <Spinner animation="border" />}
                                            </button>}
                                        <button style={{ float: "right" }} onClick={() => { this.setState({ panel: "set-initiative" }); }} className="btn btn-outline-dark mr-2 mt-2 mb-2">
                                            Set initiative
                                        </button>

                                        <permissionContext.Consumer>
                                            {({ permissions }) => {

                                                return (permissions.includes("permission.modify") && <button style={{ float: "right" }} onClick={() => { this.setState({ panel: "set-role" }); }} className="btn btn-outline-dark mr-2 mt-2 mb-2">
                                                    Set role
                                        </button>);
                                            }}
                                        </permissionContext.Consumer>

                                       
                                    </div>
                                </Col>
                            </Row>

                        </CardHeader>
                    </Card>}

                    {this.state.panel === "set-role" && <Card>
                        <CardBody>
                            <Formik
                                initialValues={{
                                    roleId: this.state.roleOptions.filter(type => type.value === this.state.roleId)[0]
                                }}
                                onSubmit={async (fields, { setErrors, setFieldError, setFieldValue }) => {

                                    var response = await WellStreamUserClient.SetRole(this.props.match.params.id, fields.roleId);

                                    if (!response.successful) {
                                        response.validationErrors.map(error => {
                                            setFieldError(error.key, error.message);
                                            return {
                                                [error.key]: error.message
                                            };
                                        });
                                    } else {
                                        this.setState({ panel: "none" });
                                        this.populateData(this.props.match.params.id);
                                    }
                                }}
                            >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                                <Form>
                                    <Row>
                                        <Col>
                                            <SelectField
                                                    id="roleId"
                                                    name="roleId"
                                                label="Role"
                                                placeholder="Select role"
                                                options={this.state.roleOptions}
                                                    value={values.roleId}
                                                isMulti={false}
                                                onChange={setFieldValue}
                                                onBlur={setFieldTouched}
                                                    touched={touched.roleId}
                                                    error={errors.roleId}
                                                isClearable={true}
                                                backspaceRemovesValue={true}
                                            />
                                                <ErrorMessage name="roleId" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="mt-4">
                                                <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                                    {!isSubmitting && "Save"}
                                                    {isSubmitting && <Spinner animation="border" />}
                                                </button>

                                                <button onClick={() => { this.setState({ panel: "none" }); }} className="btn btn-outline-dark mr-2 mt-2">
                                                    Cancel
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            )}</Formik>
                        </CardBody>
                    </Card>}

                    {this.state.panel === "set-initiative" && <Card>
                        <CardBody>
                            <Formik
                                initialValues={{
                                    initiativeId: this.state.initiativeOptions.filter(type => type.value === this.state.initiativeId)[0]
                                }}
                                onSubmit={async (fields, { setErrors, setFieldError, setFieldValue }) => {

                                    var response = await WellStreamUserClient.SetInitiative(this.props.match.params.id, fields.initiativeId ? fields.initiativeId.value : null);


                                    if (!response.successful) {
                                            response.validationErrors.map(error => {
                                                setFieldError(error.key, error.message);
                                                return {
                                                    [error.key]: error.message
                                                };
                                            });
                                    } else {
                                        this.setState({ panel: "none" });
                                        this.populateData(this.props.match.params.id);
                                    }
                                }}
                            >{({ errors, status, touched, isSubmitting, values, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
                                <Form>
                                    <Row>
                                        <Col>
                                            <SelectField
                                                id="initiativeId"
                                                name="initiativeId"
                                                label="Initiative"
                                                placeholder="Select initiative"
                                                options={this.state.initiativeOptions}
                                                value={values.initiativeId}
                                                isMulti={false}
                                                onChange={setFieldValue}
                                                onBlur={setFieldTouched}
                                                touched={touched.initiativeId}
                                                error={errors.initiativeId}
                                                isClearable={true}
                                                backspaceRemovesValue={true}
                                            />
                                            <ErrorMessage name="initiativeId" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="mt-4">
                                                <button type="submit" className="btn mr-2 btn-outline-dark mt-2" disabled={isSubmitting}>
                                                    {!isSubmitting && "Save"}
                                                    {isSubmitting && <Spinner animation="border" />}
                                                </button>

                                                <button onClick={() => { this.setState({ panel: "none" }); }} className="btn btn-outline-dark mr-2 mt-2">
                                                    Cancel
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            )}</Formik>
                        </CardBody>
                    </Card>}

                    {this.state.data && <Card>
                        <CardBody>
                            <Row>
                                <Col>
                                    <h6>Details</h6>
                                    <hr />

                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="pr-2">Name:</td>
                                                <td>
                                                    {this.state.data.name && this.state.data.name}
                                                    {!this.state.data.name && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Surname:</td>
                                                <td>
                                                    {this.state.data.surname && this.state.data.surname}
                                                    {!this.state.data.surname && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Status:</td>
                                                <td>
                                                    {this.state.data.status === "active" && <Badge color="success">Active</Badge>}
                                                    {this.state.data.status === "suspended" && <Badge color="secondary">Access suspended</Badge>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Email:</td>
                                                <td>
                                                    {this.state.data.email && this.state.data.email}
                                                    {!this.state.data.email && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Mobile:</td>
                                                <td>
                                                    {this.state.data.mobile && this.state.data.mobile}
                                                    {!this.state.data.mobile && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            {this.state.data.roles && this.state.data.roles.length > 0 &&
                                                <tr>
                                                    <td className="pr-2">Role:</td>
                                                <td>{this.state.data.roles[0].name}</td>
                                                </tr>}
                                            <tr>
                                                <td className="pr-2">Joined:</td>
                                                <td>
                                                    {this.state.data.created && <Moment utc local format="ddd DD MMM, h:mm a">{this.state.data.created}</Moment>}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                                <Col>
                                    <h6 className="text-left">Initiative</h6>
                                    <hr />
                                    {!this.state.data.initiative && <h4 className="text-muted mt-3"><i>No initiative</i></h4>}
                                    {this.state.data.initiative && <table>
                                        <tbody>
                                            <tr>
                                                <td className="pr-2">Name:</td>
                                                <td>
                                                    {this.state.data.initiative.name && this.state.data.initiative.name}
                                                    {!this.state.data.initiative.name && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Organisation:</td>
                                                <td>
                                                    {this.state.data.initiative.organisation && this.state.data.initiative.organisation.name}
                                                    {!this.state.data.initiative.organisation && <i>Not specified</i>}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>}
                                </Col>
                                <Col>
                                    <h6 className="text-left">Profile image</h6>
                                    <hr />
                                    {!this.state.data.profileImage && <h4 className="text-muted mt-3"><i>No image</i></h4>}
                                    {this.state.data.profileImage && <img alt="Uploaded by submitted" src={this.state.assetUrl + "/image/" + this.state.data.profileImage.id + ".jpg"} style={{ width: "250px", marginTop: "20px", objectFit: "cover" }} />}
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>} 

                    {this.state.data && < Card >
                        <CardBody>
                            <Row>
                                <Col>
                                    <h6>Subscription</h6>
                                    <hr />
                                    {!this.state.subscription && <i>No subscription</i>}
                                    {this.state.subscription && <table>
                                        <tbody>
                                            <tr>
                                                <td className="pr-2">Type:</td>
                                                <td>
                                                    {this.state.subscription.type}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pr-2">Status:</td>
                                                <td>
                                                    {this.state.subscription.status}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>}
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col>
                                    <h6>Actions</h6>
                                    <hr />
                                    {(!this.state.subscription || (this.state.subscription.type != "complimentary")) && <button disabled={this.state.loadingPlan} style={{ float: "left" }} onClick={() => { this.setPlan("complimentary", this.state.subscription ? this.state.subscription.plan : "monthly"); }} className="btn btn-outline-dark mr-2 mt-2 mb-2">
                                        Make Complimentary Subscription
                                    </button>}
                                    {(this.state.data.initiative && (!this.state.subscription || this.state.subscription.type != "organisation")) && <button disabled={this.state.loadingPlan} style={{ float: "left" }} onClick={() => { this.setPlan("organisation", this.state.subscription ? this.state.subscription.plan : "monthly"); }} className="btn btn-outline-dark mr-2 mt-2 mb-2">
                                        Make Org Subscription
                                    </button>}
                                    {(!this.state.subscription || (this.state.subscription.type != "free")) && <button disabled={this.state.loadingPlan} style={{ float: "left" }} onClick={() => { this.setPlan("free", this.state.subscription ? this.state.subscription.plan : "monthly"); }} className="btn btn-outline-dark mr-2 mt-2 mb-2">
                                        Make Basic Subscription
                                    </button>}
                                    {(!this.state.subscription || (this.state.subscription.type != "premium")) && <button disabled={this.state.loadingPlan} style={{ float: "left" }} onClick={() => { this.setPlan("premium", this.state.subscription ? this.state.subscription.plan : "monthly"); }} className="btn btn-outline-dark mr-2 mt-2 mb-2">
                                        Make Premium Subscription
                                    </button>}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>}

                    <Modal isOpen={this.state.modal}>
                        <ModalHeader toggle={() => this.toggleModal()}>Are you sure?</ModalHeader>
                        <ModalBody>
                            <p>Suspending the user will remove them from your billing immediately.</p>
                            <p>We'll let the user know they have 2 weeks to wrap up before they lose access to the Wellstream platform.</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={() => this.toggleModal()}>Cancel</Button>
                            <Button color="primary" onClick={() => {
                                this.suspend();
                                this.toggleModal();
                            }
                            }>I'm sure, suspend the user</Button>
                        </ModalFooter>
                    </Modal>


                </div>
            );
        }
    }

    async initDropdownData() {
        

        var initiatives = (await WellStreamInitiativeClient.List(new InitiativeQueryParams().Paginate(0, 10000))).data.data;

        this.setState({
            initiativeOptions: initiatives.map(function (item) { return { value: item.id, label: item.name }; })
        });

        var roles = await WellStreamRoleClient.List(new RoleQueryParams().Paginate(0, 1000));
        if (roles.successful) {

            var roleOptions = roles.data.data.map(function (item) { return { value: item.id, label: item.name }; });

            roleOptions.push({ value: null, label: "No role" });

            this.setState({
                roleOptions: roleOptions
            });

        }

    }

    async setPlan(type, plan) {

        this.setState({
            loadingPlan: true
        });

        await WellStreamUserClient.SetSubscription(this.props.match.params.id, plan, type);
        await this.populateData(this.props.match.params.id);

        this.setState({
            loadingPlan: false
        });

    }

    async populateData(id) {

        if (!this.state.loading) {
            this.setState({ loading: true });

            var response = await WellStreamUserClient.Get(id);

            if (!response.authenticated) {
                this.setState({
                    authenticated: false,
                    loading: false
                });
            } else {

                if (response.data.initiative) {
                    this.setState({ initiativeId: response.data.initiative.id });
                }

                if (response.data.roles) {
                    if (response.data.roles.length > 0)
                        this.setState({ roleId: response.data.roles[0].id });
                }

                var subscription = await WellStreamUserClient.GetSubscription(id);
                this.setState({ data: response.data, loading: false, subscription: subscription.successful ? subscription.data : null });
            }
        }
    }

}
