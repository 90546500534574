import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Row, Spinner } from 'reactstrap';
import { OrganisationSelector } from '../infrastructure/OrganisationSelector';
import { WellStreamInitiativeClient, InitiativeQueryParams } from '../infrastructure/WellStreamClient/Initiatives';
import { WellStreamStatisticsClient } from '../infrastructure/WellStreamClient/Statistics';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { permissionContext } from '../infrastructure/Context/permissionContext';
import { Line, Bar } from 'react-chartjs-2';
import DatePicker from "react-datepicker";
import moment from 'moment';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const dayInterval = 24 * 60 * 60 * 1000;

export class Home extends Component {

    constructor(props) {
        super(props);

        var nowDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

        this.state = {
            loading: true,

            dailyGraphEnd: nowDate,
            dailyGraphLoading: false,

            weekGraphEnd: this.previousMonday(nowDate),
            weekGraphLoading: false,

            monthGraphEnd: this.startOfMonth(nowDate),
            monthGraphLoading: false,

            weekDetailEnd: this.previousMonday(nowDate),
            weekDetailLoading: false,

            monthlyRunningStart: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            monthlyRunningLoading: false
        };
    }

    componentDidMount() {
        OrganisationSelector.Init();
        this.populateData();
    }

    previousMonday(d) {

        console.log(d);

        var diff = d.getDay() - (d.getDay() == 0 ? -6 : 1); // adjust when day is sunday

        console.log(new Date(d.getTime() - (diff * dayInterval)));

        return new Date(d.getTime() - (diff * dayInterval));
    }

    nextMonday(d) {
        return new Date(this.previousMonday(d).getTime() + 7 * dayInterval);
    }


    startOfMonth(d) {
        return new Date(d.getFullYear(), d.getMonth(), 1);
    }

    async populateData() {
        var selectedOrg = await OrganisationSelector.GetSelectedOrg();
        if (selectedOrg == null)
            return;

        var initiatives = await WellStreamInitiativeClient.List(new InitiativeQueryParams()
            .WithOrganisationId(selectedOrg.id)
            .Paginate(0, 1));

        if (initiatives.data && initiatives.data.data.length === 0) {
            this.setState({
                requireInitiative: true
            })
        }


        var response = await WellStreamStatisticsClient.Dashboard(selectedOrg.id);
        this.setState({
            data: response.data
        });

        await this.populateDailyGraphData();
        await this.populateWeeklyGraphData();
        await this.populateMonthlyGraphData();
        await this.populateWeeklyGraphDetailData();
        await this.populateMonthRunningGraphData();
    }

    async populateDailyGraphData() {

        this.setState({
            dailyGraphLoading: true
        });

        var selectedOrg = await OrganisationSelector.GetSelectedOrg();
        if (selectedOrg == null)
            return;

        var graphResponse = await WellStreamStatisticsClient.Graph(selectedOrg.id, new Date(this.state.dailyGraphEnd.getTime() - 30 * dayInterval), new Date(this.state.dailyGraphEnd.getTime() + dayInterval));

        this.setState({
            dailyGraphLoading: false,
            dailyGraphData: {
                labels: graphResponse.data.map(x => moment.utc(x.start).local().format('ddd, MMM DD')),
                datasets: [
                    {
                        lineTension: 0.5,
                        label: 'Wellbeing',
                        data: graphResponse.data.map(x => x.value),
                        pointRadius: 9,
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        pointBackgroundColor: graphResponse.data.map((x) => {
                            if (x.numberOfDataPoints == 0) {
                                return 'rgb(201, 201, 201)';
                            } else if (x.completionPercentage < 0.333) {
                                return 'rgb(233, 39, 71)';
                            } else if (x.completionPercentage < 0.66) {
                                return 'rgb(255, 216, 71)';
                            } else {
                                return 'rgb(44, 167, 24)';
                            }
                        }),
                        pointBorderColor: graphResponse.data.map((x) => {
                            if (x.numberOfDataPoints == 0) {
                                return 'rgb(201, 201, 201)';
                            } else if (x.completionPercentage < 0.333) {
                                return 'rgb(233, 39, 71)';
                            } else if (x.completionPercentage < 0.66) {
                                return 'rgb(255, 216, 71)';
                            } else {
                                return 'rgb(44, 167, 24)';
                            }
                        }),
                    },
                    {
                        lineTension: 0.5,
                        label: 'Wellbeing',
                        data: graphResponse.data.map(x => 4),
                        pointRadius: 0,
                        borderColor: 'rgb(44, 167, 24)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        pointBackgroundColor: graphResponse.data.map((x) => {
                            return 'rgb(44, 167, 24)';
                        }),
                        pointBorderColor: graphResponse.data.map((x) => {
                            return 'rgb(44, 167, 24)';
                        }),
                    },
                    {
                        lineTension: 0.5,
                        label: 'Wellbeing',
                        data: graphResponse.data.map(x => 3),
                        pointRadius: 0,
                        borderColor: 'rgb(233, 39, 71)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        pointBackgroundColor: graphResponse.data.map((x) => {
                            return 'rgb(233, 39, 71)';
                        }),
                        pointBorderColor: graphResponse.data.map((x) => {
                            return 'rgb(233, 39, 71)';
                        }),
                    },
                ],
            },
            dailyGraphOptions: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        filter: function (tooltipItem) {
                            return tooltipItem.datasetIndex === 0;
                        },
                        callbacks: {
                            label: function (context) {

                                var index = context.dataIndex;
                                var dataPoint = graphResponse.data[index];
                                if (!dataPoint)
                                    return "No data";
                                return " " + context.formattedValue + " (" + dataPoint.numberOfDataPoints + " of " + dataPoint.total + " participants)";
                            }
                        }
                    }
                },
                scales: {
                    y: {
                        min: 1,
                        max: 7
                    }
                }
            }
        });
    }

    async populateWeeklyGraphData() {

        this.setState({
            weekGraphLoading: true
        });

        var selectedOrg = await OrganisationSelector.GetSelectedOrg();
        if (selectedOrg == null)
            return;

        console.log(this.state.weekGraphEnd);

        var graphResponse = await WellStreamStatisticsClient.WeeklyGraph(selectedOrg.id, new Date(this.state.weekGraphEnd.getTime() - 11 * 7 * dayInterval), new Date(this.state.weekGraphEnd.getTime() + 7 * dayInterval));

        this.setState({
            weekGraphLoading: false,
            graphData: {
                labels: graphResponse.data.map(x => moment.utc(x.start).local().format('ddd, MMM DD')),
                datasets: [
                    {
                        lineTension: 0.5,
                        label: 'Wellbeing',
                        data: graphResponse.data.map(x => x.value),
                        pointRadius: 4,
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        pointBackgroundColor: graphResponse.data.map((x) => {
                            if (x.numberOfDataPoints == 0) {
                                return 'rgb(201, 201, 201)';
                            } else if (x.completionPercentage < 0.333) {
                                return 'rgb(233, 39, 71)';
                            } else if (x.completionPercentage < 0.66) {
                                return 'rgb(255, 216, 71)';
                            } else {
                                return 'rgb(44, 167, 24)';
                            }
                        }),
                        pointBorderColor: graphResponse.data.map((x) => {
                            if (x.numberOfDataPoints == 0) {
                                return 'rgb(201, 201, 201)';
                            } else if (x.completionPercentage < 0.333) {
                                return 'rgb(233, 39, 71)';
                            } else if (x.completionPercentage < 0.66) {
                                return 'rgb(255, 216, 71)';
                            } else {
                                return 'rgb(44, 167, 24)';
                            }
                        }),
                    },
                    {
                        lineTension: 0.5,
                        label: 'Wellbeing',
                        data: graphResponse.data.map(x => 4),
                        pointRadius: 0,
                        borderColor: 'rgb(44, 167, 24)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        pointBackgroundColor: graphResponse.data.map((x) => {
                            return 'rgb(44, 167, 24)';
                        }),
                        pointBorderColor: graphResponse.data.map((x) => {
                            return 'rgb(44, 167, 24)';
                        }),
                    },
                    {
                        lineTension: 0.5,
                        label: 'Wellbeing',
                        data: graphResponse.data.map(x => 3),
                        pointRadius: 0,
                        borderColor: 'rgb(233, 39, 71)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        pointBackgroundColor: graphResponse.data.map((x) => {
                            return 'rgb(233, 39, 71)';
                        }),
                        pointBorderColor: graphResponse.data.map((x) => {
                            return 'rgb(233, 39, 71)';
                        }),
                    },
                ],
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        filter: function (tooltipItem) {
                            return tooltipItem.datasetIndex === 0;
                        },
                        callbacks: {
                            label: function (context) {

                                var index = context.dataIndex;
                                var dataPoint = graphResponse.data[index];
                                if (!dataPoint)
                                    return "No data";
                                return " " + context.formattedValue + " (" + dataPoint.numberOfDataPoints + " of " + dataPoint.total + " participants)";
                            }
                        }
                    }
                },
                scales: {
                    y: {
                        min: 1,
                        max: 7
                    }
                }
            }
        });
    }

    async populateMonthlyGraphData() {

        this.setState({
            monthGraphLoading: true
        });

        var selectedOrg = await OrganisationSelector.GetSelectedOrg();
        if (selectedOrg == null)
            return;

        var graphResponse = await WellStreamStatisticsClient.MonthlyGraph(selectedOrg.id, new Date(this.state.monthGraphEnd.getFullYear() - 1, (this.state.monthGraphEnd.getMonth() + 1) % 12, this.state.monthGraphEnd.getDay()), new Date(this.state.monthGraphEnd.getFullYear(), (this.state.monthGraphEnd.getMonth() + 1) % 12, this.state.monthGraphEnd.getDay()),);

        this.setState({
            monthGraphLoading: false,
            monthGraphData: {
                labels: graphResponse.data.map(x => moment.utc(x.start).local().format('MMM, YY')),
                datasets: [
                    {
                        lineTension: 0.5,
                        label: 'Wellbeing',
                        data: graphResponse.data.map(x => x.value),
                        pointRadius: 4,
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        pointBackgroundColor: graphResponse.data.map((x) => {
                            if (x.numberOfDataPoints == 0) {
                                return 'rgb(201, 201, 201)';
                            } else if (x.completionPercentage < 0.333) {
                                return 'rgb(233, 39, 71)';
                            } else if (x.completionPercentage < 0.66) {
                                return 'rgb(255, 216, 71)';
                            } else {
                                return 'rgb(44, 167, 24)';
                            }
                        }),
                        pointBorderColor: graphResponse.data.map((x) => {
                            if (x.numberOfDataPoints == 0) {
                                return 'rgb(201, 201, 201)';
                            } else if (x.completionPercentage < 0.333) {
                                return 'rgb(233, 39, 71)';
                            } else if (x.completionPercentage < 0.66) {
                                return 'rgb(255, 216, 71)';
                            } else {
                                return 'rgb(44, 167, 24)';
                            }
                        }),
                    },
                    {
                        lineTension: 0.5,
                        label: 'Wellbeing',
                        data: graphResponse.data.map(x => 4),
                        pointRadius: 0,
                        borderColor: 'rgb(44, 167, 24)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        pointBackgroundColor: graphResponse.data.map((x) => {
                            return 'rgb(44, 167, 24)';
                        }),
                        pointBorderColor: graphResponse.data.map((x) => {
                            return 'rgb(44, 167, 24)';
                        }),
                    },
                    {
                        lineTension: 0.5,
                        label: 'Wellbeing',
                        data: graphResponse.data.map(x => 3),
                        pointRadius: 0,
                        borderColor: 'rgb(233, 39, 71)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        pointBackgroundColor: graphResponse.data.map((x) => {
                            return 'rgb(233, 39, 71)';
                        }),
                        pointBorderColor: graphResponse.data.map((x) => {
                            return 'rgb(233, 39, 71)';
                        }),
                    },
                ],
            },
            monthOptions: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        filter: function (tooltipItem) {
                            return tooltipItem.datasetIndex === 0;
                        },
                        callbacks: {
                            label: function (context) {
                                var index = context.dataIndex;
                                var dataPoint = graphResponse.data[index];
                                console.log(dataPoint);
                                if (!dataPoint)
                                    return "No data";
                                return " " + context.formattedValue + " (" + dataPoint.numberOfDataPoints + " of " + dataPoint.total + " participants)";
                            }
                        }
                    }
                },
                scales: {
                    y: {
                        min: 1,
                        max: 7
                    }
                }
            }
        });
    }

    async populateWeeklyGraphDetailData() {

        this.setState({
            weekDetailLoading: true
        });

        var selectedOrg = await OrganisationSelector.GetSelectedOrg();
        if (selectedOrg == null)
            return;

        var graphResponse = await WellStreamStatisticsClient.WeeklyGraphDetail(selectedOrg.id, new Date(this.state.weekDetailEnd.getTime() - 5 * 7 * dayInterval), new Date(this.state.weekDetailEnd.getTime() + 7 * dayInterval));

        this.setState({
            weekDetailLoading: false,
            graphWeekDetailData: {
                labels: graphResponse.data.map(x => moment.utc(x.start).local().format('ddd, MMM DD')),
                datasets: [
                    {
                        label: 'Happiness',
                        data: graphResponse.data.map(x => x.value[0]),
                        borderColor: '#058457',
                        backgroundColor: '#058457',
                    },
                    {
                        label: 'Relaxed',
                        data: graphResponse.data.map(x => x.value[1]),
                        borderColor: '#f68742',
                        backgroundColor: '#f68742',
                    },
                    {
                        label: 'Phys. Energy',
                        data: graphResponse.data.map(x => x.value[2]),
                        borderColor: '#ce4fe1',
                        backgroundColor: '#ce4fe1',
                    },
                    {
                        label: 'Motivation',
                        data: graphResponse.data.map(x => x.value[1]),
                        borderColor: '#0e5cda',
                        backgroundColor: '#0e5cda',
                    },
                ],
            },
            weekDetailsOptions: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        filter: function (tooltipItem) {
                            return true;
                        },
                        callbacks: {
                            label: function (context) {

                                var index = context.dataIndex;
                                var dataPoint = graphResponse.data[index];
                                if (!dataPoint)
                                    return "No data";
                                return " " + context.formattedValue + " (" + context.dataset.label + ")";
                            }
                        }
                    }
                },
                scales: {
                    y: {
                        min: 1,
                        max: 7
                    }
                }
            }
        });
    }

    async populateMonthRunningGraphData() {

        this.setState({
            monthlyRunningLoading: true
        });

        var selectedOrg = await OrganisationSelector.GetSelectedOrg();
        if (selectedOrg == null)
            return;

        var end = new Date(this.state.monthlyRunningStart.getTime());
        var start = new Date(this.state.monthlyRunningStart.getTime());

        end.setMonth(this.state.monthlyRunningStart.getMonth() + 1);
        start.setMonth(this.state.monthlyRunningStart.getMonth() - 2);

        var graphResponse = await WellStreamStatisticsClient.MonthlyRunningGraphDetail(selectedOrg.id, start, end);

        this.setState({
            monthlyRunningLoading: false,
            graphMonthlyRunningData: {
                labels: graphResponse.data.map(x => moment.utc(x.start).local().format('MMM YY')),
                datasets: [
                    {
                        label: 'Mon',
                        data: graphResponse.data.map(x => x.value[0]),
                        borderColor: '#0e5cda',
                        backgroundColor: '#0e5cda',
                    },
                    {
                        label: 'Tues',
                        data: graphResponse.data.map(x => x.value[1]),
                        borderColor: '#0e5cda',
                        backgroundColor: '#0e5cda',
                    },
                    {
                        label: 'Wed',
                        data: graphResponse.data.map(x => x.value[2]),
                        borderColor: '#0e5cda',
                        backgroundColor: '#0e5cda',
                    },
                    {
                        label: 'Thur',
                        data: graphResponse.data.map(x => x.value[3]),
                        borderColor: '#0e5cda',
                        backgroundColor: '#0e5cda',
                    },
                    {
                        label: 'Fri',
                        data: graphResponse.data.map(x => x.value[4]),
                        borderColor: '#0e5cda',
                        backgroundColor: '#0e5cda',
                    },
                    {
                        label: 'Sat',
                        data: graphResponse.data.map(x => x.value[5]),
                        borderColor: '#058457',
                        backgroundColor: '#058457',
                    },
                    {
                        label: 'Sun',
                        data: graphResponse.data.map(x => x.value[6]),
                        borderColor: '#058457',
                        backgroundColor: '#058457',
                    },
                ],
            },
            monthlyRunningOptions: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        filter: function (tooltipItem) {
                            return true;
                        },
                        callbacks: {
                            label: function (context) {

                                var index = context.dataIndex;
                                var dataPoint = graphResponse.data[index];
                                if (!dataPoint)
                                    return "No data";
                                return " " + context.formattedValue + " (" + context.dataset.label + ")";
                            }
                        }
                    }
                },
                scales: {
                    y: {
                        min: 1,
                        max: 7
                    }
                }
            }
        });
    }

render() {
    return (<>

        <permissionContext.Consumer>
            {({ permissions, mode }) => {
                if (mode == "admin")
                    return (<Redirect to="/customers" />);
            }}
        </permissionContext.Consumer>

        {this.state.requireInitiative && <div className="alert alert-warning" style={{ marginTop: "30px" }}>
            <Row>
                <Col>
                    <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}><span className="pcoded-micon"><i className="feather icon-alert-triangle"></i></span> <strong>This organisation has no initiatives yet</strong></p>
                </Col>
                <Col md="2">
                    <Link style={{ float: "right" }} to="/onboarding/step-02" className="btn mr-2 mt-2 mb-2 btn-warning">Create initiative</Link>
                </Col>
            </Row>
        </div>}

        <div>
            <Row>
                <Col>
                    <Row>
                        {this.state.data && this.state.data.length > 0 && this.state.data.map(stat => <Col md="4" key={stat.title}>
                            <Card>
                                <Link to={stat.link} className="media friendlist-box">
                                    <CardBody>
                                        <h5>{stat.title}</h5>
                                        <h2>{stat.value}</h2>
                                        <span className="d-block text-muted" style={{ height: "56px" }}>{stat.description}</span>
                                    </CardBody>
                                </Link>
                            </Card>
                        </Col>)}
                    </Row>

                    <Row>
                        <Col>
                            {this.state.dailyGraphData && <Card>
                                <CardHeader style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <h5>Daily Details

                                        {this.state.dailyGraphLoading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <div>
                                        <DatePicker dateFormat="dd/MM/yyyy" selected={this.state.dailyGraphEnd} onChange={(date) => this.setState({
                                            dailyGraphEnd: this.previousMonday(date)
                                        }, this.populateDailyGraphData)} className="bgWhite form-control" />
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Line data={this.state.dailyGraphData} options={this.state.dailyGraphOptions} />
                                </CardBody>
                            </Card>}
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            {this.state.graphData && <Card>
                                <CardHeader style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <h5>Weekly Mental Wellbeing
                                        {this.state.weekGraphLoading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}
                                    </h5>
                                    <div>
                                    <DatePicker dateFormat="dd/MM/yyyy" selected={this.state.weekGraphEnd} onChange={(date) => this.setState({
                                        weekGraphEnd: this.previousMonday(date)
                                    }, this.populateWeeklyGraphData)} className="bgWhite form-control" />
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Line data={this.state.graphData} options={this.state.options} />
                                </CardBody>
                            </Card>}
                        </Col>
                        <Col>
                            {this.state.monthGraphData && <Card>
                                <CardHeader style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <h5>Monthly Mental Wellbeing
                                        {this.state.monthGraphLoading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}</h5>
                                    <div>
                                    <DatePicker dateFormat="MMM yy" showMonthYearPicker selected={this.state.monthGraphEnd} onChange={(date) => this.setState({
                                        monthGraphEnd: date
                                    }, this.populateMonthlyGraphData)} className="bgWhite form-control" />
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Line data={this.state.monthGraphData} options={this.state.monthOptions} />
                                </CardBody>
                            </Card>}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {this.state.graphWeekDetailData && <Card>
                                <CardHeader style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <h5>Weekly Details
                                        {this.state.weekDetailLoading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}</h5>
                                    <div>
                                    <DatePicker dateFormat="dd/MM/yyyy" selected={this.state.weekDetailEnd} onChange={(date) => this.setState({
                                        weekDetailEnd: this.previousMonday(date)
                                    }, this.populateWeeklyGraphDetailData)} className="bgWhite form-control" />
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Bar data={this.state.graphWeekDetailData} options={this.state.weekDetailsOptions} />
                                </CardBody>
                            </Card>}
                        </Col>
                        <Col>
                            {this.state.graphMonthlyRunningData && <Card>
                                <CardHeader style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <h5>Day of the week
                                        {this.state.monthlyRunningLoading && <Spinner style={{ height: "18px", width: "18px", marginLeft: "10px" }} animation="border" />}</h5>
                                    <div>
                                    <DatePicker showMonthYearPicker dateFormat="MMM yy" selected={this.state.monthlyRunningStart} onChange={(date) => this.setState({
                                        monthlyRunningStart: date
                                    }, this.populateMonthRunningGraphData)} className="bgWhite form-control" />
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Bar data={this.state.graphMonthlyRunningData} options={this.state.monthlyRunningOptions} />
                                </CardBody>
                            </Card>}
                        </Col>
                    </Row>
                </Col>
                <Col md="3">
                    <Card className="note-bar">
                        <CardHeader><h5>Quick actions</h5></CardHeader>
                        <CardBody>
                            <Link to="/invitations/new?dest=user" className="media friendlist-box">
                                <div className="mr-4 photo-table">
                                    <i className="feather icon-user"></i>
                                </div>
                                <div className="media-body">
                                    <h6>Invite new user</h6>
                                    <span className="f-12 float-right text-muted"><FontAwesomeIcon icon={faExternalLinkAlt} /></span>
                                    <p className="text-muted m-0">Send an invite for a user to join an initiative or organisation</p>
                                </div>
                            </Link>

                            <Link to="/invitations/new?dest=staff" className="media friendlist-box">
                                <div className="mr-4 photo-table">
                                    <i className="feather icon-tablet"></i>
                                </div>
                                <div className="media-body">
                                    <h6>Manage staff</h6>
                                    <span className="f-12 float-right text-muted"><FontAwesomeIcon icon={faExternalLinkAlt} /></span>
                                    <p className="text-muted m-0">View and manage oragnisation staff</p>
                                </div>
                            </Link>

                            <a href="mailto:help@thewellstream.com?subject=Wellstream%20help%20request" className="media friendlist-box">
                                <div className="mr-4 photo-table fa">
                                    <i><FontAwesomeIcon icon={faQuestionCircle} /></i>
                                </div>
                                <div className="media-body">
                                    <h6>Need help?</h6>
                                    <span className="f-12 float-right text-muted"><FontAwesomeIcon icon={faExternalLinkAlt} /></span>
                                    <p className="text-muted m-0">Email help@thewellstream.com</p>
                                </div>
                            </a>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    </>);
}
}
