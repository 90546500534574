import { WellStreamAPI } from "./WellStreamAPI";

export async function GetAPIDomain() {
    var domain = localStorage.getItem("api-domain");

    if (!domain) {
        const response = await fetch('app-settings/domains');
        const data = await response.json();

        domain = data.domainApi;
        localStorage.setItem("api-domain", domain);
    }

    return domain;
}

export async function GetAssetDomain() {
    var domain = localStorage.getItem("asset-domain-2");

    if (!domain) {
        const response = await fetch('app-settings/domains');
        const data = await response.json();

        domain = data.domainAsset;
        localStorage.setItem("asset-domain-2", domain);
    }

    return domain;
}

export async function GetAPIHeadersTokenOnly() {

    var expiry = new Date(Date.parse(localStorage.getItem("expires")));

    if (expiry < new Date()) {
        console.log("Expired");
        await WellStreamAPI.RefreshToken();
    }

    var accessToken = localStorage.getItem("acess-token");
    return new Headers({ 'Authorization': 'Bearer ' + accessToken });
}

export async function GetAPIHeaders(contentType, ignoreSelectedMode = false) {

    var expiry = new Date(Date.parse(localStorage.getItem("expires")));

    if (expiry < new Date()) {
        console.log("Expired");
        await WellStreamAPI.RefreshToken();
    }

    var accessToken = localStorage.getItem("acess-token");
    var headers = new Headers({ 'content-type': contentType ? contentType : 'application/json', 'Authorization': 'Bearer ' + accessToken });

    if (!ignoreSelectedMode) {
        var selectedMode = localStorage.getItem("org-list-selected-mode");
        var selectedOrgId = localStorage.getItem("org-list-selected");
        if (selectedMode == "org" && selectedOrgId != null)
            headers.append("x-organisation-id", selectedOrgId);
    }

    return headers
}


export async function GetAPIHeadersPatch() {

    var expiry = new Date(Date.parse(localStorage.getItem("expires")));

    if (expiry < new Date()) {
        console.log("Expired");
        await WellStreamAPI.RefreshToken();
    }

    var accessToken = localStorage.getItem("acess-token");

    var headers = new Headers({ 'content-type': 'application/json-patch+json', 'Authorization': 'Bearer ' + accessToken });

    var selectedMode = localStorage.getItem("org-list-selected-mode");
    var selectedOrgId = localStorage.getItem("org-list-selected");
    if (selectedMode == "org" && selectedOrgId != null)
        headers.append("x-organisation-id", selectedOrgId);

    return headers
}