import { ListQueryParams, ApiResponse } from "../WellStreamAPI";
import { GetAPIHeadersPatch, GetAPIDomain, GetAPIHeaders } from "../Helper";

export class ContactQueryParams extends ListQueryParams {
    WithOrganisationId(value: any) {
        this.WithParam("organisationId", value);
        return this;
    }

    WithGlobalOnly() {
        this.WithParam("globalOnly", "true");
        return this;
    }
}


export class WellStreamContactClient {

    static async List(params: ContactQueryParams) {

        var query = (await GetAPIDomain()) + "/contacts" + params.GenerateQueryString();

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Get(id: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/contacts/" + id, {
            headers: await GetAPIHeaders()
        }));

    }

    static async Create(name: string, link?: string, imageId?: string, organisationId?: string, initativeId?: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/contacts/", {
            method: 'post',
            headers: await GetAPIHeaders(),
            body: JSON.stringify({
                "name": name,
                "organisationId": organisationId,
                "link": link,
                "imageId": imageId,
                "initiativeId": initativeId
            })
        }));
    }

    static async Update(id: string, name: string, link?: string, imageId?: string) {

        return await ApiResponse.Create(await fetch((await GetAPIDomain()) + "/contacts/" + id, {
            method: 'patch',
            headers: await GetAPIHeadersPatch(),
            body: JSON.stringify([{
                "op": "replace",
                "path": "name",
                "value": name
            },
            {
                "op": "replace",
                "path": "link",
                "value": link
            },
            {
                "op": "replace",
                "path": "imageId",
                "value": imageId
            }])
        }));
    }

    static async Delete(id: string) {

        var query = (await GetAPIDomain()) + "/contacts/" + id;

        return await ApiResponse.Create(await fetch(query, {
            headers: await GetAPIHeaders(),
            method: 'delete'
        }));
    }
}