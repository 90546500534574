import { GetAPIDomain } from "../Helper";

export class WellStreamFileClient {

    static async UploadImage(file: any) {

        let formData = new FormData();
        formData.append("file", file);

        var imageResult = await fetch((await GetAPIDomain()) + "/uploads/images", {
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("acess-token")
            },
            body: formData
        });

        if (imageResult.ok) {
            return (await imageResult.json()).id;
        }

        return null;
    }

    static async UploadContentFile(id :string, file: any) {

        let formData = new FormData();
        formData.append("file", file);

        var imageResult = await fetch((await GetAPIDomain()) + "/uploads/content-items/"+id, {
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("acess-token")
            },
            body: formData
        });

        if (imageResult.ok) {
            return (await imageResult.json()).id;
        }

        return null;
    }

}